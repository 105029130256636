/* --------------------------------
   Variables
   -------------------------------- */

/* Colours */
$black: #031611;
$white: #ffffff;
$silver: #c4c4c4;
$green: #2fcfb3;
$navy: #0e223a;
$neon: #b9ff77;
$red: #bb3530;
$red-hover: #b32924;
$red-link: #ac1f19;
$light-grey: #e3e2e2;
$black-slate: #393939;
$dark-grey: #424957;
$dim-grey: #707070;
$light-black: #272e3c;
$white-smoke: #f8f8f9;
$competence-grey: #F8F8F9;
$image-white: var(--white-color);
$image-black: var(--black-color);

$font-color: #393939;
/* Colours in use  */
$color-primary: $neon;
$color-secondary: $navy;
$primary-text-color: $black-slate;
$body-background: $color-secondary;

/* Typeface */
$font-acumin: 'acumin-pro', sans-serif;
$font-alt-gothic: 'alternate-gothic-atf', sans-serif;
$font-haas: 'font-haas', sans-serif;
$font-haas-tx: 'font-haas-tx', sans-serif;

/* Font Weight */
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;
$weight-black: 900;

/* Animations */
$custom-ease-in: cubic-bezier(0.82, 0, 0.79, 0);
$custom-ease-out: cubic-bezier(0.18, 1, 0.21, 1);

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
	xxs: 0,
	xs: 576px,
	sm: 768px,
	md: 992px,
	lg: 1200px,
	xl: 1500px,
	xxl: 1800px,
);

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem;
$spacers: (
	0: 0,
	1: $spacer / 4,
	2: $spacer / 2,
	3: $spacer,
	4: $spacer * 1.5,
	5: $spacer * 3,
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 40px;
$grid-row-columns: 6;
$gutters: $spacers;

// Container padding

$container-padding-x: 20px;
$container-max-width: 1920px;

// Form Colours
$form-text-color: $black;
$form-message-color: '';
$input-text-color: $primary-text-color;
$input-text-dark-color: '';
$input-label-color: '';
$input-label-active-color: '';
$input-label-active-dark-color: '';
$input-bg-color: #ffffff;
$input-bg-dark-color: #1a1a1a;
$input-border-color: '';
$input-border-active-color: '';
$input-border-active-dark-color: '';
// Checkbox
$checkbox-border-color: #cccccc;
$checkbox-bg-color: #cccccc;
$checkbox-tick-color: #f50080;
//
$checkbox-dot-border-color: '';
$checkbox-dot-fill-color: '';
// Radio
$radiobox-bg-color: #222222;
// Invert
$input-text-invert-color: #ffffff;
$input-bg-invert-color: #202020;
$input-border-invert-color: #202020;
// Success
$input-bg-success-color: #b8ffbe;
$input-border-success-color: #8cff97;
// $label-success-color: #188223;
$label-success-color: $color-secondary;
// Warning
$input-bg-warning-color: #ffc107;
$input-border-warning-color: #ffc107;
$label-warning-color: #dc4c1e;
// Error
$input-text-error-color: #d21212;
$input-bg-error-color: #ffebec;
$input-border-error-color: #ffb0b0;
$label-error-color: darken(#d21212, 10%);
// Submit
$submit-text-color: #ffffff;
$submit-bg-color: '';
$submit-bg-hover-color: '';
$submit-bg-active-color: '';
$submit-bg-disabled-color: #cccccc;
