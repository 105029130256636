/* --------------------------------
    Dropdown
   -------------------------------- */
:root {
	--dropdown-background-color: #{$color-secondary};
	--dropdown-nav-color: #{$color-primary};
}

/*
 * Dropdown - Active
 */

html.nav-active {
	&,
	body {
		// overflow: hidden;
		position: relative;

		.dropdown-close {
			width: 100%;
			height: 100%;
			transition: width 0.3s step-start, height 0.3s step-start, opacity 0.3s ease-in-out;
			opacity: 1;
		}
	}
}

/*
 * Dropdown 
 */

.dropdown__container {
	display: flex;
	visibility: hidden;
	overflow: hidden;
	position: fixed;
	z-index: 50;
	top: 0;
	left: 0;
	width: 100vw;
	max-width: 100vw;
	height: 100vh;
	transition: transform 0.5s 0.25s $custom-ease-out;
	transform: translateY(-100%);
	will-change: transform;
	background-color: var(--background-color, var(--dropdown-background-color));

	--gutter-x: #{$grid-gutter-width};
	-webkit-overflow-scrolling: touch;

	&.is-active {
		overflow: auto;
		transition: transform 0.5s 0.2s $custom-ease-out;
		transform: translateY(0);

		@include mq(md) {
		}
	}

	&.is-visible {
		visibility: visible;
	}
}

.dropdown {
	display: flex;
	width: 100%;
	min-height: 100%;
	margin: auto;

	.is-static & {
	}

	&__background {
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;

		@include mq(md) {
			display: flex;
			justify-content: center;
		}
	}

	&__inner {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		z-index: 25;
		width: 100%;

		@include mq(sm) {
			align-items: center;
		}
	}

	&__align {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		position: relative;
		z-index: 25;
		width: 100%;
		height: 100%;
		transition: opacity 0.25s ease-in-out;
		opacity: 0;

		.dropdown__container.is-active & {
			transition-delay: 0.5s;
			opacity: 1;
		}
	}

	&__column {
		width: 100%;
		max-width: 100%;
		padding-bottom: 45px;

		@include mq(sm) {
			padding-right: 20px;
			padding-left: 20px;
		}
		@include mq(md) {
			padding-right: 0;
			padding-left: 0;
		}

		&--main {
			display: flex;
			flex-grow: 1;
			align-items: center;
			padding-top: 110px;
			padding-bottom: 90px;

			@include mq(sm) {
				display: block;
				padding-bottom: 90px;
			}
			@include mq(md) {
				padding-top: 180px;
			}
			@include mq(lg) {
				padding-bottom: 200px;
			}
		}
	}

	&-close {
		position: fixed;
		// z-index: 125;
		bottom: 0;
		left: 0;
		width: 0;
		height: 0;
		transition: width 0.5s step-end, height 0.5s step-end, opacity 0.5s ease-in-out;
		will-change: opacity;
		opacity: 0;
		// background-color: $dropdown-overlay;
	}
}

.dropdown-nav {
	$root: &;
	@include make-col($size: 100%);

	&--primary {
		position: relative;
		margin-bottom: 30px;

		@include mq(md) {
			margin-bottom: 0;
		}
	}

	&--festivals {
		display: flex;
		justify-content: center;
		margin-bottom: 0;
	}

	&__inner {
		&:hover {
			#{$root} {
				&__item {
					opacity: 0.5;

					&:hover {
						opacity: 1;
					}
				}
			}
		}

		&.hover-in {
			#{$root} {
				&__item {
					opacity: 0.5;

					&.is-active {
						opacity: 1;
					}
				}
			}
		}

		&--center {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
		}
	}

	&__item {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		position: relative;
		margin-bottom: 15px;
		transition: opacity 0.2s ease-in-out;

		@include mq(sm) {
			margin-bottom: 20px;
		}
		@include mq(md) {
			margin-bottom: 25px;
		}

		&--button {
			flex-grow: 1;

			@include mq(md) {
				flex-grow: initial;
			}
		}

		&.is-active {
			z-index: 1;
			opacity: 1;
		}
	}

	&__link {
		$link: &;
		@include rem(30);

		font-style: italic;
		line-height: 1.3;
		display: flex;
		align-items: center;
		position: relative;
		padding-bottom: 10px;
		transition: color 0.3s ease-in-out, opacity 0.2s ease-in-out;
		will-change: opacity, color;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		opacity: 0;
		color: var(--dropdown-nav-color, #ffffff);

		@include mq(md) {
			@include rem(70);
		}
		@include mq(lg) {
			@include rem(85);
		}
		@include mq(xl) {
			@include rem(100);
		}

		&:last-of-type {
			padding-bottom: 0;
		}

		&:hover {
			// --dropdown-nav-color: #{$dropdown-link-hover};

			#{$link}-icon {
				&:after {
					width: 80px;
				}
			}
		}

		&:focus,
		&:active,
		&.is-active {
			// --dropdown-nav-color: #{$dropdown-link-active};
		}

		.dropdown__container.is-active & {
			transition: color 0.3s ease-in-out, opacity 0.3s 0.45s ease-in-out;
			opacity: 1;
		}

		&-text {
			padding-left: 20px;
		}

		&-icon {
			display: flex;
			width: 30px;
			margin-top: 5px;
			margin-left: 10px;

			@include mq(md) {
				width: 80px;
				margin-top: 15px;
				margin-left: 20px;
			}

			&:after {
				width: 20px;
				height: 10px;
				content: '';
				transition: width 0.4s $custom-ease-out;
				background-image: url('/app/theme/images/site/icons/arrow-right-light.svg');
				background-repeat: no-repeat;
				background-position: right center;
				background-size: cover;

				@include mq(md) {
					width: 60px;
					height: 30px;
				}
			}
		}
	}

	&__button {
		width: 100%;
		margin-right: 15px;
		margin-left: 15px;

		@include mq(md) {
			width: auto;
			padding-right: 75px;
			padding-left: 75px;
		}
	}
}

/*
 * Navigation Fix 
 *
 * If window is shorter than navigation, reposition
 */

.nav-overflow {
	.dropdown__container {
		display: block;
		padding-bottom: 0;

		&.is-active {
			overflow: auto;
			overflow-x: hidden;
		}
	}

	.dropdown {
		align-items: flex-start;
	}

	.dropdown__spacer {
		width: 100%;
		height: 40px;
	}
}
