/* ==========================================================================
   Qualifications List 
   ========================================================================== */

.qualifications-list {
	margin-top: 25px;
	margin-bottom: 80px;

	@include mq-min-max(no, xs) {
		margin-top: 12px;
		margin-bottom: 32px;
	}
}
