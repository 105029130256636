/* ==========================================================================
    Learning Portal Content
   ========================================================================== */

.learning-portal-content {
	padding-bottom: 95px;

	@include mq-min-max(no, xs) {
		padding-bottom: 55px;
	}
}

.learning-portal-single {
	width: 100%;

	&__inner {
		width: 100%;
		max-width: 1920px;
		margin: 0 auto;
		padding: 0 100px;

		h1 {
			text-align: left;
			@include rem(74);
		}
	}

	&__text-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		h2 {
			text-align: center;
			@include rem(30);
			max-width: 1000px;
		}

		p,
		ul,
		a {
			// text-align: center;
			@include rem(18);
			text-decoration: none;
			color: #393939;
			max-width: 840px;
		}

		a {
			font-weight: $weight-bold;
		}
	}

	&__image {
		display: flex;
		justify-content: center;

		img {
			width: 100%;
			object-fit: cover;
			aspect-ratio: 16/9;
			max-width: 1200px;
			align-self: center;
			margin: 0 auto;
			margin-right: auto;
			margin-left: auto;
		}
	}

	&__breadcrumbs {
		display: flex;
		flex-direction: row;

		a {
			color: #393939;
			text-decoration: none;
			margin: 0px 10px 0px 0px;
			font-weight: 300;
			@include rem(12);
		}
	}
}
