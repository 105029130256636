/* ==========================================================================
Home Partners
   ========================================================================== */

.home-partners {
	display: flex;
	width: 100vw;
	justify-content: center;
	background-color: $light-grey;
	font-family: $font-haas;
	color: $black-slate;
	font-weight: 700;

	&__inner {
		width: 100%;
		max-width: 1920px;
		padding: 50px 100px 55px;

		@include mq-min-max(no, xs) {
			height: 100%;
			padding: 48px 17px 28px;
		}
	}

	&__title {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 28px;
		border-bottom: 1px solid $black-slate;
		font-size: 22px;
		line-height: 1;

		@include mq-min-max(no, xs) {
			padding-bottom: 10px;
			font-size: 18px;
		}
	}

	&__partners-grid {
		display: flex;
		height: 110px;
		margin-top: 40px;
		flex-wrap: wrap;

		@include mq-min-max(no, xs) {
			height: auto;
			margin-top: 37px;
		}
	}

	&__partner {
		width: 12.5%;
		justify-content: center;
		align-items: center;

		@include mq-min-max(no, xs) {
			display: flex;
			width: 50%;
			margin-bottom: 26px;
		}
	}

	img {
		width: 100%;
		mix-blend-mode: multiply;
		object-fit: cover;

		@include mq-min-max(no, xs) {
			width: 80%;
		}
	}
}
