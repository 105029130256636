/* ==========================================================================
Contact Locations
   ========================================================================== */

.contact-locations {
	z-index: 1;
	display: flex;
	width: 100%;
	justify-content: center;
	font-family: $font-haas;
	color: white;
	font-weight: 500;

	&__inner {
		width: 100%;
		max-width: 1920px;
		padding: 0px 100px 124px;
		@include mq-min-max(no, sm) {
			padding: 0px 15px 60px;
		}
	}

	&__header {
		margin-bottom: 59px;
		padding-bottom: 26px;
		border-bottom: 1px solid white;
		@include mq-min-max(no, sm) {
			margin-bottom: 35px;
			padding-bottom: 20px;
		}
	}

	&__header-title {
		margin: 0;
		font-size: 30px;
		line-height: 1;
		@include mq-min-max(no, sm) {
			font-family: $font-haas-tx;
			line-height: 38px;
		}
	}

	&__locations {
		display: grid;
		grid-column-gap: 40px;
		grid-row-gap: 40px;
		grid-template-rows: auto;

		@include mq(lg) {
			grid-template-columns: repeat(3, 1fr);
		}
		@include mq(md) {
			grid-template-columns: repeat(3, 1fr);
		}
		@include mq(sm) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include mq-min-max(no, sm) {
			display: flex;
			flex-direction: column;
			grid-row-gap: 70px;
		}
	}

	&__location-office {
		margin: 0;
		line-height: 1.3;
		padding-bottom: 31px;
		font-size: 22px;
	}

	&__location-address {
		padding-bottom: 33px;
		font-size: 22px;
		line-height: 30px;
	}

	&__location-telephone {
		margin-bottom: 0px;
		padding-bottom: 7px;
		font-size: 22px;
		line-height: 1;
	}

	&__location-email {
		margin-bottom: 0px;
		padding-bottom: 38px;
		font-size: 22px;
		line-height: 1;
		word-break: break-all;
	}

	&__location-directions {
		color: white;
		font-size: 22px;
		line-height: 1;
	}
}
