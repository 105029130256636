.career-details {
	width: 100%;
	max-width: 1920px;
	padding: 0 100px;
	margin: 0 auto;

	@include mq-min-max(no, md2) {
		padding: 0 15px;
	}

	&__grey-line {
		border-top: 1px solid #c4c4c4;
	}

	&__breadcrumbs {
		@include rem(12);
		display: flex;
		flex-direction: row;

		&--paragraph {
			margin-right: 5px;
			color: #393939;
			text-decoration: none;
			font-weight: $weight-semibold;
		}
	}

	&__content-inner {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 2em;
		margin-bottom: 50px;

		@include mq-min-max(no, xl) {
			flex-direction: column;
			align-items: center;
		}
	}

	&__content {
		width: 50%;

		@media (min-width: 1500px) {
			&:last-child {
				max-width: 693px;
			}
		}

		@include mq-min-max(sm, xl) {
			width: 75%;
		}

		@include mq-min-max(no, sm) {
			width: 100%;
		}

		&--title {
			@include rem(74);
			font-weight: $weight-bold;
			border-bottom: 1px solid #c4c4c4;
			padding-bottom: 20px;
			@include mq-min-max(xs, md2) {
				@include rem(45);
			}
			@include mq-min-max(no, xs) {
				@include rem(30);
			}
		}

		&--related-files {
			@include rem(20);
			font-weight: $weight-bold;
			border-bottom: 1px solid #c4c4c4;
			padding-bottom: 15px;
		}

		&--summary {
			@include rem(30);
			font-weight: $weight-bold;
			@include mq-min-max(no, sm) {
				@include rem(22);
			}
		}

		&--bold-paragraph,
		&--bold-paragraph-title {
			@include rem(22);
			font-weight: $weight-bold;
			@include mq-min-max(no, sm) {
				@include rem(18);
			}
		}

		&--bold-paragraph-title {
			margin-top: 50px;
		}

		&--description {
			@include rem(22);
			@include mq-min-max(no, sm) {
				@include rem(18);
			}
		}

		&--list-title {
			@include rem(22);
			font-weight: $weight-bold;
			width: 170px;
			margin: 0;
			@include mq-min-max(no, sm) {
				@include rem(18);
			}
		}

		&--list-description {
			@include rem(22);
			margin: 0;
			@include mq-min-max(no, sm) {
				@include rem(18);
			}
			@include mq-min-max(no, xxs) {
				width: 125px;
			}
		}

		ul {
			padding: 0;
		}

		li {
			display: flex;
			margin: 20px 0px;
		}
	}

	&__content-middle {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 25px 0;
		border-bottom: 1px solid #c4c4c4;

		&--link {
			color: #393939;
			text-decoration: none;
			@include rem(20);
			@include mq-min-max(no, sm) {
				@include rem(17);
			}
		}

		&--button {
			background-color: #bb3530;
			color: white;
			padding: 0px 30px;
			text-decoration: none;
			display: flex;
			width: 220px;
			height: 70px;
			justify-content: space-between;
			align-items: center;
			@include rem(24);
			font-weight: $weight-bold;
			@include mq-min-max(no, sm) {
				@include rem(18);
			}
		}

		&--arrow {
			max-height: 20px;
			max-width: 45px;
		}
	}

	&__downloads {
		@include rem(20);
		font-weight: $weight-bold;
		width: 100%;
		border-bottom: 1px solid #c4c4c4;
		padding-bottom: 20px;
	}

	&__file {
		display: flex;
		flex-direction: row;
		align-items: center;

		&--image {
			max-width: 36px;
			max-height: 46px;
		}

		&--description {
			@include rem(20);
			font-weight: $weight-bold;
			margin-left: 20px;
			padding-right: 20px;
		}

		&--download {
			max-height: 20px;
			max-width: 18px;
			margin-left: auto;
		}
	}
}
