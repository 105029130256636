/* ==========================================================================
   Header  
   ========================================================================== */

.standard-header {
	position: relative;
	display: flex;
	width: 100%;
	justify-content: center;
	font-family: $font-haas;
	color: white;

	&.margin{
		margin-bottom: 50px;
		@include mq-down(md){
			padding-bottom: 25px;
		}
	}

	&.extended {
		@include mq(md){
			margin-bottom: 0;
			padding-bottom: 200px;
			margin-bottom: -200px;
		}
	}

	&___content {
		width: 100%;
		max-width: 1920px;
		padding: 33px 100px;

		&.narrow {
			padding: 33px 100px;
		}
		@include mq-down(xs){
			padding: 14px 15px;

			&.narrow {
				padding: 14px 15px;
			}
		}
	}

	&__breadcrumbs {
		padding-bottom: 60px;
		@include rem(12);
		font-weight: 600;
	}

	&__breadcrumbs-link {
		display: inline-block;
		color: white;
		text-decoration: none;
	}

	&__main {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 50px;

		@include mq-down(md){
			gap: 30px;
			grid-template-columns: 1fr;
		}
	}

	&__content{
		width: 100%;
	}

	&__title {
		font-family: $font-haas-tx;
		margin: 0;
		@include fontSize(30,74);
		font-weight: $weight-bold;
	}

	&__intro {
		h2{
			@include fontSize(18,23);
			padding-bottom: 30px;
			margin: 0;
		}

		p{
			margin-top: 0;
			font-weight: $weight-medium;
			@include fontSize(18, 22);
		}
	}

	&__background{
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		img{
			display:block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}


// Widget styling

// Funded SVQ


.funded-svq-header-widget{

	width: 100%;
	padding-top: 50px;

	&__bottom-box {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
	
	&__links {
		display: flex;
		flex-direction: row;
		align-items: center;
		@include rem(18);
		font-weight: $weight-semibold;
		@include mq-min-max(no, xs) {
			@include rem(13);
		}
	}
	
	&__links-desktop {
		display: flex;
		flex-direction: row;
		@include mq-min-max(no, xl) {
			display: none;
		}
	
		&--link {
			background-color: transparent;
			border: 1px solid white;
			text-decoration: none;
			color: white;
			padding: 20px 40px;
			border-radius: 30px;
			margin: 0px 10px;
			transition: 0.3s;
			cursor: pointer;
	
			@include mq-min-max(xl, xl2) {
				padding: 15px 25px;
			}
	
			&:hover,
			&.active {
				background-color: white;
				color: $red;
			}
		}
	}
	
	&__links-mobile {
		display: none;
	
		@include mq-min-max(no, xl) {
			display: block;
		}
	
		@include mq-min-max(no, xs) {
			@include rem(13);
		}
	
		select {
			margin-left: 15px;
			padding: 20px 40px;
			background-color: transparent;
			border: 1px solid white;
			color: white;
			border-radius: 30px;
	
			@include mq-min-max(no, xs) {
				padding: 5px 0px;
				width: 80px;
			}
		}
	}
	
	&__view-selector {
		display: flex;
		flex-direction: row;
	
		img {
			width: 35px;
			height: 30px;
			margin-right: 20px;
			cursor: pointer;
			transition: 0.3s;
			filter: opacity(0.6);
	
			&:hover {
				filter: opacity(1);
			}
	
			&.active {
				filter: opacity(1);
			}
		}
	}
}

.news-header-widget{
	padding-top: 50px;

	@include mq(md){
		padding-bottom: 50px;
		margin-bottom: -50px;
	}

	&__links {
		width: auto;
	}

	&__single-link {
		color: white;
		border: 1px solid white;
		padding: 15px 30px;
		margin-right: 15px;
		width: 30px;
		width: 117px;
		display: inline-block;
		text-align: center;
		border-radius: 25px;
		text-decoration: none;
		transition: 0.4s;

		&.active {
			background-color: white;
			color: $red;
		}

		&:hover {
			background-color: white;
			color: $red;
		}
		@include mq-min-max(no, xs) {
			padding: 15px 0px;
			margin-right: 4px;
			width: 80px;
		}
	}
}