/* ==========================================================================
   Tab Header
   ========================================================================== */

.tab-header {
	position: relative;
	height: 100%;
	font-family: $font-haas;
	color: white;

	&__content {
		display: flex;
		width: 100vw;
		justify-content: center;
	}

	&__content-inner {
		width: 100%;
		padding: 28px 100px 80px;
		line-height: 1;
		max-width: 1920px;

		@include mq-min-max(no, xs) {
			padding: 8px 15px 34px;
		}
	}

	&__background {
		position: absolute;
		top: 0px;
		z-index: -1;
		display: block;
		overflow: hidden;
		width: 100%;
		height: 100%;
	}

	&__background-image {
		position: absolute;
		right: 0px;
		bottom: -493px;
		width: auto;

		@include mq-min-max(no, xs) {
			left: -64vw;
			bottom: -110vw;
			max-width: 230%;
		}
	}

	&__breadcrumbs {
		display: inline-block;
		padding-bottom: 66px;
		color: #fff;
		font-size: 12px;
		line-height: 1;
		font-weight: 700;
		text-decoration: none;
	}

	&__title {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 47px;
		font-size: 74px;
		line-height: 1;

		@include mq-min-max(no, xs) {
			padding-bottom: 25px;
			font-family: $font-haas-tx;
			font-size: 30px;
		}
	}

	&__background-overlay {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		background-color: $red;

		@include mq-min-max(no, xs) {
			display: block;
		}
	}

	&__tab-selected {
		display: none;
		min-width: 205px;
		margin-right: 17px;
		padding: 17px 36px;
		border-radius: 25px;
		background-color: white;
		transition: color 250ms ease, background-color 250ms ease;
		color: $red;
		font-size: 16px;
		font-weight: 600;
		text-align: center;
		text-decoration: none;

		@include mq-min-max(no, xs) {
			display: block;
			min-width: auto;
			margin-right: 0px;
			padding: 9px 55px 10px 14px;
			border-style: none;
			background-color: transparent;
			color: #fff;
			font-size: 13px;
			text-align: left;
		}
	}

	&__category-inner {
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: 15px;
	}

	&__desktop-tabs {
		cursor: pointer;
		@include rem(16);
		font-weight: bold;
		border: 1px solid white;
		border-radius: 35px;
		padding: 20px 25px;
		display: flex;
		align-items: center;
		text-align: center;

		transition: 0.4s ease-in-out;

		@include mq-min-max(md2, lg2) {
			padding: 5px 25px;
		}

		@include mq-min-max(no, md2) {
			display: none;
		}

		&:hover,
		&.active {
			background-color: $white;
			color: $red;
		}
	}

	&__mobile-tabs {
		@media (min-width: 1024px) {
			display: none;
		}

		@include rem(13);
		font-weight: bold;
		display: flex;
		flex-direction: row;
		gap: 15px;
		align-items: center;

		select {
			background: transparent;
			border: 1px solid white;
			color: white;
			padding: 10px 25px 10px 13px;
			border-radius: 30px;
			font-weight: bold;
		}
	}
}
