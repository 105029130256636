/* ==========================================================================
    Home What 
   ========================================================================== */

.home-what {
	position: relative;
	display: flex;
	width: 100vw;
	margin-bottom: 110px;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background-color: $red;
	font-family: $font-haas;
	color: white;
	font-weight: 700;

	@include mq-min-max(no, md) {
		height: auto;
		margin-bottom: 0px;
		padding-bottom: 0px;
		flex-direction: column;
		justify-content: flex-start;
		background-color: $red;
	}

	&__inner {
		position: relative;
		display: flex;
		width: 100%;
		max-width: 1920px;
		padding-left: 100px;

		@include mq-min-max(no, md) {
			padding-right: 17px;
			padding-bottom: 0px;
			padding-left: 17px;
		}
	}

	&__header {
		display: flex;
		width: 100%;
		margin-bottom: 75px;
		padding-top: 106px;
		padding-bottom: 26px;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid white;
		font-weight: 700;

		@include mq-min-max(no, md) {
			margin-bottom: 46px;
			padding-top: 38px;
			padding-bottom: 12px;
		}
	}

	&__header-title {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 22px;
		line-height: 1;

		@include mq-min-max(no, md) {
			padding-bottom: 0px;
			font-size: 18px;
		}
	}

	&__content {
		width: 100%;
		padding-right: 100px;
		padding-bottom: 125px;
		padding-left: 145px;
		background-color: $red;

		@include mq-min-max(no, md) {
			padding-right: 0px;
			padding-bottom: 37px;
			padding-left: 0px;
		}
	}

	&__main {
		display: flex;

		@include mq-min-max(no, md) {
			flex-direction: column;
		}
	}

	&__main-left {
		width: 56%;
		display: flex;
		flex-direction: column;

		@include mq-min-max(no, md) {
			width: 100%;
			padding-bottom: 10px;
		}
	}

	&__main-right {
		display: flex;
		width: 44%;
		flex-direction: column;
		align-items: flex-start;
		transition: 0.4s ease;

		@include mq-min-max(no, md) {
			width: 100%;
		}
	}

	&__wrapper {
		width: 100%;
		transition: 0.4s ease-in;

		&.loading {
			opacity: 0;
		}
	}

	&__links {
		display: inline-block;
		padding-top: 10px;
		padding-bottom: 40px;
		transition: color 300ms ease;
		font-family: $font-haas-tx;
		color: $red-link;
		font-size: 68px;
		line-height: 1;
		text-decoration: none;
		cursor: pointer;

		&.active {
			color: white;
		}

		@include mq-min-max(xs, xl) {
			font-size: 45px;
		}

		@include mq-min-max(no, xs) {
			font-size: 30px;
		}

		@include mq-min-max(no, md) {
			padding-top: 0px;
			padding-bottom: 22px;
			line-height: 1.1;
		}
	}

	&__links:hover {
		color: white;
	}

	&__image {
		width: 100%;
		img {
			width: 100%;
			margin-bottom: 49px;
			aspect-ratio: 3/2;

			@include mq-min-max(no, md) {
				margin-bottom: 27px;
			}
		}
	}

	&__description {
		padding-bottom: 44px;
		font-size: 22px;
		line-height: 30px;
		font-weight: 600;

		@include mq-min-max(no, md) {
			padding-bottom: 34px;
			font-size: 18px;
			line-height: 26px;
		}
	}

	&__button {
		display: flex;
		max-width: 217px;
		padding: 20px 25px;
		justify-content: space-between;
		background-color: white;
		color: $red;
		text-decoration: none;
		transition: 0.2s ease-in-out;

		&:hover {
			background-color: rgb(238, 238, 238);
			padding-right: 15px;
		}

		@include mq-min-max(no, md) {
			min-width: 176px;
			padding: 13px 21px;
		}
	}

	&__button-text {
		font-size: 16px;
		line-height: 1;
		margin: 0px;
	}

	&__button-image {
		width: 28px;
		height: auto;
	}

	&__inner-background {
		position: absolute;
		left: 0px;
		display: flex;
		width: 100px;
		height: 101%;
		flex-direction: column;

		@include mq-min-max(no, md) {
			display: none;
		}
	}

	&__inner-background-grey {
		height: 22vh;
		background-color: #f8f8f9;

		@include mq-min-max(no, md) {
			display: none;
		}
	}

	&__inner-background-white {
		flex: 1;
		background-color: white;
	}

	&__background {
		position: absolute;
		left: 0px;
		display: flex;
		width: 20%;
		height: 101%;
		flex-direction: column;
		@include mq-min-max(no, md) {
			display: none;
		}
	}

	&__background-grey {
		width: 100%;
		height: 22vh;
		background-color: #f8f8f9;
	}

	&__background-white {
		width: 100%;
		flex: 1;
		background-color: white;
	}
}
