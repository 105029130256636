/* --------------------------------
    Form Inputs
   -------------------------------- */

.form-input {
	@include rem(12);

	width: 100%;
	height: var(--input-size, 50px);
	padding: var(--input-padding, 6px 0px 5px 0px);
	transition: border-color 0.2s ease-in-out;
	color: var(--input-color, #{$color-secondary});
	border: 0;
	border-style: solid;
	border-color: var(--input-border-color, #{$color-secondary});
	border-bottom-width: var(--input-border-width, 1px);
	outline: none;
	background-color: var(--input-background, transparent);
	appearance: none;

	@include mq(md) {
		@include rem(16);

		--input-size: 50px;
	}

	&--border {
		border-width: var(--input-border-width, 1px);
	}

	&--border-bottom {
		border-width: 0 0 var(--input-border-width, 1px) 0;
	}

	&.is-active,
	&:focus {
		// --input-border-color: var(--input-border-color-active, var(--input-border-color));
	}
}
