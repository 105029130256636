/* ==========================================================================
   Pod Type One
   ========================================================================== */

.pod-type-one {
	position: relative;
	display: flex;
	width: 100vw;
	height: 100%;
	justify-content: center;
	font-family: $font-haas;
	color: $black-slate;

	&__inner {
		display: flex;
		width: 100%;
		max-width: 1920px;
		padding: 60px 100px;
		@include mq-min-max(sm, lg) {
			padding: 60px 15px;
		}
		@include mq-min-max(no, sm) {
			padding: 30px 15px;
			flex-direction: column;
		}
	}

	&__left {
		display: flex;
		width: 40%;
		min-width: 40%;
		margin-right: 45px;
		padding-top: 28px;
		padding-right: 0px;
		padding-bottom: 95px;
		flex-direction: column;
		border-top: 1px solid $black-slate;
		@include mq-min-max(no, sm) {
			width: 100%;
			margin-right: 0px;
			padding-top: 26px;
			padding-bottom: 19px;
		}
	}

	&__right {
		width: 100%;
		@include mq-min-max(no, sm) {
			aspect-ratio: 0.9;

			&.learning {
				aspect-ratio: 16 / 9;
			}
		}
	}

	&__title {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 70px;
		font-size: 40px;
		line-height: 1;
		@include mq-min-max(no, sm) {
			padding-bottom: 40px;
			font-size: 30px;
			line-height: 36px;
		}
	}

	&__description {
		padding-right: 70px;
		padding-bottom: 96px;
		font-size: 18px;
		line-height: 24px;
		font-weight: 500;
		@include mq-min-max(no, sm) {
			padding-right: 0px;
			padding-bottom: 40px;
		}
	}

	&__button {
		display: flex;
		width: 258px;
		padding: 20px 31px;
		justify-content: space-between;
		align-self: flex-start;
		background-color: $red;
		color: white;
		font-size: 16px;
		line-height: 1;
		font-weight: 700;
		text-decoration: none;
		transition: 0.2s ease-in-out;

		&:hover {
			padding-right: 20px;
		}
	}

	&__button-text {
		margin: 0;
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}
