/* ==========================================================================
About Pledge
   ========================================================================== */

.about-pledge {
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	font-family: $font-haas;
	color: $black-slate;

	@include mq-min-max(no, md) {
		width: 100vw;
		min-height: auto;
		padding-right: 0px;
		padding-bottom: 0px;
		padding-left: 0px;
	}

	&___content {
		width: 100%;
		max-width: 1920px;
		padding: 87px 100px 175px;

		@include mq-min-max(no, md) {
			padding: 58px 15px 50px;
		}
	}

	&__header {
		margin-bottom: 64px;
		padding-bottom: 26px;
		border-bottom: 1px solid $black-slate;
		font-size: 9px;
		line-height: 1;
		font-weight: 600;

		@include mq-min-max(no, md) {
			margin-bottom: 41px;
			padding-bottom: 9px;
		}
	}

	&__left {
		width: 50%;
		padding-right: 40px;

		@include mq-min-max(no, md) {
			width: 100%;
			padding-right: 0px;
		}
	}

	&__right {
		padding-top: 43px;
		padding-left: 20px;
		flex: 1;

		@include mq-min-max(no, md) {
			padding: 0;
		}
	}

	&__main {
		display: flex;
		justify-content: space-between;

		@include mq-min-max(no, md) {
			flex-direction: column;
			justify-content: flex-start;
		}
	}

	&__title {
		p {
			margin-top: 0px;
			margin-bottom: 0px;
			font-size: 74px;
			line-height: 88px;
			font-weight: bold;

			@include mq-min-max(md, lg) {
				font-size: 40px;
				line-height: 60px;
			}

			@include mq-min-max(no, md) {
				padding-bottom: 41px;
				font-size: 30px;
				line-height: 36px;
			}
		}
	}

	&__intro {
		p {
			margin-top: 0px;
			margin-bottom: 0px;
			padding-bottom: 31px;
			font-size: 22px;
			font-weight: bold;

			@include mq-min-max(no, md) {
				padding-bottom: 23px;
				font-size: 18px;
				line-height: 24px;
			}
		}
	}

	&__description {
		p {
			font-size: 22px;
			line-height: 30px;
			font-weight: 500;

			@include mq-min-max(no, md) {
				font-size: 18px;
				line-height: 24px;
			}
		}
	}

	&__header-title {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 22px;
		line-height: 1;

		@include mq-min-max(no, md) {
			font-size: 18px;
		}
	}
}
