/* --------------------------------
   Container Mixin
   -------------------------------- */

@mixin make-container($wide: false, $gutter: $container-padding-x) {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @include make-container-padding($gutter);

  @if $wide == false {
    max-width: $container-max-width;
  }
}

@mixin make-container-padding($gutter: $container-padding-x) {
  padding-right: $gutter;
  padding-left: $gutter;
}
