/* Interpolates a property between two breaks

If no breakpoint is specified, the property will be interpolated
between 375px and 1920px

@include responsive(font-size, 20px, 60px);
@include responsive(height, 200px, 1000px, xs, md2);
*/

$listAnimations: ();
$breakpointMin: 375;
$breakpointMax: 1920;
$breaks: (
	no: 0,
	xxs: 470,
	xs: 576,
	sm: 768,
	md: 992,
	md2: 1024,
	lg: 1200,
	lg2: 1390,
	xl: 1500,
	xl2: 1650,
	xxl: 1800,
	wumbo: 1920,
);

// Media query
@mixin mq-min-max($breakpoint1, $breakpoint2) {
	// If the breakpoint exists in the map.
	@if map-has-key($breaks, $breakpoint1) and map-has-key($breaks, $breakpoint2) {
		// Get the breakpoint value.
		$breakpoint-value1: map-get($breaks, $breakpoint1);
		$breakpoint-value2: map-get($breaks, $breakpoint2);

		// Write the media query.
		@media only screen and (min-width: ($breakpoint-value1) + 'px') and (max-width: ($breakpoint-value2 - 1) + 'px') {
			@content;
		}

		// If the breakpoint doesn't exist in the map.
	} @else {
		// Log a warning.
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

// Animate Property Between Two Media Queries
@mixin responsive($property, $mobile, $desktop, $mqBottom: '', $mqTop: '') {
	$selector: str-replace(#{&}, '.', '');
	$mqTopPrefix: 'DE';
	$mqBottomPrefix: 'MO';

	@if ($mqTop != '') {
		$mqTopPrefix: to-upper-case(str-slice($mqTop, 1, 2));
	}

	@if ($mqBottom != '') {
		$mqBottomPrefix: to-upper-case(str-slice($mqBottom, 1, 2));
	}

	$keyframe: str-replace($selector, ' ', '_') + '--' + $property + '-' + $mqBottomPrefix +
		$mqTopPrefix;

	$animation: #{$keyframe} 1s linear 1 calc(-1s * var(--width) / 1920) both paused;

	$currentSelector: str-replace($selector, ' ', '_');

	$breakpointBottom: $breakpointMin;
	$breakpointTop: $breakpointMax;

	@if ($mqBottom != '') {
		$breakpointBottom: map-get($breaks, $mqBottom);
	}

	@if ($mqTop != '') {
		$breakpointTop: map-get($breaks, $mqTop);
	}

	$start: 100 / ($breakpointMax / $breakpointBottom);
	$end: 100 / ($breakpointMax / $breakpointTop);

	$breakpoint-value1: map-get($breaks, $mqBottom);
	$breakpoint-value2: map-get($breaks, $mqTop);

	$otherSelector: checkotherSelector($currentSelector);

	@if $otherSelector == true {
		$listAnimations: () !global;
	}

	@if ($mqTop == '' and $mqBottom == '') {
		@keyframes #{$keyframe} {
			0%,
			#{$start}% {
				#{$property}: $mobile;
			}

			#{$end}%,
			100% {
				#{$property}: $desktop;
			}
		}

		$listAnimations: append($listAnimations, $animation) !global;
		@include mergeAnimations($listAnimations...);
	} @else {
		@media only screen and (min-width: ($breakpoint-value1) + 'px') and (max-width: ($breakpoint-value2 - 1) + 'px') {
			@keyframes #{$keyframe} {
				0%,
				#{$start}% {
					#{$property}: $mobile;
				}

				#{$end}%,
				100% {
					#{$property}: $desktop;
				}
			}

			$listAnimations: append($listAnimations, $animation) !global;
			@include mergeAnimations($listAnimations...);
		}
	}
}
