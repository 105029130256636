.topnav.fof {
	background-color: transparent;
}

.not-found {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	background-color: rgba($color: #000000, $alpha: 0.4);

	&__background {
		position: fixed;
		width: 100%;
		height: 100vh;
		top: 0;
		left: 0;
		background-image: url('/theme/images/site/404.png');
		z-index: -1;
		background-repeat: no-repeat;
		background-size: cover;
	}

	&__inner {
		width: 100%;
		max-width: 1920px;
		min-height: 100vh;
		@include mq(md) {
			padding-left: 100px;
			padding-right: 100px;
		}
		padding-left: 15px;
		padding-right: 15px;
		padding-top: clamp(135px, 15vw, 220px);
	}

	&__content {
		h1 {
			font-size: 22px;
			font-weight: bold;
			line-height: 30px;
		}
		h2 {
			font-size: 75px;
			font-weight: bold;
			line-height: 93px;
			margin: 0;
		}
		h3 {
			font-size: 40px;
			line-height: 50px;
			font-weight: normal;
		}
		p {
			font-weight: bold;
		}
		p,
		a {
			font-size: 18px;
			line-height: 30px;
			color: white;
		}

		&-links {
			display: flex;
			flex-direction: column;
		}
	}
}
