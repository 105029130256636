/* ==========================================================================
    Checkout
   ========================================================================== */

.checkout {
	position: relative;
	justify-content: center;
	font-family: $font-haas;
	color: $black-slate;

	&__background {
		position: relative;
		overflow: hidden;
		width: 100vw;
		height: 90px;
		opacity: 1;
	}

	&__background-logo {
		position: absolute;
		right: 0px;
		bottom: -760px;
		z-index: 1;
		width: 100%;
		height: auto;
	}

	&__overlay {
		position: absolute;
		z-index: 0;
		width: 100%;
		height: 100%;
		background-color: $red;
	}

	&__main {
		display: flex;
		width: 100vw;
		justify-content: center;
		flex-direction: column;

		@include mq(md) {
			flex-direction: row;
		}
	}

	&__form-inner {
		display: flex;
		width: 100%;

		@include mq-min-max(xs, md) {
			padding: 15px;
			gap: 15px;
			flex-direction: column;
		}

		@include mq-min-max(no, xs) {
			padding: 15px;
			gap: 15px;
			flex-direction: column-reverse;
		}
	}

	&__left-column {
		background-color: transparent;

		@include mq(md) {
			width: 51.2vw;
			display: flex;
			justify-content: flex-end;
		}

		@include mq-min-max(no, xs) {
			width: 100%;
			order: 1;
		}
	}

	&__red-margin {
		height: 74%;
		min-width: 100px;
		flex: 1;
		background-color: $red;

		@include mq-min-max(no, md) {
			display: none;
		}
	}

	&__user-details {
		display: flex;
		width: 100%;

		flex-direction: column;
		background-color: white;

		@include mq(xl) {
			padding: 71px 131px 100px 115px;
		}
		@include mq(md) {
			padding: 71px 15px 100px 15px;
			max-width: 895px;
		}
	}

	&__details-header {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 45px;
		font-size: 30px;

		@include mq-min-max(no, xs) {
			padding-bottom: 25px;
		}
	}

	&__summary-item-image {
		img {
			margin-right: 22px;
			height: 67px;
			width: 100px;

			@include mq-min-max(no, xs) {
				margin-right: 16px;
			}
		}
	}

	&__summary-item-title {
		width: fit-content;
		min-width: 130px;
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 18px;

		@include mq-min-max(no, xs) {
			width: 100%;
			font-size: 16px;
		}
	}

	&__summary-item-info {
		margin: 0 20px;
		min-width: 220px;
		display: flex;
		flex-direction: column;

		&--location{
			margin-top: 0px;
			margin-bottom: 0px;
			font-size: 16px;
			line-height: 1;
		}

		&--date{
			margin-top: 0px;
			margin-bottom: 0px;
			font-size: 16px;
			line-height: 1;
		}
	}

	&__summary-item-quantity {
		font-size: 18px;
		font-weight: 500;
		display: flex;

		@include mq-min-max(no, xs) {
			padding-bottom: 8px;
			font-size: 16px;
		}
	}

	&__summary-item-amount {
		display: flex;
		font-size: 18px;
		font-weight: 500;
		text-align: right;
		margin-left: 1rem;

		@include mq-min-max(no, xs) {
			font-size: 16px;
		}
	}

	&__summary-item-amounts {
		display: flex;
		justify-content: space-between;
		margin-left: auto;

		@include mq-min-max(no, xs) {
			margin-left: 25px;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;
		}
	}

	&__summary-item-content {
		align-items: center;
		display: flex;
		flex-grow: 1;
		
		&--has-image {
			flex-basis: 80%;
		}

		@include mq-min-max(no, xs) {
			flex-direction: row;
		}
	}

	&__breadcrumb {
		position: absolute;
		z-index: 2;
		top: 0px;
		display: flex;
		width: 100vw;
		justify-content: center;
	}

	&__breadcrumb-inner {
		width: 100%;
		max-width: 1920px;
		padding-top: 33px;
		padding-right: 100px;
		padding-left: 100px;
		font-size: 12px;
		line-height: 1;
		font-weight: 600;
	}

	&__breadcrumb-link {
		color: #fff;
		text-decoration: none;
	}

	&__form {
		display: flex;
		width: 100vw;
		justify-content: center;
	}

	&__input-field {
		height: 42px;
		margin-bottom: 0px;
		border-style: solid;
		border-width: 1px;
		border-color: $black-slate;
		border-radius: 4px;
		background-color: transparent;
		font-size: 16px;
		line-height: 1;
		font-weight: 500;
		width: 100%;
		padding-left: 15px;

		&:not(:placeholder-shown) ~ .checkout__input-label,
		&:focus ~ .checkout__input-label {
			top: -9px;
			font-size: 12px;
		}

		&.error {
			border-color: $red;
			border-width: 3px;
		}
	}

	&__error-message {
		color: $red;
		margin: 5px 0 0 0;
		padding-left: 20px;
	}

	&__fieldset {
		position: relative;
		padding-bottom: 22px;
		font-size: 16px;
		line-height: 1;
		font-weight: 500;

		@include mq-min-max(no, xs) {
			padding-bottom: 14px;
		}
	}

	&__input-label {
		position: absolute;
		left: 15px;
		top: 13px;
		margin-top: 0px;
		margin-bottom: 0px;
		padding-right: 4px;
		padding-left: 4px;
		background-color: white;
		pointer-events: none;
		transition: 0.2s ease-in-out;

		&.select {
			top: -9px;
			font-size: 12px;
		}
	}

	&__select-field {
		height: 42px;
		max-width: 133px;
		margin-bottom: 0px;
		border-style: solid;
		border-width: 1px;
		border-color: $black-slate;
		border-radius: 4px;
		background-color: transparent;
		width: 100%;
		padding-left: 15px;

		&.full-width {
			max-width: 100%;
			width: 100%;
		}
	}

	&__checkbox-subscribe {
		display: flex;
		margin-bottom: 0px;
		padding-bottom: 0px;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		@include mq-min-max(no, xs) {
			padding-top: 5px;
		}
	}

	&__form-details {
		margin-bottom: 32px;
		padding-bottom: 35px;
		border-bottom: 1px solid $black-slate;
	}

	&__form-billing {
		display: flex;
		flex-direction: column;
	}
}
