/* -------------------------------
    Base
   ------------------------------- */

:root {
    --red-color: invert(26%) sepia(58%) saturate(1755%) hue-rotate(333deg) brightness(99%) contrast(96%);
    --white-color: brightness(0) invert(1);
    --black-color: brightness(0) invert(0);
    --left-content-height: auto;
}

*,
*:before,
*:after {
    box-sizing: inherit;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

html {
    font-family: $font-haas;
    box-sizing: border-box;

    &.dropdown-active {
        overflow: hidden;
    }

    scroll-behavior: smooth;
}

body {
    &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 4px;
    }

    &.noScroll{
        height: 100%;
        overflow: hidden;
    }

    overflow-x: hidden;
}

@mixin fontSize($min, $max) {
    $min-vw: ($min / 320) * 100vw; // 375 is the default mobile device width
    $max-vw: ($max / 1920) * 100vw; // 1920 is our default desktop device width
    $vw: $min-vw - $max-vw;
    $size: clamp(#{$min}px, #{$vw}, #{$max}px);
    font-size: $size;
}

.learning-portal-button{
    padding: 18px;
    display: flex;
    gap: 13px;
    justify-content: center;
    align-items: center;
    width: fit-content;
    text-decoration: none;
    margin-right: 50px;

    @include rem(16);
    font-weight: $weight-bold; 
    color: white;

    img{
        width: 22px;
    }

    &.desktop{
        background-color: $red;
        transition: 0.4s ease;
        padding: 0 25px;
        height: 50px;
        @include mq-down(md){
            display: none;
        }
        &:hover{
            background-color: $red-hover;
        }
    }

    &.mobile{
        background-color: $red-hover;
        @include mq(md){

            display: none;
        }
    }
}