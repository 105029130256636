/* ==========================================================================
    Payment
   ========================================================================== */

.payment {
	position: relative;
	justify-content: center;
	font-family: $font-haas;
	color: $black-slate;

	&__background {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 90px;

		@include mq-min-max(no, xs) {
			display: none;
		}
	}

	&__background-logo {
		position: absolute;
		right: 0px;
		bottom: -760px;
		z-index: 1;
	}

	&__overlay {
		position: absolute;
		z-index: 0;
		width: 100%;
		height: 100%;
		background-color: $red;
	}

	&__main {
		display: flex;
		width: 100%;
		justify-content: center;
	}

	&__main-outer {
		display: flex;
		width: 100%;

		@include mq-min-max(no, md) {
			flex-direction: column-reverse;
		}
	}

	&__main-inner {
		display: flex;
		justify-content: space-between;
	}

	&__left-column {
		display: flex;
		background-color: transparent;
		width: 100%;

		@include mq(md) {
			width: 50%;
		}
	}

	&__right-column {
		display: flex;
		flex: 1;

		@include mq-min-max(no, xs) {
			width: 100%;
		}
	}

	&__red-margin {
		height: 74%;
		min-width: 100px;
		flex: 1;
		background-color: $red;

		@include mq-min-max(no, md) {
			display: none;
		}
	}

	&__cards {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 15px;
	}

	&__summary {
		width: 100%;
		max-width: 835px;
		padding: 71px 105px 160px 113px;
		background-color: $white-smoke;

		@include mq-min-max(no, xs) {
			padding: 40px 15px;
		}
	}

	&__right-margin {
		flex: 1;
	}

	&__grey-margin {
		min-width: 100px;
		flex: 1;
		background-color: $white-smoke;

		@include mq-min-max(no, xs) {
			display: none;
		}
	}

	&__cards-header {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 49px;
		font-size: 30px;

		@include mq-min-max(no, xs) {
			padding-bottom: 25px;
		}
	}

	&__card {
		display: flex;
		max-height: 53px;
		padding-top: 17px;
		padding-bottom: 17px;
		padding-left: 13px;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid $silver;

		@include mq-min-max(no, xs) {
			padding-left: 5px;
		}
	}

	&__card.paypal {
		max-height: none;
		padding-top: 22px;
		padding-bottom: 22px;
	}

	&__card-logo {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	&__card-logo-wrapper {
		display: flex;
		width: 50px;
		max-height: 35px;
		margin-right: 12px;
		justify-content: center;
		align-items: center;
	}

	&__card-text {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 0px;
		font-size: 15px;
		line-height: 1;
		font-weight: 600;
	}

	&__card-text-small {
		margin-top: 7px;
		margin-bottom: 0px;
		padding-bottom: 0px;
		color: #a8a8a8;
		font-size: 12px;
		line-height: 1;
		font-weight: 500;
		letter-spacing: 0em;
	}

	&__card-left {
		display: flex;
		align-items: center;
	}

	&__card-arrow {
		width: 8px;
		margin-right: 12px;

		@include mq-min-max(no, xs) {
			margin-right: 5px;
		}
	}

	&__paypal-signin {
		display: flex;
		padding-top: 5px;
		padding-bottom: 5px;
		justify-content: center;
		border-top: 1px solid $silver;
		font-size: 12px;
		line-height: 1;
	}

	&__paypal-text-green {
		font-size: 12px;
		margin-top: 0px;
		margin-bottom: 0px;
		color: rgba(4, 130, 4, 0.7);
		line-height: 1;
		font-weight: 600;
	}

	&__paypal-text-black {
		font-size: 12px;
		margin: 0px 5px;
		color: $black-slate;
		line-height: 1;
		font-weight: 600;
	}

	&__summary-header {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 19px;
		font-size: 30px;
		line-height: 36px;

		@include mq-min-max(no, xs) {
			padding-bottom: 20px;
		}
	}

	&__summary-items {
		border-bottom: 1px solid $silver;
	}

	&__summary-item {
		display: flex;
		padding-top: 7px;
		padding-bottom: 7px;
		align-items: center;
		border-top: 1px solid $silver;
	}

	&__summary-item-image {
		height: 67px;
		margin-right: 22px;

		@include mq-min-max(no, xs) {
			margin-right: 16px;
		}
	}

	&__summary-item-title {
		width: 74%;
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 18px;
		line-height: 24px;

		@include mq-min-max(no, xs) {
			width: 100%;
			font-size: 16px;
		}
	}

	&__summary-item-quantity {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 18px;
		line-height: 1;
		font-weight: 500;

		@include mq-min-max(no, xs) {
			padding-bottom: 8px;
			font-size: 16px;
		}
	}

	&__summary-item-amount {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 18px;
		line-height: 1;
		font-weight: 500;
		text-align: right;

		@include mq-min-max(no, xs) {
			font-size: 16px;
		}
	}

	&__summary-totals {
		padding-left: 125px;

		@include mq-min-max(no, xs) {
			padding-left: 0px;
		}
	}

	&__summary-subtotal {
		display: flex;
		padding-top: 19px;
		padding-bottom: 19px;
		justify-content: space-between;
		border-bottom: 1px solid $silver;

		@include mq-min-max(no, xs) {
			padding-top: 16px;
			padding-bottom: 16px;
		}
	}

	&__smmary-subtotal-label {
		margin-top: 0px;
		margin-bottom: 0px;
		line-height: 1;
		font-size: 18px;

		@include mq-min-max(no, xs) {
			font-size: 16px;
		}
	}

	&__summary-subtotal-amount {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 18px;
		line-height: 1;
		font-weight: 500;

		@include mq-min-max(no, xs) {
			font-size: 16px;
		}
	}

	&__summary-total {
		display: flex;
		margin-bottom: 52px;
		padding-top: 20px;
		padding-bottom: 20px;
		justify-content: space-between;
		border-top: 1px solid $black-slate;
		border-bottom: 1px solid $black-slate;

		@include mq-min-max(no, xs) {
			margin-bottom: 40px;
		}
	}

	&__summary-total-label {
		margin-top: 0px;
		margin-bottom: 0px;
		line-height: 1;
		font-size: 24px;

		@include mq-min-max(no, xs) {
			font-size: 22px;
		}
	}

	&__summary-total-amount {
		margin-top: 0px;
		margin-bottom: 0px;
		line-height: 1;
		font-size: 24px;

		@include mq-min-max(no, xs) {
			font-size: 22px;
		}
	}

	&__summary-footer {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		@include mq-min-max(no, xs) {
			align-items: flex-start;
		}
	}

	&__summary-footer-text {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 14px;
		font-size: 16px;
		line-height: 1;

		@include mq-min-max(no, xs) {
			padding-bottom: 10px;
		}
	}

	&__summary-footer-links {
		display: flex;

		@include mq-min-max(no, xs) {
			flex-direction: column;
		}
	}

	&__summary-footer-link {
		color: $black-slate;
		font-size: 16px;
		line-height: 1;
		font-weight: 500;
		text-decoration: none;

		@include mq-min-max(no, xs) {
			padding-bottom: 3px;
			font-size: 14px;
		}
	}

	&__summary-footer-pipe {
		margin: 0px 10px;
		font-size: 16px;
		line-height: 1;
		font-weight: 500;

		@include mq-min-max(no, xs) {
			display: none;
		}
	}

	&__summary-item-amounts {
		display: flex;
		width: 26%;
		justify-content: space-between;

		@include mq-min-max(no, xs) {
			margin-left: 25px;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;
		}
	}

	&__summary-item-content {
		display: flex;
		width: 80%;

		@include mq-min-max(no, xs) {
			flex-direction: row;
		}
	}

	&__cards-table {
		width: 100%;

		form {
			width: 100%;
			align-self: center;
			box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1),
				0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
			border-radius: 7px;
			padding: 40px;
		}

		.hidden {
			display: none;
		}

		#payment-message {
			color: rgb(105, 115, 134);
			font-size: 16px;
			line-height: 20px;
			padding-top: 12px;
			text-align: center;
		}

		#payment-element {
			margin-bottom: 24px;
		}

		/* Buttons and links */
		button {
			background: $red;
			font-family: Arial, sans-serif;
			color: #ffffff;
			border-radius: 4px;
			border: 0;
			padding: 12px 16px;
			font-size: 16px;
			font-weight: 600;
			cursor: pointer;
			display: block;
			transition: all 0.2s ease;
			box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
			width: 100%;
		}
		button:hover {
			filter: contrast(115%);
		}
		button:disabled {
			opacity: 0.5;
			cursor: default;
		}

		/* spinner/processing state, errors */
		.spinner,
		.spinner:before,
		.spinner:after {
			border-radius: 50%;
		}
		.spinner {
			color: #ffffff;
			font-size: 22px;
			text-indent: -99999px;
			margin: 0px auto;
			position: relative;
			width: 20px;
			height: 20px;
			box-shadow: inset 0 0 0 2px;
			-webkit-transform: translateZ(0);
			-ms-transform: translateZ(0);
			transform: translateZ(0);
		}
		.spinner:before,
		.spinner:after {
			position: absolute;
			content: '';
		}
		.spinner:before {
			width: 10.4px;
			height: 20.4px;
			background: #5469d4;
			border-radius: 20.4px 0 0 20.4px;
			top: -0.2px;
			left: -0.2px;
			-webkit-transform-origin: 10.4px 10.2px;
			transform-origin: 10.4px 10.2px;
			-webkit-animation: loading 2s infinite ease 1.5s;
			animation: loading 2s infinite ease 1.5s;
		}
		.spinner:after {
			width: 10.4px;
			height: 10.2px;
			background: #5469d4;
			border-radius: 0 10.2px 10.2px 0;
			top: -0.1px;
			left: 10.2px;
			-webkit-transform-origin: 0px 10.2px;
			transform-origin: 0px 10.2px;
			-webkit-animation: loading 2s infinite ease;
			animation: loading 2s infinite ease;
		}

		@-webkit-keyframes loading {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
		@keyframes loading {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
	}
}
