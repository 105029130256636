/* --------------------------------
    Login
   -------------------------------- */

.login__wrapper {
	display: flex;
	flex-grow: 1;
	align-items: center;
	min-height: 75vh;
	padding-top: 200px;
	padding-bottom: 120px;
}

.login {
	@include make-container();

	max-width: 440px;
	margin-bottom: 100px;

	@include mq(md) {
		@include make-container-padding(50px);
	}

	&__inner {
		@include make-row();
	}

	&-header {
		@include make-col($size: 100%);

		margin-bottom: 40px;

		&__title {
			@include rem(40);

			font-family: $font-alt-gothic;
			font-weight: $weight-black;
			line-height: 0.8;
			margin: 0;
			text-transform: uppercase;
			color: $color-primary;
		}
	}

	&-form {
		@include make-col($size: 100%);

		--button-background: transparent;
		--button-background-hover: transparent;
		--button-border: #1a1b1b;
		--button-color: #2f3435;
		--button-width: 100%;
		--field-margin-bottom: 10px;
		--input-background: #{$color-secondary};
		--input-border-color: #{$color-primary};
		--input-border-color-active: #{$color-primary};
		--input-color: #ffffff;
		--input-padding: 6px 16px 5px 0;
		--label-color: #{$color-primary};
		--label-color-active: #{$color-primary};
		--label-vertical-left: 0;
	}
}
