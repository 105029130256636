/* ==========================================================================
Home Why
   ========================================================================== */

.home-why {
	position: relative;
	display: flex;
	width: 100vw;
	min-height: 100vh;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background-color: transparent;
	font-family: $font-haas;
	color: white;
	font-weight: 700;
	padding-bottom: 120px;

	@include mq-min-max(no, sm) {
		height: auto;
		padding-bottom: 80px;
		flex-direction: column;
		justify-content: flex-start;
	}

	&__background {
		position: absolute;
		z-index: -1;
		overflow: hidden;
		width: 100%;
		height: 100%;
		background-color: $dark-grey;
	}

	&__content {
		width: 100%;
		max-width: 1920px;
		padding-right: 100px;
		padding-left: 100px;

		@include mq-min-max(no, sm) {
			padding-right: 17px;
			padding-left: 17px;
		}
	}

	&__header {
		padding-top: 98px;
		border-bottom: 1px solid white;
		font-weight: 700;

		@include mq-min-max(no, sm) {
			padding-top: 47px;
		}
	}

	&__main {
		display: flex;
		padding-top: 54px;
		justify-content: space-between;

		@include mq-min-max(no, sm) {
			padding-top: 43px;
			flex-direction: column-reverse;
			justify-content: flex-start;
		}
	}

	&__main-left {
		width: 50%;

		@include mq-min-max(no, sm) {
			width: 100%;
		}
	}

	&__main-right {
		width: 50%;
		padding-right: 10px;
		padding-left: 22px;

		@include mq-min-max(no, sm) {
			display: flex;
			width: 100%;
			padding-right: 0px;
			padding-left: 0px;
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__header-title {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 26px;
		font-size: 22px;
		line-height: 1;

		@include mq-min-max(no, sm) {
			padding-bottom: 10px;
			font-size: 18px;
		}
	}

	&__title {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 95px;
		font-size: 74px;
		line-height: 1;

		@include mq-min-max(no, sm) {
			display: none;
		}
	}

	&__map-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__map-image {
		height: 700px;

		@include mq-min-max(no, sm) {
			height: auto;
			width: 100%;
		}
	}

	&__content-description {
		padding-bottom: 78px;
		font-size: 22px;
		line-height: 30px;
		font-weight: 500;

		@include mq-min-max(sm, md) {
			overflow: hidden;
			margin-bottom: 46px;
			padding-bottom: 0px;
			font-size: 17px;
			line-height: 24px;
			margin-top: 200px;
		}
	}

	&__background-logo {
		position: absolute;
		bottom: -5vh;
		width: 140vw;
		transform: translate(0px, 0px);
		object-fit: none;

		@include mq-min-max(no, sm) {
			bottom: 0vh;
			width: 100%;
			height: 100vh;
			transform: scale(2.5) translate(-29px, -105px);
			object-fit: contain;
		}
	}

	&__title-mobile {
		display: none;
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 95px;
		font-size: 74px;
		line-height: 1;

		@include mq-min-max(no, sm) {
			display: block;
			padding-bottom: 29px;
			font-size: 30px;
		}
	}

	&__button-text {
		display: inline-block;
		margin: 0px;
		color: white;
		font-size: 16px;
		line-height: 1;
	}

	&__button {
		width: 230px;
		padding: 20px 30px;

		&:hover {
			padding-right: 20px;
		}

		@include mq-min-max(no, sm) {
			width: auto;
			min-width: 190px;
			padding: 11px 18px;
		}
	}

	&__text {
		cursor: pointer;
		transition: 0.4s ease-in-out;
		&.hover {
			fill: $red;
		}
	}

	&__ellipse {
		cursor: pointer;
		transition: 0.4s ease-in-out;
		&.hover {
			r: 20;
		}
	}

	&__fade-in {
		opacity: 0;
	}
}
