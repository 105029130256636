/* ==========================================================================
    Careers Content
   ========================================================================== */

.careers-content {
	position: relative;
	display: flex;
	justify-content: center;
	font-family: $font-haas;
	color: $black-slate;

	&__inner {
		width: 100%;
		max-width: 1920px;
		padding: 42px 100px 110px;

		@include mq-min-max(no, xs) {
			padding-top: 35px;
			padding-right: 15px;
			padding-left: 15px;
		}
	}

	&__header {
		margin-bottom: 72px;
		padding-bottom: 24px;
		border-bottom: 1px solid $black-slate;

		@include mq-min-max(no, xs) {
			margin: 0;
			padding-bottom: 23px;
		}
	}

	&__header-text {
		margin: 0;
		font-size: 30px;
		line-height: 1;

		@include mq-min-max(no, xs) {
			padding-bottom: 28px;
		}
	}

	&__table-header {
		display: flex;
		padding-bottom: 18px;
		border-bottom: 1px solid $black-slate;

		@include mq-min-max(no, xs) {
			display: none;
		}
	}

	&__table-header-text {
		margin: 0;
		font-size: 20px;
		line-height: 1;

		&.job-title {
			width: 40.5%;
		}

		&.location {
			width: 19.5%;
		}

		&.date {
			width: 14%;
		}
	}

	&__table-row {
		display: flex;
		padding-top: 9px;
		padding-bottom: 8px;
		align-items: center;
		border-bottom: 1px solid $silver;
		font-size: 17px;
		line-height: 1;
		font-weight: 500;

		@include mq-min-max(no, xs) {
			padding-top: 23px;
			padding-bottom: 18px;
			flex-direction: column;
			align-items: flex-start;
			border-bottom-color: $black-slate;
		}
	}

	&__table-row-text {
		margin: 0;
		line-height: 1;

		&.job-title {
			width: 40.5%;

			@include mq-min-max(no, xs) {
				width: 100%;
				padding-bottom: 18px;
				line-height: 24px;
				font-weight: 700;
			}
		}
	}

	&__table-row-button {
		display: flex;
		padding: 19px 25px;
		justify-content: space-between;
		flex: 1;
		border-style: solid;
		border-width: 1px;
		border-color: $red;
		background-color: $red;
		color: white;
		text-decoration: none;

		@include mq-min-max(no, xs) {
			width: 142px;
			margin-top: 15px;
		}
	}

	&__table-row-button-text {
		margin: 0;
		font-size: 16px;
		font-weight: 700;
	}

	&__table-row-label {
		display: none;
		margin: 0;
		font-weight: 700;

		@include mq-min-max(no, xs) {
			display: block;
			width: 117px;
			padding-bottom: 7px;
		}
	}

	&__table-row-cell {
		display: flex;

		&.job-title {
			width: 40.5%;
		}

		&.location {
			width: 19.5%;

			@include mq-min-max(no, xs) {
				width: 100%;
			}
		}

		&.date {
			width: 14%;

			@include mq-min-max(no, xs) {
				width: 100%;
			}
		}

		&.type {
			width: 17.7%;

			@include mq-min-max(no, xs) {
				width: 100%;
			}
		}
	}

	&__dropdown {
		display: none;

		@include mq-min-max(no, xs) {
			display: flex;
			align-items: center;
		}
	}

	&__dropdown-select {
		@include mq-min-max(no, xs) {
			display: flex;
			width: 100%;
			margin: 0;
			padding-top: 13px;
			padding-bottom: 13px;
			padding-left: 23px;
			align-items: center;
			font-size: 16px;
			line-height: 1;
			font-weight: 600;
			border-radius: 25px;
		}
	}
}
