/* --------------------------------
   Typography
   -------------------------------- */

/*
 * Font size in rems
 */
@mixin rem($target, $context: 16) {
	font-size: $target + px;
	font-size: convert($target, $context, rem);
}

/*
 * Hide the text of an element e.g. for image replacement
 */
@mixin hide-text {
	border: 0;
	font: 0/0 a;
	text-shadow: none;
	color: transparent;
}

/*
 * Sets word wrapping properties
 */
@mixin word-wrap($wrap: break-word) {
	overflow-wrap: $wrap;
	word-wrap: $wrap;

	@if $wrap == break-word {
		word-break: break-all;
	} @else {
		word-break: $wrap;
	}
}

/*
 * Sets hyphenation styles
 */
@mixin hyphens($hyphens: manual) {
	hyphens: $hyphens;
}

/*
 * Set an element to hyphenate content
 */
@mixin hyphenate() {
	@include word-wrap();
	@include hyphens(auto);
}

/*
 * Set single line ellipsis
 */
@mixin ellipsis($width: 100%) {
	display: inline-block;
	max-width: $width;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-wrap: normal;
}

/*
 * Letter Spacing
 */
@function tracking($target) {
	@return ($target / 1000) * 1em;
}

@mixin letter-spacing($target) {
	letter-spacing: tracking($target);
}

/*
 * Line Height Crop
 */
@mixin line-height-crop($line-height, $capital-letter: 1) {
	&::before {
		content: '';
		display: block;
		height: 0;
		width: 0;
		margin-top: calc((#{$capital-letter} - #{$line-height}) * 0.5em);
	}
}
