/* --------------------------------
   Layout
   -------------------------------- */

.page-layout {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.layout {
		display: flex;
		flex-grow: 1;
		flex-direction: column;

		@include mq-down(md) {
			overflow-x: hidden;
		}
	}

	.header-container {
		display: flex;
		justify-content: center;
		position: absolute;
		z-index: 200;
		left: 0;
		width: 100%;
		transition: z-index 0.5s 0.3s step-end;

		@include mq(md) {
			z-index: 200;
		}

		&.transition-true {
			transition: transform 0.3s ease-in-out, z-index 0.5s 0.3s step-end;
		}

		&.black {
			.topnav {
				--nav-color: transparent;
				--search-color: black;
				--search-background: rgba(255, 255, 255, 0.3);
				--image-color: brightness(0) invert(0); // black
			}
		}
	}

	.main-container {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		position: relative;

		.main-content {
			display: flex;
			flex-grow: 1;
			flex-direction: column;
			position: relative;
			z-index: 10;
		}

		&--content {
			padding-top: 117px;
			@include mq-min-max(no, xs) {
				padding-top: 90px;
			}
		}
	}

	.main-container.open {
		display: none;
	}

	.main.open {
		display: none;
	}

	.footer-container.open {
		display: none;
	}
}
