.news {
	width: 100%;

	&__content {
		width: 100%;
		max-width: 1920px;
		display: flex;
		flex-direction: row;
		margin-left: auto;
		margin-right: auto;
		padding-left: 100px;
		color: white;
		margin-bottom: 50px;
		max-height: 806px;
		@include mq-min-max(no, lg) {
			flex-direction: column;
			padding: 0px;
			width: 90%;
			max-height: none;
		}
	}

	&__content-img {
		width: 50%;
		@include mq-min-max(no, lg) {
			width: 100%;
		}

		img {
			width: 100%;
			height: 100%;
			max-width: 960px;
			overflow: hidden;
			object-fit: cover;
			object-position: center;

			@include mq-min-max(no, lg) {
				max-width: none;
				padding: 0;
				aspect-ratio: 19/13;
			}
		}
	}

	&__content-text {
		padding-left: 50px;
		padding-right: 50px;
		width: 50%;
		background-color: #424957;
		font-weight: $weight-bold;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@include mq-min-max(no, lg) {
			width: 100%;
		}
	}

	&__content-top {
		h3 {
			max-width: 693px;
			@include rem(40);
			@include mq-min-max(no, xs) {
				@include rem(30);
			}
		}

		p {
			max-width: 546px;
			@include rem(22);
			@include mq-min-max(no, xs) {
				@include rem(18);
			}
		}

		a {
			background-color: #bb3530;
			display: flex;
			align-items: center;
			height: 55px;
			width: 216px;
			color: white;
			text-decoration: none;
			padding: 20px;
			justify-content: space-between;
			@include rem(16);
			margin-bottom: 20px;
			transition: 0.2s ease-in-out;

			&:hover {
				padding-right: 10px;
			}
		}

		img {
			max-width: 28px;
		}
	}

	&__content-footer,
	&__content-footer-black {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
	}

	&__content-footer {
		border-top: 1px solid white;
	}

	&__content-footer-black {
		border-top: 1px solid black;
	}

	&__bottom-content {
		width: 100%;
		max-width: 1920px;
		margin-left: auto;
		margin-right: auto;
		padding: 0px 100px;

		@include mq-min-max(no, xs) {
			padding: 0px;
			width: 90%;
		}

		@include mq-min-max(xs, lg) {
			padding-left: 100px;
		}
	}

	&__content-boxes {
		width: auto;
		transition: 0.4s ease-in-out;
	}

	&__white-content-box {
		@media (min-width: 1920px) {
			width: 546px;
			transition: 0.4s ease-in-out;
		}

		width: 30%;
		max-width: 546px;
		min-height: 531px;
		margin-bottom: 30px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 20px;
		background-color: #f8f8f9;
		color: #393939;
		border-color: #393939;

		&:hover {
			background-color: $red;
			color: white;
		}

		@include mq-min-max(no, sm) {
			width: 100%;
		}

		@include mq-min-max(no, lg) {
			width: 47%;
			min-height: auto;
			max-height: auto;
		}
	}

	&__content-gap {
		@media (min-width: 1920px) {
			width: 2%;
		}
		width: 5%;
		@include mq-min-max(no, sm) {
			width: 0;
		}
		@include mq-min-max(no, lg) {
			width: 6%;
		}
	}

	&__image-box {
		margin: -20px;
		img {
			width: 100%;
			aspect-ratio: 19/12;
			object-fit: cover;
		}
	}

	&__info-box,
	&__info-box-with-image {
		h3 {
			font-weight: $weight-bold;
			@include rem(30);
			margin: 60px 0px 30px;
		}
		p {
			@include rem(18);
			margin-bottom: 60px;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
		}

		@include mq-min-max(no, xs) {
			h3 {
				@include rem(24);
				margin: 20px 0px;
			}
			p {
				@include rem(16);
			}
		}
	}

	&__info-box-with-image {
		@include mq-min-max(no, xs) {
			p {
				display: none;
			}
		}
	}

	&__footer-box {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
		border-top: 1px solid;
		margin-top: 30px;
		margin-bottom: 50px;

		p {
			@include rem(18);
			&:nth-child(1) {
				font-weight: $weight-bold;
			}
		}
	}

	&__load-more {
		width: 100%;
		text-align: center;
		margin: 50px 0px;
		a {
			text-decoration: none;
			font-weight: $weight-bold;
			@include rem(22);
			color: #393939;
		}
	}

	&__button {
		&.hidden {
			display: none;
		}
	}
}
