/* --------------------------------
   Form Labels
   -------------------------------- */

.form-label {
	@include rem(14);

	position: relative;
	z-index: 2;
	transition: transform 0.2s ease-in-out, color 0.2s ease-in-out, background-color 0.2s ease-in-out,
		font-size 0.2s ease-in-out;
	color: var(--label-color, #{$color-secondary});

	@include mq(md) {
		@include rem(20);
	}

	&:before {
		width: 0;
		transition: width 0.2s ease-in-out;
	}

	&--vertical {
		position: absolute;
		top: var(--label-vertical-top, 16px);
		left: var(--label-vertical-left, 0px);
		padding-right: 5px;
		transform: translateY(0px) scale(1);
		transform-origin: left;
		text-transform: uppercase;

		&:after {
			display: block;
			position: absolute;
			z-index: -1;
			top: -1px;
			right: 0;
			bottom: -1px;
			left: 0;
			width: 0;
			height: 5px;
			margin: auto;
			content: '';
			transition: width 0.2s ease-in-out;
			pointer-events: none;
			background-color: var(--input-background, #ffffff);
		}

		.form-input.is-active + &,
		.form-input:focus + &,
		.form-textarea.is-active + &,
		.form-textarea:focus + &,
		.form-select + &,
		.choices.choices--default + &,
		&.is-active {
			transform: var(--label-vertical-transform, translateY(-25px) scale(0.6));
			color: var(--label-color-active, var(--label-color));

			&:after {
				width: 100%;
			}
		}
	}

	&--transparent {
		--input-background: transparent;
	}

	&--small {
		--label-vertical-left: 12px;
		--label-vertical-top: 15px;
	}

	&--message {
		line-height: 1.2;
		position: relative;
		margin-left: 15px;
	}

	a {
		font-size: inherit;
		color: inherit;
	}
}

[data-label],
.label {
	// Error Message
	.error {
		margin-left: 4px;
		color: #e23636;

		@include mq(md) {
			@include rem(14);
		}

		.form--contact & {
			margin-left: 10px;
		}
	}

	.warning {
		display: inline;
	}
}
