/* ==========================================================================
About Values
   ========================================================================== */

.about-values {
	position: relative;
	display: flex;
	width: 100vw;
	height: 100%;
	justify-content: center;
	font-family: $font-haas;
	color: white;
	@include mq-min-max(no, md) {
		flex-direction: column;
	}
	&__background {
		position: absolute;
		z-index: -1;
		display: flex;
		width: 100%;
		height: 100%;
		@include mq-min-max(no, md) {
			position: static;
			height: 315px;
			flex-direction: column;
		}
	}

	&__background-right {
		width: 50%;
		@include mq-min-max(no, md) {
			display: none;
			width: 100%;
		}
	}

	&__background-left {
		width: 50%;
		@include mq-min-max(no, md) {
			width: 100%;
			height: 100%;
		}
	}

	&__content {
		display: flex;
		width: 100%;
		max-width: 1920px;
		padding: 200px 100px 257px;
		@include mq-min-max(no, md) {
			padding: 45px 35px 50px;
			flex-direction: column;
			background-color: $red;
		}
	}

	&__content-right {
		width: 50%;
		padding-right: 150px;
		padding-left: 170px;
		@include mq-min-max(md, lg) {
			padding-right: 10px;
		}
		@include mq-min-max(no, md) {
			width: 100%;
			padding-right: 0px;
			padding-left: 0px;
		}
	}

	&__content-left {
		width: 50%;
		@include mq-min-max(no, md) {
			display: none;
		}
	}

	&__content-title {
		p {
			margin-top: 0px;
			margin-bottom: 0px;
			padding-bottom: 64px;
			font-size: 74px;
			line-height: 1;
			font-weight: 700;

			@include mq-min-max(md, lg) {
				font-size: 50px;
			}

			@include mq-min-max(no, md) {
				padding-bottom: 26px;
				font-size: 30px;
			}
		}
	}

	&__content-intro {
		p {
			margin-bottom: 0px;
			font-size: 22px;
			line-height: 30px;
			font-weight: 700;

			@include mq-min-max(md, lg) {
				font-size: 18px;
			}

			@include mq-min-max(no, md) {
				font-size: 18px;
				line-height: 24px;
			}
		}
	}

	&__content-description {
		p {
			font-size: 22px;
			line-height: 30px;
			font-weight: 500;
			@include mq-min-max(no, md) {
				font-size: 18px;
				line-height: 24px;
			}
		}
	}

	&__background-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
