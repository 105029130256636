/* ==========================================================================
Related Courses
   ========================================================================== */

.related-courses {
	$root: &;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	font-family: $font-haas;
	color: $black-slate;
	background-color: #F8F8F9;

	@include mq-min-max(no, xs) {
		width: 100vw;
		min-height: auto;
		padding: 0;
	}

	&__inner {
		width: 100%;
		max-width: 1920px;
		padding: 70px 100px 140px;

		@include mq-down(lg) {
			padding: 24px 15px 9px;
		}
	}

	&__header {
		margin-bottom: 50px;
		padding-bottom: 25px;
		border-bottom: 1px solid $black-slate;

		@include mq-min-max(no, xs) {
			margin-bottom: 38px;
			padding-bottom: 10px;
		}
	}

	&__header-text {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 30px;
		line-height: 1;

		@include mq-min-max(no, xs) {
			font-size: 18px;
		}
	}

	&__cards {
		display: grid;
		gap: 40px;
		grid-template-columns: repeat(3, 1fr);
		

		@include mq-down(md) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include mq-down(xs) {
			grid-template-columns: 1fr;
		}
	}

	&__card {
		height: 335px;
		padding: 38px 32px 20px 25px;
		background-color: $white;
		transition: 0.25s ease;
		a{
			height: 100%;
			width: 100%;
			flex-direction: column;
			text-decoration: none;
			color: #393939;
			display: flex;
			justify-content: space-between;
			transition: 0.25s ease;
		}

		&:hover {
			a{
				color: white;
			}

			background-color: $red;

			#{$root} {
				&__card-footer {
					border-top: 1px solid white;
				}

				&__card-button {
					&:hover {
						background-color: $white;
						#{$root} {
							&__card-button-arrow {
								filter: var(--red-color);
							}
						}
					}
				}
			}
		}

		@include mq-min-max(no, xs) {
			height: auto;
			min-height: 335px;
			margin-bottom: 20px;
			padding-right: 30px;
			padding-bottom: 30px;
		}
	}

	&__card-footer {
		display: flex;
		padding-top: 21px;
		justify-content: flex-end;
		border-top: 1px solid $black-slate;
		transition: border 0.25s ease;
	}

	&__card-button {
		display: flex;
		width: 51px;
		height: 51px;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: $black-slate;
		transition: background-color 0.25s ease;

		@include mq-min-max(no, xs) {
			width: 39px;
			height: 39px;
		}
	}

	&__card-title {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 31px;
		font-size: 40px;
		line-height: 48px;

		@include mq-min-max(no, xs) {
			padding-bottom: 24px;
			font-size: 30px;
			line-height: 36px;
		}
	}

	&__card-duration-label {
		display: inline-block;
		margin-bottom: 0px;
		font-size: 20px;
		line-height: 1;
		font-weight: 700;

		@include mq-min-max(no, xs) {
			font-size: 18px;
		}
	}

	&__card-duration-text {
		display: inline-block;
		margin-bottom: 0px;
		margin-left: 7px;
		font-size: 20px;
		line-height: 1;
		font-weight: 500;

		@include mq-min-max(no, xs) {
			font-size: 18px;
		}
	}
}
