/* ==========================================================================
    Legals Content
   ========================================================================== */

.legals-content {
	position: relative;
	font-family: $font-haas;
	color: $black-slate;

	&__content {
		position: relative;
		z-index: 1;
		display: flex;
		width: 100vw;
		justify-content: center;
	}

	&__content-outer {
		width: 100%;
		max-width: 1820px;
		margin-left: 100px;
		padding-top: 114px;
		padding-right: 100px;
		padding-left: 0px;
		background-color: white;

		@include mq-min-max(no, xs) {
			margin-left: 0px;
			padding-top: 40px;
			padding-right: 15px;
			padding-left: 15px;
		}
	}

	&__content-inner {
		display: flex;
		padding: 0px 75px 150px;
		gap: 15px;

		@include mq-min-max(no, lg) {
			padding: 0px 15px 60px;
		}
		@include mq-min-max(no, sm) {
			flex-direction: column;
		}
	}

	&__left-column {
		@include mq(sm) {
			width: 32.7%;
		}
		h1 {
			@include mq(lg) {
				@include rem(40);
			}
			@include mq(md) {
				@include rem(30);
			}
			@include rem(25);
		}
	}

	&__right-column {
		padding-top: 15px;
		flex: 1;

		@include mq-min-max(no, xs) {
			padding-top: 30px;
		}

		h2 {
			@include rem(22);
		}
		p {
			@include rem(18);
		}
	}

	&__title {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 40px;
		line-height: 1;

		@include mq-min-max(no, xs) {
			font-size: 30px;
		}
	}

	&__body {
		padding-top: 0px;
		padding-right: 43px;
		font-size: 18px;
		line-height: 24px;
		font-weight: 500;

		@include mq-min-max(no, xs) {
			padding-right: 0px;
		}
	}

	&__subtitle {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 33px;
		font-size: 22px;
		line-height: 1;

		@include mq-min-max(no, xs) {
			padding-bottom: 25px;
			font-size: 18px;
		}
	}

	&__background {
		position: absolute;
		width: 50%;
		top: 0;
		height: 335px;
		background-color: $red;

		@include mq-min-max(no, xs) {
			display: none;
		}
	}
}
