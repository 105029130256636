/* ==========================================================================
Home Where
   ========================================================================== */

.home-where {
	width: 100vw;
	padding-bottom: 105px;
	background-color: #f8f8f9;
	font-family: $font-haas;
	color: $black-slate;
	font-weight: 700;
	display: flex;
	justify-content: center;
	min-height: 100vh;
	position: relative;

	@include mq-min-max(no, md2) {
		flex-direction: column;
		padding-bottom: 0px;
		background-color: #f8f8f9;
	}

	// Background
	&__background {
		position: absolute;
		right: 0px;
		z-index: 1;
		width: 50vw;
		max-height: 100vh;
		overflow: hidden;
		transition: 0.2s ease-in-out;

		&.loading {
			opacity: 0;
		}

		@include mq-min-max(no, md2) {
			position: static;
			width: 100vw;
			height: 600px;
			min-height: auto;
		}

		@include mq-min-max(no, xs) {
			height: 430px;
		}
	}

	&__background-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		aspect-ratio: 5/6;
	}

	&__title {
		margin-top: 0px;
		margin-bottom: 0px;
		border-bottom-color: $black-slate;
		font-size: 22px;
		line-height: 1;

		@include mq-min-max(no, md2) {
			width: 65%;
			padding-bottom: 10px;
			font-size: 18px;
			line-height: 1.12;
		}
	}

	&__slide-no {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 22px;
		line-height: 1;
		font-weight: 600;

		@include mq-min-max(no, md2) {
			font-size: 16px;
		}
	}

	// Header

	&__header {
		position: absolute;
		z-index: 2;
		width: 100vw;
		padding-top: 85px;
		display: flex;
		justify-content: center;
		top: 0;

		@include mq-min-max(no, md2) {
			padding: 27px 17px 1px;
		}
	}

	&__header-outer {
		width: 100%;
		max-width: 1920px;
		padding-right: 100px;
		padding-left: 100px;

		@include mq-min-max(no, md2) {
			padding-right: 0px;
			padding-left: 0px;
		}
	}

	&__header-inner {
		width: 100%;
		padding-bottom: 28px;
		border-bottom: 1px solid $black-slate;
		display: flex;
		justify-content: space-between;

		@include mq-min-max(no, md2) {
			padding-bottom: 0px;
		}
	}

	// Content

	&__content {
		position: relative;
		z-index: 2;
		width: 100%;
		max-width: 1920px;
		min-height: 100vh;
		padding: 135px 100px 55px;

		@include mq-min-max(no, md2) {
			min-height: auto;
			padding: 20px 17px 50px;
		}
	}

	&__content-inner {
		width: 50%;
		padding-top: 70px;
		padding-right: 170px;

		@include mq-min-max(no, md2) {
			width: 100%;
			padding-top: 0px;
			padding-right: 0px;
		}
	}

	// Slider Buttons

	&__slider-buttons {
		padding-bottom: 59px;
		display: flex;

		@include mq-min-max(no, md2) {
			padding-bottom: 25px;
		}
	}

	&__slider-button {
		display: flex;
		width: 51px;
		height: 51px;
		margin-right: 14px;
		border-radius: 50%;
		background-color: $light-grey;
		justify-content: center;
		align-items: center;
		transition: 0.2s ease-in-out;

		@include mq-min-max(no, md2) {
			width: 38px;
			height: 38px;
			margin-right: 9px;
		}

		&:hover {
			background-color: $black-slate;
		}
	}

	&__slider-button-image {
		width: 9px;

		@include mq-min-max(no, md2) {
			width: 7px;
		}

		&.left {
			margin-left: -5px;
			transform: rotateZ(180deg);
		}
	}

	&__content-title {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 53px;
		font-size: 74px;
		line-height: 1;

		@include mq-min-max(no, md2) {
			padding-bottom: 33px;
			font-size: 30px;
		}
	}

	&__content-intro {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 31px;
		font-size: 22px;

		@include mq-min-max(no, md2) {
			padding-bottom: 23px;
			font-size: 18px;
			line-height: 24px;
		}
	}

	&__content-description {
		padding-bottom: 63px;
		font-size: 22px;
		line-height: 30px;
		font-weight: 500;

		@include mq-min-max(no, md2) {
			padding-bottom: 49px;
			font-size: 17px;
			line-height: 24px;
			font-weight: 500;
		}
	}

	&__button {
		width: 230px;
		padding: 20px 30px;
		&:hover {
			padding-right: 20px;
		}

		@include mq-min-max(no, md2) {
			width: auto;
			min-width: 190px;
			padding: 11px 22px;
		}
	}

	&__button-text {
		margin-bottom: 0px;
		margin-top: 0px;
		color: white;
		font-size: 15px;
		line-height: 1;
	}

	&__content-buttons {
		display: flex;
		justify-content: space-between;

		@include mq-min-max(no, md2) {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__content-links {
		display: flex;
		align-items: center;

		@include mq-min-max(no, md2) {
			padding-top: 39px;
		}
	}

	&__content-link {
		color: $red;
		font-size: 22px;
		line-height: 1;
		font-weight: 700;
		text-decoration: none;
		cursor: pointer;

		@include mq-min-max(no, md2) {
			margin-left: 10px;
			font-size: 18px;
		}
	}

	&__content-link-label {
		margin: 0 13px 0 13px;
		color: $red;
		font-size: 22px;
		line-height: 1;
		font-weight: 500;
		text-decoration: none;

		@include mq-min-max(no, md2) {
			margin-left: 0px;
			font-size: 18px;
		}
	}

	&__wrapper {
		transition: 0.2s ease-in-out;
		&.loading {
			opacity: 0;
		}
	}
}
