/* ==========================================================================
	Pod Video
   ========================================================================== */

.pod-video {
	display: flex;
	width: 100vw;
	padding-bottom: 0px;
	justify-content: center;
	font-family: $font-haas;
	color: $black-slate;
	line-height: 1;

	&__red-margin {
		height: 255px;
		min-width: 100px;
		flex: 1;
		background-color: $red;

		@include mq-min-max(no, sm) {
			display: none;
		}
	}

	&__white-margin {
		min-width: 100px;
		flex: 1;

		@include mq-min-max(no, sm) {
			display: none;
		}
	}

	&__main {
		width: 100%;
		max-width: 1720px;
		padding: 99px 211px 60px 374px;

		@include mq-min-max(sm, xl) {
			padding: 15px;
		}

		@include mq-min-max(no, sm) {
			padding: 29px 15px 0px;
			background-color: transparent;
		}
	}

	&__main-inner {
		display: flex;
		flex-direction: column;

		@include mq-min-max(no, sm) {
			padding-top: 0px;
			padding-bottom: 0px;
			flex-direction: column;
		}
	}

	&__video {
		position: relative;
		width: 100%;
		aspect-ratio: 16 / 9;

		video {
			object-fit: cover;
			aspect-ratio: 16/9;
		}
	}

	&__video-control-play {
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 2;
		transform: translate(-50%, -50%);
		cursor: pointer;

		@include mq-min-max(no, sm) {
			width: 50px;
			min-width: 50px;
		}
	}

	&__video-player {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__video-overlay {
		position: absolute;
		top: 0px;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-color: $black;
		opacity: 0.2;
	}

	&__video-caption {
		display: flex;
		padding-top: 25px;
		padding-bottom: 10px;
		align-items: center;

		@include mq-min-max(no, sm) {
			padding-top: 16px;
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__video-caption-text {
		padding-top: 5px;
		padding-right: 24px;
		padding-bottom: 5px;
		border-right: 1px solid $black-slate;
		font-size: 18px;
		line-height: 24px;
		font-weight: 500;

		@include mq-min-max(no, sm) {
			margin-bottom: 22px;
			padding-bottom: 25px;
			border-bottom: 1px solid $black-slate;
			border-right-style: none;
		}
	}

	&__button {
		display: flex;
		min-width: 200px;
		margin-top: -10px;
		margin-left: 61px;
		padding: 19px 30px;
		justify-content: space-between;
		align-items: center;
		background-color: $red;
		cursor: pointer;
		transition: 0.4 ease-in-out;

		&:hover {
			padding-right: 20px;
		}

		a {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}

		@include mq-min-max(no, sm) {
			margin-top: 9px;
			margin-left: 0px;
		}
	}

	&__button-text {
		margin-top: 0px;
		margin-bottom: 0px;
		color: #fff;
		font-size: 16px;
		font-weight: 700;
		white-space: nowrap;
		text-decoration: none;
	}

	&__title {
		display: none;

		@include mq-min-max(no, sm) {
			display: block;
			margin-top: 0px;
			margin-bottom: 0px;
			padding-bottom: 21px;
			font-size: 30px;
			text-transform: capitalize;
		}
	}
}
