.article {
	width: 80%;
	padding: 0px 100px 0px 100px;
	max-width: 1920px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	p {
		line-height: 24px;
	}

	h1 {
		line-height: 90px;
	}

	h3 {
		line-height: 38px;
	}

	@include mq-min-max(no, xs) {
		max-width: none;
		padding: 0;

		h1 {
			line-height: 38px;
		}

		h3 {
			line-height: 28px;
		}
	}

	&__navigation {
		display: flex;
		flex-direction: row;
		@include mq-min-max(no, xs) {
			padding-left: 15px;
		}

		a {
			color: #393939;
			text-decoration: none;
			margin: 0px 10px 0px 0px;
			font-weight: $weight-light;
			@include rem(9);
		}
	}

	&__description {
		max-width: 1135px;
		@include mq-min-max(no, xs) {
			max-width: 375px;
			padding-left: 15px;
		}
		h1 {
			@include rem(74);
			font-weight: $weight-bold;
			@include mq-min-max(no, xs) {
				@include rem(30);
			}
			@include mq-min-max(xs, md) {
				@include rem(45);
			}
		}
	}

	&__content-top {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		img {
			width: 100%;
			aspect-ratio: 16/9;
			object-fit: cover;
		}

		@include mq-min-max(no, xl) {
			flex-direction: column;
		}
	}

	&__dates-and-social {
		display: flex;
		flex-direction: column;
		width: 175px;
		@include mq-min-max(md, xl) {
			width: 100%;
			justify-content: space-between;
			flex-direction: row;
		}
		@include mq-min-max(no, md) {
			width: 100%;
		}
	}

	&__dates,
	&__social {
		display: flex;
		flex-direction: column;
		@include mq-min-max(no, xl) {
			flex-direction: row;
			padding-left: 15px;
		}

		p {
			font-weight: $weight-bold;
			@include rem(18);
		}

		img {
			width: 100%;
			max-width: 1134px;
		}

		@include mq-min-max(no, xl) {
			p,
			a {
				margin: 0px 20px 10px 0px;
			}
		}
	}

	&__dates {
		p {
			margin: 5px 0px 5px 0px;
		}

		p:nth-child(2) {
			font-weight: $weight-light;
		}
	}

	&__social {
		a {
			color: #393939;
			text-decoration: underline;
			@include rem(18);
			margin-bottom: 5px;
		}
	}

	&__spacer {
		width: 175px;
	}

	&__main-content {
		margin: 100px auto 150px auto;
		max-width: 840px;
		@include mq-min-max(no, xs) {
			padding: 0px 15px 0px 15px;
		}
		p {
			@include rem(18);
			font-weight: $weight-light;
		}
		img {
			width: 100%;
		}
	}

	&__navigation-arrows {
		width: 100%;
		margin: 100px 0px 100px 0px;
		display: inline-flex;

		&--prev {
			margin-right: auto;
		}
		&--next {
			margin-left: auto;
		}
		// align-items: center;
		// display: flex;
		// flex-direction: row;
		// justify-content: space-between;

		a {
			font-weight: $weight-bold;
			@include rem(18);
			color: #393939;
			text-decoration: none;
			display: flex;
			flex-direction: row;

			&:nth-child(1) {
				img {
					margin-right: 20px;
				}
			}

			&:nth-child(2) {
				img {
					margin-left: 20px;
				}
			}
		}

		img {
			max-width: 30px;
		}
	}

	&__related-description {
		width: 100%;
		border-bottom: 2px solid #393939;

		h3 {
			@include rem(30);
			font-weight: $weight-bold;
			@include mq-min-max(no, xs) {
				@include rem(24);
			}
		}
	}

	&__single-article {
		width: 100%;
		border-bottom: 1px solid #393939;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 30px 0px 30px 0px;

		a {
			font-weight: $weight-bold;
			color: #393939;
			text-decoration: none;
			display: contents;
		}

		img {
			max-width: 252px;
			@include mq-min-max(no, sm) {
				display: none;
			}
		}
	}

	&__text-with-img,
	&__text-without-img {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 100px;
		h3 {
			@include rem(30);
			font-weight: $weight-bold;
			margin-bottom: 15px;
			margin-top: 0;

			@include mq-min-max(no, xs) {
				@include rem(24);
			}
		}
	}

	&__text-with-img {
		max-width: 546px;
	}
	&__text-without-img {
		max-width: 840px;
	}

	&__related-dates {
		display: flex;
		flex-direction: row;
		p {
			font-weight: $weight-bold;
			@include rem(18);
			margin: 0;
			margin-right: 15px;
		}
		p:nth-child(2) {
			font-weight: $weight-light;
		}
	}
}
