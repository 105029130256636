/* ==========================================================================
Course List
   ========================================================================== */

.courses-list {
	$root: &;

	width: 100vw;
	font-family: $font-haas;
	display: flex;
	justify-content: center;

	&__outer {
		display: flex;
		width: 100%;
		max-width: 1920px;
		padding: 43px 100px 110px;
		flex-direction: column;
		align-items: center;
		@include mq-min-max(no, xs) {
			padding: 17px 15px 50px;
		}
	}

	&__inner {
		display: grid;
		width: 100%;
		max-width: 1920px;
		gap: 44px;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: auto auto auto auto;

		@include mq-down(lg){
			grid-template-columns: repeat(3, 1fr);
		}

		@include mq-down(md){
			grid-template-columns: repeat(2, 1fr);
		}

		@include mq-down(sm){
			grid-template-columns: 1fr;
			padding-bottom: 50px;
			align-items: center;
			gap: 15px;
		}
	}

	&__course {
		width: 100%;
		position: relative;
		display: flex;
		height: 575px;
		flex-direction: column;
		justify-content: space-between;
		color: $black-slate;
		transition: color 0.25s ease;

		&:hover {
			color: white;

			#{$root} {
				&__course-background-overlay {
					opacity: 0.2;
					background-color: black;
				}

				&__course-cta {
					background-color: $red;
					border: none;
					padding: 0;

					&:hover {
						padding-left: 5px;
						background-color: $red-hover;
					}
				}
			}
		}
		@include mq-min-max(no, xs) {
			min-height: 480px;
		}
	}

	&__course-title {
		margin: 0;
		padding-bottom: 24px;
		font-size: 40px;
		min-height: 3em;
		@include mq-min-max(no, xs) {
			padding-bottom: 17px;
			font-size: 30px;
			line-height: 1;
		}
	}

	&__course-duration-label {
		display: inline-block;
		margin-right: 6px;
		margin-bottom: 0px;
		font-size: 20px;
		line-height: 1;
		font-weight: 700;
		@include mq-min-max(no, xs) {
			font-size: 17px;
		}
	}

	&__course-duration-text {
		display: inline-block;
		margin: 0;
		font-size: 20px;
		line-height: 1;
		font-weight: 500;
		@include mq-min-max(no, xs) {
			font-size: 17px;
		}
	}

	&__course-excerpt {
		li {
			&:nth-child(n + 5) {
				display: none;
			}
		}
		* {
			font-size: 18px;
			line-height: 24px;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			@include mq-min-max(no, xs) {
				font-size: 17px;
			}
		}
	}

	&__course-duration {
		padding-bottom: 36px;
		@include mq-min-max(no, xs) {
			padding-bottom: 29px;
		}
	}

	&__course-bottom {
		display: flex;
		padding-top: 21px;
		justify-content: space-between;
		align-items: flex-end;
		border-top: 1px solid $black-slate;
		@include mq-min-max(no, xs) {
			padding-top: 11px;
		}
	}

	&__course-logo {
		height: 80px;
		@include mq-min-max(no, xs) {
			height: 55px;
		}
	}

	&__course-cta {
		display: flex;
		width: 51px;
		height: 51px;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: $black-slate;
		transition: 0.2s ease;
		border: 0;

		@include mq-min-max(no, xs) {
			width: 38px;
			height: 38px;
		}
	}

	&__course-background-image {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__course-content {
		display: flex;
		height: 100%;
		width: 100%;
		flex-direction: column;
		justify-content: space-between;
	}

	&__course-inner {
		display: flex;
		height: 100%;
		padding: 61px 29px 45px;
		color: #393939;
		text-decoration: none;
		transition: 0.4 ease;

		&:hover{
			color: white;
		}

		@include mq-min-max(no, xs) {
			padding: 39px 25px 20px;
		}
	}

	&__course-background-overlay {
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-color: $white-smoke;
		opacity: 1;
		transition: opacity 0.2s ease, background-color 0.2s ease;
	}

	&__course-background {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
	}

	&__course-cta-arrow {
		@include mq-min-max(no, xs) {
			width: 8px;
		}
	}

	&__learn-more {
		display: none;
		color: $black-slate;
		font-size: 16px;
		line-height: 1;
		font-weight: 700;
		text-decoration: none;
		@include mq-min-max(no, xs) {
			display: block;
			text-decoration: none;
		}
	}
}
