/* ==========================================================================
Courses Hero
   ========================================================================== */

.courses-hero {
	position: relative;
	font-family: $font-haas;
	color: white;

	&__content {
		display: flex;
		width: 100vw;
		justify-content: center;
	}

	&__background {
		position: absolute;
		z-index: -1;
		overflow: hidden;
		width: 100vw;
		height: 100%;
		background-color: $red;
		@include mq-min-max(no, md) {
			display: none;
			background-color: transparent;
		}
	}

	&__left {
		width: 50%;
		@include mq-min-max(no, md) {
			width: 100%;
			padding-bottom: 29px;
		}
	}

	&__right {
		width: 50%;
		padding-left: 20px;
		@include mq-min-max(no, md) {
			width: 100%;
			padding-left: 0px;
		}
	}

	&__content-inner {
		width: 100%;
		max-width: 1920px;
		padding: 32px 100px 25px;
		justify-content: space-between;
		@include mq-min-max(no, md) {
			padding: 12px 15px 25px;
		}
	}

	&__title {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 74px;
		line-height: 1;
		@include mq-min-max(no, md) {
			font-family: $font-haas-tx;
			font-size: 30px;
		}
	}

	&__breadcrumb {
		a {
			color: white;
			text-decoration: none;
			font-size: 12px;
			line-height: 1;
			font-weight: 600;
		}
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 70px;
		@include mq-down(md) {
			padding-bottom: 10px;
		}
	}

	&__content-main {
		display: flex;
		justify-content: space-between;
		@include mq-min-max(no, md) {
			flex-direction: column;
		}
	}

	&__description-text {
		p {
			font-size: 20px;
			line-height: 30px;
			font-weight: 500;
			@include mq-min-max(no, md) {
				padding-top: 31px;
				padding-bottom: 45px;
				color: $black-slate;
				font-size: 17px;
				line-height: 24px;
			}
		}
	}

	&__download {
		display: flex;
		align-items: center;
		text-decoration: none;
		@include mq-min-max(no, md) {
			color: $black-slate;
		}
	}

	&__download-icon {
		@include mq-min-max(no, md) {
			width: 23px;
			filter: $image-black;
		}
	}

	&__description-intro {
		p {
			font-size: 20px;
			line-height: 30px;
			font-weight: 700;
			@include mq-min-max(no, md) {
				padding-bottom: 115px;
				font-size: 18px;
				line-height: 24px;
			}
		}
	}

	&__download-link {
		margin: 0 18px 0;
		color: white;
		font-size: 22px;
		line-height: 1;
		font-weight: 700;
		@include mq-min-max(no, md) {
			color: $black-slate;
			font-size: 17px;
		}
	}

	&__background-logo {
		position: absolute;
		left: -80vw;
		right: 0px;
		bottom: -29vh;
		width: 300%;
		height: 300%;
		max-width: none;
		object-fit: contain;
	}

	&__right-background {
		display: none;
		@include mq-min-max(no, md) {
			display: block;
			position: absolute;
			z-index: -1;
			overflow: hidden;
			width: 100vw;
			left: -15px;
			right: -15px;
			bottom: 0px;
			height: 150%;
			background-color: $red;
		}
	}

	&__right-background-logo {
		display: none;
		@include mq-min-max(no, md) {
			display: block;
			position: absolute;
			right: 0;
			width: 300%;
			height: 300%;
			max-width: none;
			object-fit: contain;
			left: -110vw;
			bottom: -26vh;
		}
	}

	&__right-top {
		@include mq-min-max(no, md) {
			position: relative;
		}
	}
}
