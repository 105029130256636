/* --------------------------------
   Form Selectbox
   -------------------------------- */

.form__select {
	@include rem(12);

	font-weight: $weight-regular;
	width: 100%;
	height: 50px;
	padding: 8px 5px 5px 16px;
	transition: border-color 0.3s ease-in-out;
	border-radius: 0;
	outline: none;
	background-color: $input-bg-color;
	// background-image: url('../images/site/icons/icon-select.svg');
	background-repeat: no-repeat;
	background-position: calc(100% - 1.7em) center;
	background-size: 0.8em;
	appearance: none;

	@include mq(md) {
		@include rem(12);

		height: 50px;
		padding: 8px 5px 5px 16px;
	}

	&--border {
		border: 1px solid $input-border-color;
	}
}
