.payment-confirmation {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	
	&__inner {
		width: 100%;
		max-width: 1200px;
		background-color: $white;
		@include mq(md) {
			padding: 70px 140px;
		}
		padding: 70px 0px;
		text-align: center;

		h2 {
			@include rem(30);
			font-weight: $weight-bold;
		}

		h3 {
			@include rem(20);
			font-weight: 500;
		}
	}
}
