.svq-main {
	width: 100%;
	overflow: hidden;

	&__inner {
		margin: 0px auto;
		width: 100%;
		max-width: 1920px;
		padding: 0px 100px;

		@include mq-min-max(no, lg2) {
			padding: 0px 15px;
		}

		//list view for all blocks

		&.is-list {
			.svq-main__svq-block {
				flex-direction: column;
			}
			.svq-main__svq-block {
				width: 100%;
				height: auto;
				flex-direction: column;
				flex-direction: row;
				margin-bottom: 0;

				&:nth-child(even) {
					background-color: white;
				}

				&:nth-child(odd) {
					border-bottom: 1px solid #707070;
					border-top: 1px solid #707070;
				}

				&:hover {
					background-color: $red;
				}
			}

			.svq-main__svq-block-bottom {
				width: 200px;
				border: none;
				padding: 0;

				@include mq-min-max(no, sm) {
					position: absolute;
					bottom: 40px;
					right: 25px;
					width: 100px;

					.svq-main__svq-block-bottom--logo {
						max-width: 36px;
						max-height: 43px;
					}

					.svq-main__svq-block-bottom--button {
						width: 35px;
						height: 35px;
					}
				}
			}

			.svq-main__svq-block-hover {
				display: none;
			}

			.svq-main__svq-block-top--header {
				margin: 0;
			}

			.svq-main__svq-block-bottom--button {
				&:hover {
					background-color: white;

					.svq-main__svq-block-bottom--span,
					.svq-main__svq-block-bottom--span::after {
						background-color: $red;
					}
				}
			}

			//support list view

			.svq-main__support-block {
				width: 100%;
				height: auto;
				margin-top: 50px;
				margin-bottom: 100px;
			}

			.svq-main__support-inner {
				flex-direction: row;
				flex-wrap: wrap;
			}

			.svq-main__support-content {
				display: flex;
				flex-direction: row;
				margin-bottom: 0;
			}

			.svq-main__support-background {
				top: -300px;
				left: auto;
				right: 63px;
			}

			.svq-main__support-content:first-child {
				width: auto;
				border: none;
				border-right: 1px solid;
			}

			.svq-main__main{
				display: block;
			}
		}
	}

	&__header {
		width: 100%;
		border-bottom: 1px solid #393939;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 50px;
		margin-bottom: 25px;

		@include mq-min-max(no, sm) {
			flex-direction: column;
		}

		p {
			@include rem(22);
			font-weight: $weight-bold;

			@include mq-min-max(no, sm) {
				@include rem(18);
			}
		}
	}

	&__main{
			display: grid;
			width: 100%;
			max-width: 1920px;
			gap: 44px;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: auto auto auto auto;
	
			@include mq-down(lg){
				grid-template-columns: repeat(3, 1fr);
			}
	
			@include mq-down(md){
				grid-template-columns: repeat(2, 1fr);
			}
	
			@include mq-down(sm){
				grid-template-columns: 1fr;
				padding-bottom: 50px;
				align-items: center;
				gap: 15px;
			}

	}
	// &__main {
	// 	display: flex;
	// 	flex-direction: row;
	// 	justify-content: space-between;
	// 	flex-wrap: wrap;
	// 	@include mq-min-max(no, lg) {
	// 		justify-content: center;
	// 	}
	// }

	//Single svq block

	&__svq-block {
		text-decoration: none;
		color: #393939;
		position: relative;
		height: 575px;
		width: 100%;
		background-color: #f8f8f9;
		padding: 25px 15px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		z-index: 1;
		margin-bottom: 20px;
		overflow: hidden;

		@include mq-min-max(no, sm) {
			height: 440px;
		}

		@include mq-min-max(no, xs) {
			width: auto;
		}

		&:hover {
			color: white;

			.svq-main__svq-block-hover {
				opacity: 1;
			}
		}
	}

	&__svq-block-hover {
		top: 0;
		left: 0;
		position: absolute;
		height: 576px;
		width: 545px;
		opacity: 0;
		transition: 0.3s;
		z-index: -1;
	}

	&__svq-block-top {
		display: flex;
		flex-direction: column;

		&--header {
			@include rem(40);
			font-weight: $weight-bold;

			@include mq-min-max(no, sm) {
				@include rem(30);
			}
		}
	}

	&__svq-block-top-description {
		display: flex;

		p {
			@include rem(20);
			margin-right: 5px;

			@include mq-min-max(no, sm) {
				@include rem(17);
			}

			&:first-child {
				font-weight: $weight-bold;
			}
		}
	}

	&__svq-block-bottom {
		width: 100%;
		border-top: 1px solid #393939;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-top: 20px;
		align-items: center;

		&--logo {
			max-width: 80px;
			max-height: 95px;
		}

		&--button {
			background-color: #393939;
			color: white;
			text-decoration: none;
			border-radius: 50%;
			width: 50px;
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;

			&:hover {
				background-color: $red;
			}
		}

		&--span {
			position: relative;
			height: 2px;
			width: 15px;
			border-radius: 10px;
			background-color: white;
			transform-origin: 100%;
			transform: rotate(45deg);

			&::after {
				content: '';
				top: 0;
				right: 0;
				height: 2px;
				width: 15px;
				border-radius: 10px;
				background-color: white;
				transform-origin: 100%;
				transform: rotate(-45deg);
				transform: rotate(-90deg);
				position: absolute;
			}
		}
	}

	//support info

	&__support-block {
		position: relative;
		height: 576px;
		width: 545px;
		padding: 25px 15px;
		display: flex;
		background-color: $red;
		flex-direction: column;
		justify-content: space-between;
		z-index: 1;
		margin-bottom: 20px;
		overflow: hidden;

		@include mq-min-max(no, md2) {
			display: none;
		}
	}

	&__support-background {
		top: 60px;
		left: 0;
		position: absolute;
		height: 576px;
		width: 545px;
		z-index: -1;
	}

	&__support-inner {
		display: flex;
		flex-direction: column;
	}

	&__support-content {
		color: white;
		@include rem(22);
		margin-bottom: 50px;

		&:first-child {
			font-weight: $weight-bold;
			width: 100%;
			border-bottom: 1px solid;
		}

		p {
			margin: 10px;
		}
	}
}
