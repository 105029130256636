/* ==========================================================================
Contact Form
   ========================================================================== */

:root {
	--white-color: grayscale(100%) brightness(0%) invert(100%);
}
.contact-form {
	z-index: 1;
	display: flex;
	width: 100%;
	justify-content: center;
	font-family: $font-haas;
	color: white;

	&__inner {
		width: 100%;

		@include mq(lg) {
			max-width: 1920px;
			padding: 27px 100px 0;
		}

		@include mq(sm) {
			padding: 27px 50px 0;
		}
	}

	&__breadcrumb {
		margin-bottom: 0px;
		padding-bottom: 66px;
		font-size: 12px;
	}

	&__main {
		display: flex;
		width: 100%;
		padding-bottom: 132px;
		gap: 15px;
		@include mq-min-max(no, sm) {
			padding: 0 15px;
			padding-bottom: 40px;
			flex-direction: column;
		}
	}

	&__left {
		width: 51.2%;
		@include mq-min-max(no, sm) {
			width: 100%;
			margin-top: -4px;
			padding-bottom: 37px;
		}
	}

	&__right {
		margin-bottom: 0px;
		padding: 56px 103px 56px 104px;
		flex: 1;
		background-color: white;
		@include mq-min-max(no, lg) {
			padding: 56px 15px;
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		font-size: 16px;
		line-height: 1;
		font-weight: 500;
	}

	&__fieldset {
		position: relative;
		padding-bottom: 12px;

		@include mq-min-max(no, sm) {
			width: 100%;
		}

		&:last-child {
			padding-bottom: 32px;

			@include mq-min-max(no, sm) {
				padding-bottom: 23px;
			}
		}

		input,
		textarea {
			width: 100%;
			border: 1px solid $black-slate;
			border-radius: 6px;
			background-color: transparent;

			&.error {
				border-color: $red;
				border-width: 2px;
			}

			&::placeholder {
				color: transparent;
			}

			&:focus + label,
			&:not(:placeholder-shown) + label {
				top: -7px;
				left: 19px;
				background: white;
				@include rem(12);
			}
		}

		input {
			height: 42px;
			margin-bottom: 0px;
			padding-left: 18px;
		}

		textarea {
			min-height: 110px;
			padding: 10px 20px;
			&::placeholder {
				color: transparent;
			}

			@include mq-min-max(no, sm) {
				min-height: 90px;
			}
		}

		label {
			pointer-events: none;
			position: absolute;
			@include rem(16);
			left: 1.4em;
			top: 0.8em;
			margin-bottom: 0px;
			color: $black-slate;
			font-weight: 500;
			transition: 0.4s;
			@include mq-min-max(no, sm) {
				left: 0.9em;
			}
		}
	}
	&__title {
		margin-top: -6px;
		margin-bottom: 0px;
		padding-bottom: 67px;

		line-height: 1;

		@include mq(md) {
			@include rem(74);
		}
		@include mq(sm) {
			@include rem(50);
		}

		@include mq-min-max(no, sm) {
			margin-top: 0;
			margin-bottom: 16px;
			padding-bottom: 21px;
			border-bottom: 1px solid white;
			font-family: $font-haas-tx;
			font-size: 30px;
		}
	}

	&__content {
		padding-bottom: 46px;
		line-height: 30px;

		@include mq(md) {
			font-size: 22px;
		}

		@include mq(sm) {
			font-size: 18px;
		}

		@include mq-min-max(no, sm) {
			padding-bottom: 59px;
			font-size: 18px;
			line-height: 24px;
		}
	}

	&__link {
		color: white;
	}

	&__share {
		display: flex;
		@include mq-min-max(no, sm) {
			padding-left: 18px;
		}
	}

	&__share-item {
		display: flex;
		width: 38px;
		height: 38px;
		margin-right: 10px;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: white;
		transition: background-color 0.25s ease;
		cursor: pointer;

		&:hover {
			background-color: $red-hover;

			.contact-form__share-logo {
				filter: var(--white-color);
			}
		}
	}

	&__share-logo {
		max-height: 18px;
		max-width: 18px;
	}

	&__select-field {
		width: 100%;
		height: 42px;
		margin-bottom: 0px;
		padding-left: 18px;
		border-style: solid;
		border-width: 1px;
		border-color: $black-slate;
		border-radius: 6px;
		background-color: transparent;
		color: $black-slate;
		font-size: 16px;

		&.error {
			border-color: $red;
			border-width: 2px;
		}
	}

	&__textarea-field {
		width: 100%;
		min-height: 110px;
		border-style: solid;
		border-width: 1px;
		border-color: $black-slate;
		border-radius: 6px;
		background-color: transparent;
		@include mq-min-max(no, sm) {
			min-height: 90px;
		}
	}

	&__submit {
		min-width: 195px;
		padding: 19px 30px;
		align-self: flex-end;

		&.success {
			display: none;
		}

		&:hover {
			padding-right: 20px;
		}
	}

	&__submit-text {
		padding: 0px;
		background-color: transparent;
		font-weight: 700;
		border: none;
		color: white;
	}

	&__error-message {
		color: $red;
		margin-bottom: 0;
	}

	&__submition-message {
		width: 100%;
		text-align: center;
		p {
			display: none;
			&.success {
				display: contents;
				color: green;
				font-weight: bold;
			}
		}
	}
}
