// Concatenates Animations
@mixin mergeAnimations($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {

        $animation: nth($animate, $i);
        $animations: #{$animations + $animation};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }

    animation: $animations;
}
