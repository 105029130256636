/* --------------------------------
   Sass imports
   -------------------------------- */

@charset 'UTF-8';

@use 'sass:math';

// Variables/Mixins
@import '_mixins/functions';
@import '_abstracts/animations';
@import '_abstracts/font-face';
@import '_abstracts/variables';

@import '_mixins/typography';
@import '_mixins/breakpoints';
@import '_mixins/container';
@import '_mixins/grid';
@import '_mixins/media-queries';
@import '_mixins/interactions';

// Plugins
@import '_vendor/normalize';

// Base
@import '_base/base';
@import '_base/button';
@import '_base/typography';
@import '_base/layout';
@import '_base/responsive-helper';

// Forms
@import '_forms/base';
// @import '_forms/buttons';
@import '_forms/checkbox';
@import '_forms/inputs';
@import '_forms/labels';
@import '_forms/loader';
@import '_forms/quantity';
@import '_forms/radio';
@import '_forms/select';
@import '_forms/textarea';

// Components
@import '_components/login';
@import '_components/pod-type-one';
@import '_components/pod-type-two';
@import '_components/pod-video';
@import '_components/tab-header';
@import '_components/standard-header';
@import '_components/enzuzoCookie';
@import '_components/holding';

//News page
@import '_components/news/news';
@import '_components/news/article';

//Core
@import '_components/core/topnav';
@import '_components/core/search';
@import '_components/core/footer';

// Home Page
@import '_components/landing/menu';
@import '_components/landing/home-hero';
@import '_components/landing/home-why';
@import '_components/landing/home-what';
@import '_components/landing/home-where';
@import '_components/landing/home-news';
@import '_components/landing/home-partners';

// Courses Page
@import '_components/courses/courses-hero';
@import '_components/courses/courses-list';

//Touch screen tests
@import '_components/courses/tst';


// Course Page
@import '_components/course/course-content';
@import '_components/course/upcoming-dates';
@import '_components/course/related-courses';
@import '_components/booking-modal';

// Contact Page
@import '_components/contact-page';
@import '_components/contact-form';
@import '_components/contact-locations';
@import '_components/enquiry-modal';

// Signin Page
@import '_components/signin';

// About Page
@import '_components/about/about-pledge';
@import '_components/about/about-mission';
@import '_components/about/about-values';

// Qualifications Page
@import '_components/qualifications-list';

// Legals Page
@import '_components/legals-content';

// Faqs Page
@import '_components/faqs-content';

// Careers Page and details page
@import '_components/careers-content';
@import '_components/career-details';

// Testimonials Page
@import '_components/testimonials-content';

// Locations Page
@import '_components/locations-content';

// Learning Portal
@import '_components/learning-portal-content';

// Checkout Pages
@import '_components/basket';
@import '_components/checkout';
@import '_components/payment';

//Funded svq page
@import '_components/svqs/svq-main';
@import '_components/svqs/svq-course-main';
@import '_components/svqs/svq-course-footer';
@import '_components/apprenticeship';
@import '_components/core/404';

// Layout
@import '_layout/header';
@import '_layout/dropdown';

// Pages
@import '_pages/home';

// Hacks
@import '_hacks/shame';

//buttons
@import '_components/buttons';
@import '_components/productsList.scss';
@import '_components/payment-confirmation';
