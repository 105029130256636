/* ==========================================================================
Topnav
   ========================================================================== */

.search {
	&-wrapper{
		height: fit-content;
		max-height: 0;
		position: fixed;
		top: 90px;
		left: 0;
		background-color: white;
		overflow: hidden;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 0px 20px;
		transition:  0.4s ease;
		&.searchOpen{
			z-index: 1000;
			height: fit-content;
			max-height: 90px;
			padding: 20px;
			transition:  0.4s ease;
			@include mq-min-max(no, md2) {
				max-height: 400px;
			}
		}
		@media (min-width: 1024px) {
			position: unset;
			height: 90px;
			max-height: 90px;
		}
		.search__outer-border{
			@include mq-min-max(no, md2) {
				display: flex;
				flex-direction: column;
				height: fit-content;

				.search__type, .search__category, .search__course{
					width: 100%;
					border-right: 0;
					border-bottom: 1px solid black;
				}

				.search__option{
					width: 100%;
					margin: 0;
				}

				.search__icon{
					position: unset;
					margin-left: auto;
				}
			}
		}
	}





	&__title {
		margin-top: 0px;
		margin-right: 37px;
		margin-bottom: 0px;
		font-size: 20px;
		color: $font-color;
		line-height: 1;

		@include mq-min-max(md2, lg) {
			margin-right: 5px;
			width: 105px;
		}

		@include mq-min-max(no, md2) {
			display: none;
		}
	}

	select.decorated option:hover {
		box-shadow: 0 0 10px 100px $red-hover inset;
		color: #fff;
	}

	select option {
		background: rgba(0, 0, 0, 0.3);
		color: #fff;
	}

	&__outer-border {
		position: relative;
		display: flex;
		width: 735px;
		height: 59px;
		border-style: solid;
		border-width: 1px;
		border-color: $font-color;
		border-radius: 30px;
		overflow: hidden;
		@include mq-min-max(no, md2) {
			display: none;
		}
	}

	&__type {
		position: relative;
		width: 29.5%;
		border-right: 1px solid var(--search-color);
	}

	&__category {
		position: relative;
		width: 33.5%;
		border-right: 1px solid var(--search-color);
	}

	&__option{
		&.disabled{
			cursor: not-allowed !important;
			pointer-events: none;
			opacity: 0.5;
			&:hover{
				cursor: not-allowed !important;
			}
		}
	}

	&__course {
		position: relative;
		flex: 1;
	}

	&__type-dropdown,
	&__course-dropdown,
	&__category-dropdown {
	
		-webkit-appearance: none;
		-moz-appearance: none;
		background: transparent;
		background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
		background-repeat: no-repeat;
		background-position-x: 95%;
		background-position-y: 50%;
		border: 1px solid #dfdfdf;
		border-radius: 2px;
		margin-right: 2rem;
		padding: 1rem;
		padding-right: 2rem;

		width: 100%;
		height: 100%;
		border: 0;
		color: $font-color;
		background-color: transparent;
		option {
			color: white;
			background-color: rgba(0, 0, 0, 0.7);

			&:hover {
				background-color: rgba($color: $red-hover, $alpha: 0.7);
			}
		}

		padding-top: 20px;
		padding-left: 15px;
	}

	&__type-dropdown {
		// ▼

		display: flex;
		flex-direction: column;
		border-top-left-radius: 29px;
		border-bottom-left-radius: 29px;
		overflow: hidden;
	}

	&__link {
		padding-top: 11px;
		padding-bottom: 11px;
		padding-left: 24px;
		color: #fff;
		font-size: 14px;
		line-height: 1;
		font-weight: 600;
		text-decoration: none;
		border-radius: 20px;
		margin: 5px 10px;
		color: var(--search-color);

		&.display {
			font-size: 16px;
		}
	}

	&__link:hover {
		background-color: $red;
	}

	&__category-dropdown {
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	&__course-dropdown {
		width: calc(100% - 60px);
		display: flex;
		flex-direction: column;
		overflow: hidden;
		border: none;

		&:focus {
			margin-right: 50px;
			border: none;
			outline: none;
		}

		&:focus-visible {
			border: none;
			outline: none;
		}

	}

	&__arrow {
		position: absolute;
		top: 46%;
		right: 32px;
		filter: var(--image-color);

		&-last {
			position: absolute;
			top: 46%;
			right: 86px;
			filter: var(--image-color);
		}
	}

	&__icon {
		position: absolute;
		right: 0px;
		width: 60px;
		height: 59px;
		padding: 10px;

	}

	&__icon-inner {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: $red;
		transition: 0.4s ease-in-out;

		&.disabled{
			cursor: not-allowed !important;
			pointer-events: none;
			opacity: 0.5;
			&:hover{
				cursor: not-allowed !important;
			}
		}

		&:hover {
			background-color: #ac1f19;
			border: 1px solid $red;
		}
	}
}
