/* --------------------------------
    Header
   -------------------------------- */

:root {
	--header-accent: #ffffff;
	--nav-color: #1c1c1c;
}

.header-container {
}

.header {
	align-items: center;
	// position: fixed;
	z-index: 100;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 90px;
	transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, height 0.3s ease-in-out,
		box-shadow 0.3s ease-in-out, opacity 0.3s ease-in-out;
	will-change: transform, background-color;

	@include mq(md) {
		height: 210px;
	}

	&.is-home {
		border-color: transparent;
		background-color: transparent;
	}

	&.is-offset {
		top: auto;
	}

	&.is-static {
		position: relative;
		top: auto;
		transform: initial;
		pointer-events: initial;
		opacity: 1;
	}

	&.is-changing {
		position: absolute;
	}

	&.is-hidden {
		transform: translateY(-100%);
	}

	&.is-static#{&}.is-hidden {
		transform: initial;
	}

	&.disable-transition {
		transition: none;
	}

	&.is-transparent {
		border-color: transparent;
		background: transparent;
	}

	&.disable-hide {
		position: fixed;
	}

	&__position {
		@include make-container($wide: false);

		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 90;
		height: 100%;
		// @include make-container-padding();

		@include mq(md) {
			@include make-container-padding(50px);
		}
	}

	&__inner {
		@include make-row();

		position: relative;
		height: inherit;
	}

	&__section {
		@include make-col();

		display: flex;

		@include mq(md) {
			@include make-col-size($size: 25%);
		}
		@include mq-down(md) {
			padding-right: 10px;
			padding-left: 10px;
		}

		&--start {
			flex-direction: column;
		}

		&--center {
			display: none;
			justify-content: center;
			align-items: center;
			height: 90px;

			@include mq(md) {
				@include make-col-size($size: 50%);

				display: flex;
				height: 130px;
			}
		}

		&--end {
			justify-content: flex-end;
			align-items: center;
			height: 90px;
			margin-left: auto;

			@include mq(md) {
				height: 130px;
				margin: 0;
			}
		}
	}
}

// Header Logos
.header-logo-list {
	display: flex;
	flex-direction: column;
	padding-top: 20px;

	@include mq(md) {
		padding-top: 40px;
	}
}

.header-logo {
	z-index: 20;
	margin-right: auto;
	transition: opacity 0.3s ease-in-out;

	.disable-transition & {
		transition: none;
	}

	& + & {
		margin-top: 6px;

		@include mq(md) {
			margin-top: 20px;
		}
	}

	&--sponsor {
		width: 71px;
		height: 16px;

		@include mq(md) {
			width: 156px;
			height: 33px;
		}
	}

	&--festival {
		width: 100px;
		height: 40px;

		@include mq(md) {
			width: 217px;
			height: 90px;
		}
	}

	&__link {
		display: flex;
		position: relative;
		text-decoration: none;
	}

	&__elem {
		transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;

		object-fit: contain;
		object-position: top left;

		img,
		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.header-festival-list {
	position: relative;
}

.header-festival {
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 0.3s ease-in-out;
	will-change: opacity;
	pointer-events: none;
	opacity: 0;

	&.is-active {
		pointer-events: initial;
		opacity: 1;
	}
}

.header-button-list {
	padding-top: 20px;

	@include mq(md) {
		padding-top: 40px;
	}
}

.header-button {
}

.header-title {
	@include rem(20);
	@include letter-spacing(20);

	display: none;
	margin: 0;
	text-align: center;
	text-transform: uppercase;
	color: $color-primary;

	@include mq(md) {
		display: inline-block;
	}

	&__date {
		display: block;
		margin-right: 15px;
		margin-bottom: 10px;
		margin-left: 15px;

		@include mq(lg) {
			display: inline;
			margin-bottom: 0;
		}
	}

	&__location {
		display: block;
		margin-right: 15px;
		margin-left: 15px;

		@include mq(lg) {
			display: inline;
		}
	}
}

// Header Nav
.header-nav {
	$root: &;

	display: flex;
	gap: 0 12px;

	@include mq(md) {
		gap: 0 25px;
	}

	&__trigger {
		$trigger: &;
		@include rem(13);
		@include letter-spacing(20);

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		text-align: center;
		text-transform: uppercase;
		color: $color-primary;
		border: 0;
		background: transparent;

		@include mq(md) {
			@include rem(20);
		}

		&-inner {
			display: flex;
			flex-direction: column;
			position: relative;
		}

		&-label {
			transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

			&.on-active {
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translate(-50%, 0);
				opacity: 0;
			}
		}

		&.is-active {
			#{$trigger}-label {
				transform: translate(0%, -100%);
				opacity: 0;

				&.on-active {
					transform: translate(-50%, -100%);
					opacity: 1;
				}
			}
		}
	}

	&__link {
		@include rem(13);
		@include letter-spacing(20);

		display: flex;
		align-items: center;
		margin: 0;
		cursor: pointer;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		color: $color-primary;

		@include mq(md) {
			@include rem(20);
		}
	}

	&__label {
		@include letter-spacing(20);
		@include rem(13);

		display: flex;
		align-items: center;
		margin: 0;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		color: $color-primary;

		@include mq(md) {
			@include rem(20);
		}

		&-text {
		}

		&-icon {
			&:after {
				display: flex;
				width: 30px;
				height: 15px;
				margin-left: 15px;
				content: '';
				background-image: url('/app/theme/images/site/icons/arrow-down.svg');
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
			}
		}
	}
}
