/* ==========================================================================
Footer
   ========================================================================== */

:root {
	--white-color: grayscale(100%) brightness(0%) invert(100%);
}

.footer {
	display: flex;
	width: 100vw;
	justify-content: center;
	background-color: $red;
	font-family: $font-haas;
	color: #fff;

	&__inner {
		display: flex;
		width: 100%;
		max-width: 1920px;
		flex-direction: column;
		@include responsive(padding, 78px 16px 55px, 120px 100px 60px);
	}

	&__top {
		display: flex;
		border-bottom: 1px solid white;
		@include responsive(padding-bottom, 57px, 65px);

		@include mq-min-max(no, sm) {
			flex-direction: column;
		}
	}

	&__right {
		display: flex;
		flex: 1;

		@include mq-min-max(no, xs) {
			flex-direction: column;
		}
	}

	&__bottom {
		display: flex;
		justify-content: space-between;
		@include responsive(padding-top, 27px, 30px);

		@include mq-min-max(no, xs) {
			flex-direction: column-reverse;
		}
	}

	&__form-inner {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		flex: 1;
		padding-right: 188px;

		@include responsive(padding-right, 30px, 188px, sm, wumbo);

		@include mq-min-max(no, sm) {
			padding-bottom: 32px;
			padding-right: 0px;
		}
	}

	&__form-input {
		margin-bottom: 0px;
		border: 1px solid white;
		border-radius: 31px;
		background-color: transparent;
		font-size: 16px;
		line-height: 1;
		font-weight: 500;
		width: 100%;
		outline: none;
		color: white;
		@include responsive(height, 55px, 62px);
		@include responsive(padding-left, 30px, 42px);
		transition: 0.4s ease-in-out;

		@include mq-min-max(no, xs) {
			width: 100%;
		}

		&:focus,
		&:outline {
			border-color: white;
		}
		&::placeholder {
			font-weight: bold;
			color: $black;
		}
	}

	&__form {
		display: flex;
		width: 51.2%;
		flex-direction: column;

		@include mq-min-max(no, sm) {
			width: 100%;
		}
	}

	&__form-heading {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 22px;
		font-size: 30px;
		line-height: 1;
		font-weight: 700;

		@include mq-min-max(no, xs) {
			padding-bottom: 24px;
			font-size: 28px;
		}
	}

	&__form-label {
		position: absolute;
		left: 40px;
		top: 0%;
		display: flex;
		height: 100%;
		margin-bottom: 0px;
		align-items: center;
		font-size: 16px;
		line-height: 1;
		font-weight: 500;
		pointer-events: none;
		transition: top 0.2s ease, font-size 0.2s ease, left 0.2s ease;

		@include mq-min-max(no, xs) {
			left: 30px;
		}
	}

	&__form-submit {
		position: absolute;
		display: inline;
		width: 47px;
		height: 47px;
		border-radius: 50%;
		background-color: white;
		border: none;
		cursor: pointer;
		transition: background-color 0.25s ease;

		&:hover {
			background-color: $red-hover;
		}

		&:hover ~ .footer__form-arrow {
			filter: var(--white-color);
			transform: translateX(5px);
		}

		@include mq-min-max(no, xs) {
			width: 41px;
			height: 41px;
		}
	}

	&__form-submit-wrapper {
		position: absolute;
		top: 0px;
		right: 0px;
		display: flex;
		width: 65px;
		height: 100%;
		justify-content: center;
		align-items: center;

		@include mq-min-max(no, xs) {
			width: 60px;
		}
	}

	&__form-link {
		color: #fff;
		text-decoration: underline;
	}

	&__links {
		display: flex;
		width: 35%;
		flex-direction: column;
		align-items: flex-start;

		@include mq-min-max(no, xs) {
			width: 100%;
			padding-bottom: 21px;
		}
	}

	&__menu {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 20px;
		font-size: 22px;
		line-height: 1;

		@include mq-min-max(no, xs) {
			margin-right: 28px;
			padding-bottom: 24px;
			font-size: 18px;
		}
	}

	&__submenu-link {
		display: inline-block;
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 0px;
		color: #fff;
		font-size: 16px;
		line-height: 1;
		font-weight: 500;
		text-decoration: none;
	}

	&__services {
		display: flex;
		width: 35%;
		flex-direction: column;
		align-items: flex-start;

		@include mq-min-max(no, xs) {
			width: 100%;
			padding-bottom: 13px;
		}
	}

	&__social-links {
		display: flex;
		margin-top: 4px;
	}

	&__social {
		display: flex;
		flex-direction: column;

		@include mq-min-max(no, xs) {
			flex-direction: row;
			align-items: center;
		}
	}

	&__social-link {
		width: 50%;
		max-height: 60%;
		position: relative;
		pointer-events: none;
		z-index: 2;
		transition: filter 0.25s ease;
	}

	&__copyright {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 0px;
		color: #fff;
		font-size: 16px;
		line-height: 1;
		font-weight: 300;
		text-decoration: none;
	}

	&__legals {
		@include mq-min-max(no, xs) {
			padding-bottom: 12px;
		}
	}

	&__legals-link {
		display: inline-block;
		margin-left: 20px;
		color: #fff;
		font-size: 16px;
		line-height: 1;
		font-weight: 300;
		text-decoration: none;
		position: relative;

		&::before{
			content: '';
			position: absolute;
			bottom: -3px;
			left: 0%;
			width: 0%;
			height: 1px;
			background-color: white;
			transition: 0.3s ease-in-out;
		}

		&:hover{
			&::before{
				width: 100%;
			}
		}

		@include mq-min-max(no, xs) {
			margin-right: 20px;
			margin-left: 0px;
		}
	}

	&__form-field {
		position: relative;
		display: flex;
		width: 100%;
		margin-bottom: 22px;
		padding-bottom: 0px;
		align-items: center;

		@include mq-min-max(no, xs) {
			margin-bottom: 37px;
		}
	}

	&__form-arrow {
		position: relative;
		z-index: 1;
		pointer-events: none;
		transition: transform 0.2s ease-in-out;
	}

	&__submenu-item {
		position: relative;
		display: flex;
		overflow: hidden;
		height: 20px;
		margin-bottom: 20px;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

		&:hover .footer__submenu-underline {
			transform: translateX(0%);
		}
	}

	&__submenu-underline {
		display: inline-block;
		width: 100%;
		height: 1px;
		background-color: white;
		transform: translateX(-105%);
		transition: transform 0.2s ease-in-out;
	}

	&__social-item {
		display: flex;
		width: 38px;
		height: 38px;
		margin-right: 10px;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: white;
		transition: background-color 0.25s ease;
		cursor: pointer;

		a {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&:hover {
			background-color: $red-hover;

			.footer__social-link {
				filter: var(--white-color);
			}
		}
	}

	&__menu-social {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 20px;
		font-size: 22px;
		line-height: 1;

		@include mq-min-max(no, xs) {
			margin-right: 28px;
			padding-bottom: 0px;
			font-size: 18px;
		}
	}

	&__form-checkbox-label {
		color: white;
		font-size: 16px;
		font-weight: 400;
		line-height: 1;
		display: grid;
		grid-template-columns: 20px auto;
		gap: 25px;
		cursor: pointer;
		align-items: center;
	}

	&__form-checkbox-checkmark {
		appearance: none;
		margin: 0;
		color: white;
		width: 31px;
		height: 31px;
		border: 1px solid currentColor;
		border-radius: 4px;
		display: grid;
		place-content: center;
		cursor: pointer;

		&:checked::before {
			transform: scale(1);
		}

		&:before {
			content: '';
			width: 23px;
			height: 23px;
			clip-path: polygon(17% 51%, 40% 76%, 81% 28%, 87% 33%, 40% 88%, 11% 56%);
			transform: scale(0);
			transform-origin: bottom left;
			transition: 120ms transform ease-in-out;
			box-shadow: inset 1em 1em white;
			background-color: CanvasText;
		}
	}
}

// Labels Transitions
.footer__form-input:focus ~ .footer__form-label,
.footer__form-input:not(:placeholder-shown).footer__form-input:not(:focus) ~ .footer__form-label,
.footer__form-input.message ~ .footer__form-label {
	font-size: 12px;
	top: -1.5em;
	left: 42px;

	@include mq-min-max(no, xs) {
		font-size: 12px;
		left: 31px;
	}
}

//shake animation

.shake {
	animation-name: shaker;
	animation-duration: 0.2s;
	transform-origin: 50% 50%;
	animation-timing-function: linear;
}

@keyframes shaker {
	0% {
		-webkit-transform: translate(4px, 0);
	}
	50% {
		-webkit-transform: translate(-4px, 0);
	}
	100% {
		-webkit-transform: translate(0px, 0);
	}
}
