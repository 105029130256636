/* ==========================================================================
    Locations Content
   ========================================================================== */

.locations-content {
	display: flex;
	width: 100vw;
	padding-bottom: 135px;
	justify-content: center;
	font-family: $font-haas;
	color: white;
	line-height: 1;

	@include mq-min-max(no, xs) {
		padding-bottom: 0px;
	}

	&__map-click {
		cursor: pointer;
	}

	&__red-margin {
		height: 255px;
		min-width: 100px;
		flex: 1;
		background-color: transparent;

		@include mq-min-max(no, md) {
			display: none;
		}
	}

	&__grey-margin {
		min-width: 100px;
		flex: 1;
		background-color: $dark-grey;
		margin-left: -1px;

		@include mq-min-max(no, md) {
			display: none;
		}
	}

	&__main {
		width: 100%;
		max-width: 1720px;
		padding-top: 63px;
		padding-bottom: 145px;
		padding-left: 15px;
		background-color: $dark-grey;

		@include mq(lg) {
			padding-left: 145px;
		}

		@include mq-min-max(no, xs) {
			padding: 0px;
		}
	}

	&____card-image-box {
		width: 100%;
		position: relative;
	}

	&__card-close-button {
		position: absolute;
		width: 50px;
		height: 50px;
		background-color: #272e3c;
		border-radius: 100%;
		top: 15px;
		right: 15px;

		@include mq(md) {
			display: none;
		}

		&:hover {
			background-color: $red;
		}

		&::before,
		&::after {
			position: absolute;
			content: '';
			width: 3px;
			height: 30px;
			background-color: white;
			top: 50%;
			left: 50%;
		}

		&::before {
			transform: translate(-50%, -50%) rotate(315deg);
		}

		&::after {
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}

	&__left {
		position: relative;
		padding-top: 9px;
		padding-left: 8px;
		flex: 1;

		@include mq-min-max(no, xs) {
			padding: 31px 14px 78px 15px;
			background-color: $dark-grey;
			max-height: 675px;
		}
	}

	&__right {
		position: relative;
		z-index: 1;
		width: 44%;
		padding-top: 64px;
		padding-right: 145px;

		@include mq-min-max(no, xs) {
			display: block;
			width: 100%;
			padding-top: 0px;
			padding-right: 0px;
			position: absolute;
			transform: translateX(100vw);
			transition: 0.3s transform ease;

			&.popup {
				transform: translateX(0);
			}
		}
	}

	&__main-inner {
		position: relative;

		@include mq(md) {
			display: flex;
			justify-content: space-between;
		}

		@include mq-min-max(no, xs) {
			padding-top: 0px;
			padding-bottom: 0px;
			flex-direction: column;
			width: 100vw;
			overflow: hidden;
			position: relative;
		}
	}

	&__map {
		svg {
			width: 100%;
			height: fit-content;
			padding: 30px;

			@include mq(xl) {
				width: 500px;
			}
			@include mq(md) {
				width: 400px;
			}
		}
	}

	&__city {
		cursor: pointer;

		.arrow {
			opacity: 0;
		}

		&.active {
			.arrow {
				opacity: 1;
			}
		}
	}

	&__card {
		opacity: 0;
		position: absolute;
		transition: 0.4s ease-in-out;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;

		@include mq(xl) {
			left: 915px;
			width: 545px;
		}
		@include mq(md) {
			left: 650px;
			width: 435px;
		}
		@include mq(xs) {
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 500px;
		}

		&.active {
			opacity: 1;
			z-index: 3;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			aspect-ratio: 16/9;
			@include mq(xl) {
				max-width: 546px;
			}
		}
	}

	&__card-content {
		padding: 24px 40px 35px;
		background-color: $light-black;
		margin-top: -3px;
		@include mq-min-max(no, xs) {
			padding: 22px 25px 148px;
		}
	}

	&__card-title {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 23px;
		font-size: 30px;
		line-height: 38px;

		@include mq-min-max(no, xs) {
			padding-bottom: 19px;
			font-size: 22px;
			line-height: 27px;
		}
	}

	&__card-text {
		margin: 0;
		font-size: 18px;
		line-height: 24px;
		font-weight: 500;
		width: 95%;

		@include mq-min-max(no, xs) {
			font-size: 14px;
		}
	}

	&__card-left {
		width: 54.5%;
	}

	&__card-right {
		flex: 1;
	}

	&__card-body {
		display: flex;
		margin-bottom: 0px;
		padding-bottom: 37px;
		border-bottom: 1px solid white;
		font-size: 18px;
		line-height: 24px;
		font-weight: 500;

		@include mq-min-max(no, xs) {
			padding-bottom: 56px;
		}
	}

	&__card-footer {
		padding-top: 25px;

		@include mq-min-max(no, xs) {
			padding-top: 18px;
		}
	}

	&__card-footer-label {
		display: inline-block;
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 18px;
		font-weight: 700;

		@include mq-min-max(no, xs) {
			font-size: 17px;
		}
	}

	&__card-footer-link {
		margin-left: 15px;
		color: #fff;
		font-size: 18px;
		font-weight: 500;

		@include mq-min-max(no, xs) {
			margin-left: 14px;
			font-size: 15px;
		}
	}

	&__map-arrow {
		position: absolute;
		top: 30%;
		right: -10%;

		@include mq-min-max(no, xs) {
			display: none;
		}
	}

	&__map-mobile {
		display: none;

		@include mq-min-max(no, xs) {
			display: block;
			width: auto;
			margin: 0 auto;
		}
	}
}
