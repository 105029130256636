/* ==========================================================================
Upcoming Dates
   ========================================================================== */

.upcoming-dates {
	display: flex;
	width: 100vw;
	justify-content: center;
	font-family: $font-haas;
	color: $black-slate;
	background-color: #F8F8F9;

	&__inner {
		width: 100%;
		max-width: 1920px;
		padding: 58px 100px 140px;

		@include mq-down(lg) {
			padding: 24px 15px 9px;
		}
	}

	&__header {
		display: flex;
		margin-bottom: 73px;
		padding-bottom: 11px;
		justify-content: space-between;
		align-items: flex-start;
		border-bottom: 1px solid $black-slate;
		@include mq-min-max(no, md2) {
			margin-bottom: 6px;
			padding-bottom: 15px;
			flex-direction: column;
		}
	}

	&__title {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 30px;
		line-height: 1;
		@include mq-min-max(no, md2) {
			padding-bottom: 18px;
			font-size: 18px;
		}
	}

	&__dropdown {
		display: flex;
		align-items: center;
		@include mq-min-max(no, md2) {
			width: 100%;
			display: flex;
			justify-content: flex-end;
		}
	}

	&__dropdown-label {
		margin: 0 23px 0 0;
		font-size: 16px;
		line-height: 1;
		font-weight: 600;
		@include mq-min-max(no, md2) {
			margin-right: 29px;
			font-size: 14px;
		}
	}

	&__dropdown-container {
		background-color: #F8F8F9;
		position: relative;
		overflow: hidden;
		width: 305px;
		height: 43px;
		padding-top: 4px;
		padding-left: 0px;
		border-style: solid;
		border-width: 1px;
		border-color: $black-slate;
		border-radius: 22px;
		@include mq-min-max(no, md2) {
			height: 33px;
		}
	}

	&__dropdown-item {
		margin: 0;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 26px;
		font-size: 16px;
		line-height: 1;
		font-weight: 600;
		@include mq-min-max(no, md2) {
			padding-top: 6px;
			padding-bottom: 6px;
			padding-left: 18px;
			font-size: 14px;
		}
	}

	&__dropdown-arrow {
		position: absolute;
		top: 50%;
		right: 29px;
		transform: translate(0px, -50%);
		@include mq-min-max(no, md2) {
			right: 22px;
		}
	}

	&__table {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
		@include mq-min-max(no, md2) {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
		}
	}

	&__table-header {
		margin: 0;
		padding-bottom: 15px;
		font-size: 22px;
		line-height: 1;
		@include mq-min-max(no, md2) {
			display: none;
		}

		&.venue {
			width: 20%;
		}

		&.duration {
			width: 13%;
		}

		&.date {
			width: 17.3%;
		}

		&.price {
			width: 13%;
		}

		&.spaces {
			width: 12.3%;
		}

		&.places {
			width: 13.3%;
			padding-left: 20px;
		}
	}

	&__table-row-text {
		margin: 0;
		font-size: 18px;
		line-height: 1;
		font-weight: 500;
		@include mq-min-max(no, md2) {
			margin-right: 32px;
			padding-bottom: 6px;
		}
	}

	&__table-row-places {
		display: flex;
		width: 50%;
		align-items: center;
		border-bottom: 1px solid $silver;
		@include mq-min-max(no, md2) {
			width: auto;
			border-bottom-style: none;
		}
	}

	&__counter-text {
		user-select: none;
		margin: 0;
		text-align: center;
		padding-right: 10px;
		width: 30px;
		padding-left: 10px;
		font-size: 18px;
		line-height: 1;
		@include mq-min-max(no, md2) {
			padding-right: 10px;
			padding-left: 10px;
		}
	}

	&__table-row-book {
		align-items: center;
		flex: 1;
		border-bottom: 1px solid $silver;
	}

	&__table-button {
		display: flex;
		margin-top: 10px;
		margin-bottom: 7px;
		padding: 19px 30px;
		justify-content: center;
		gap: 20px;
		align-items: center;
		background-color: $red;
		color: white;
		line-height: 1;
		text-decoration: none;
		@include mq-min-max(no, md2) {
			margin-top: 15px;
			margin-bottom: 19px;
			padding: 15px 67px;
		}
	}

	&__table-button-text {
		user-select: none;
		height: 18px;
		margin: 0;
		align-items: center;
		font-size: 16px;
		line-height: 1;
		font-weight: 700;
		@include mq-min-max(no, md2) {
			height: 20px;
			font-size: 20px;
		}
		@media (max-width: 1805px) {
			height: unset;
		}
	}

	&__table-button-arrow {
		user-select: none;
		@include mq-min-max(no, md2) {
			width: 43px;
		}
	}

	&__table-row-item {
		display: flex;
		align-items: center;
		border-bottom: 1px solid $silver;
		@include mq-min-max(no, md2) {
			flex-direction: column;
			align-items: flex-start;
			border-bottom-style: none;
		}

		&.venue {
			width: 20%;
			@include mq-min-max(no, md2) {
				display: block;
				width: 100%;
			}
		}

		&.duration {
			width: 13%;
			@include mq-min-max(no, md2) {
				width: auto;
			}
		}

		&.date {
			width: 17.3%;
			@include mq-min-max(no, md2) {
				width: auto;
			}
		}

		&.price {
			width: 13%;
			@include mq-min-max(no, md2) {
				width: auto;
			}
		}

		&.spaces {
			width: 48%;
			@include mq-min-max(no, md2) {
				width: auto;
				flex-direction: row;
				align-items: flex-start;
				padding-top: 3px;
			}
		}
	}

	&__table-row-label-mob {
		display: none;
		margin: 0;
		font-size: 18px;
		line-height: 1;
		font-weight: 700;
		@include mq-min-max(no, md2) {
			display: block;
			margin-right: 19px;
			padding-bottom: 5px;
		}
	}

	&__table-row {
		display: flex;
		width: 100%;
		justify-content: flex-start;

		&:first-of-type {
			border-bottom: 1px solid $black-slate;
			@include mq-min-max(no, md2) {
				border: none;
			}
		}

		@include mq-min-max(no, md2) {
			padding-bottom: 19px;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: flex-start;
			border-bottom-style: none;
		}
	}

	&__table-row-item-group {
		display: flex;
		width: 25.5%;
		@include mq-min-max(no, md2) {
			width: 100%;
			padding-top: 21px;
			justify-content: space-between;
		}
	}

	&__table-wrapper {
		width: 100%;
	}
}

.plus {
	stroke: red;
	&.grey {
		stroke: grey;
	}
}

.minus {
	stroke: grey;
	&.red {
		stroke: red;
	}
}
