/* ==========================================================================
Menu
   ========================================================================== */

.menu {
	position: fixed;
	display: flex;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	justify-content: flex-end;
	font-family: $font-haas;
	pointer-events: none;

	&.open {
		pointer-events: auto;
	}

	@include mq-min-max(no, xs) {
		width: 100vw;
		height: auto;
	}

	&__slider {
		display: flex;
		padding-right: 100px;
		flex-direction: column;
		justify-content: space-between;
		background-color: $red;
		transform: translateX(100%);
		transition: transform 0.3s ease;

		&.open {
			transform: translateX(0%);
		}
		@include mq-min-max(no, lg2) {
			padding-right: 10px;
		}

		@include mq-min-max(no, xs) {
			padding: 0 15px 88px;
			flex: 1;
		}
	}

	&__header {
		display: flex;
		width: 100%;
		height: 117px;
		justify-content: flex-end;
		align-items: center;
		border-bottom: 1px solid white;
		@include mq-min-max(no, xs) {
			height: 90px;
		}
	}

	&__header-icons {
		display: flex;
		justify-content: flex-end;

		@include mq-down(md){
			justify-content: space-between;
			width: 100%;
			padding-left: 50px;
			padding-right: 5px;
		}

		@include mq-down(xs){
			padding-left: 5px;
		}
	}

	&__header-icon {
		width: 29px;
		margin-left: 47px;
		cursor: pointer;

		@include mq-min-max(no, xs) {
			display: block;
			height: 29px;
			margin-left: 0px;
		}

		&.search {
			display: none;
			@include mq-min-max(no, xs) {
				display: block;
			}
		}

		&.account {
			@include mq-min-max(no, xs) {
				display: none;
			}
		}
	}

	&__header-icon-logo {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__what-links {
		padding: 10px 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;
		align-self: flex-end;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
		gap: 10px;
		@include mq-min-max(no, xs) {
			padding: 12px 0;
			gap: 12px;
		}

	}

	&__what-link {
		color: white;
		font-size: 40px;
		line-height: 1;
		font-weight: 700;
		text-align: right;
		text-decoration: none;
		white-space: nowrap;
		@include mq-min-max(no, lg2) {
			font-size: 20px;
		}
	}

	&__page-links {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		align-self: flex-end;
		font-size: 24px;
		line-height: 1;
		gap: 12px;
		padding: 10px 0;
		@include mq-min-max(no, lg2) {
			font-size: 16px;
		}
	}

	&__page-link {
		color: white;
		font-weight: 600;
		text-decoration: none;
		@include mq-min-max(no, xs) {
			color: white;
			font-size: 22px;
		}
	}

	&__main {
		align-self: flex-end;
		flex: 1;
		flex-grow: 1;
		padding-left: 185px;
		// max-height: 50%;
		overflow: auto;
		padding-right: 15px;
		margin: 15px 0;
		&::-webkit-scrollbar {
			width: 8px;
			background-color: transparent;
		}
	
		&::-webkit-scrollbar-thumb {
			background-color: rgba(0, 0, 0, 0.3);
			border-radius: 4px;
		}
		@include mq-min-max(no, xs) {
			width: 100%;
			padding-left: 0;
		}
	}

	&__footer {
		align-self: flex-end;
		margin-bottom: 5%;
	}

	&__share {
		display: flex;
		justify-content: flex-end;
	}

	&__share-item {
		display: flex;
		width: 38px;
		height: 38px;
		margin-left: 8px;
		padding: 9px;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: white;
	}

	&__share-item-logo {
		max-height: 18px;
	}

	&__contact {
		display: flex;
		padding-bottom: 45px;
		flex-direction: column;
		align-items: flex-end;
		@include mq-min-max(no, xs) {
			padding-bottom: 15px;
		}
	}

	&__contact-link {
		padding-bottom: 8px;
		color: white;
		font-size: 16px;
		line-height: 1;
		text-decoration: none;
	}

	&__overlay {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		background-color: $black-slate;
		opacity: 0;
		transition: opacity 0.3s ease;

		&.open {
			opacity: 0.7;
		}
		@include mq-min-max(no, xs) {
			display: none;
		}
	}
}
