.holding {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    &__main {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
    }

    &__logo {
        display: block;
        margin-bottom: 2rem;
        max-width: 300px;
    }

    &__copy {
        font-size: 16px;
        line-height: 1.5;
        max-width: 800px;
        text-align: center;

        @media (max-width: 800px) {
            max-width: 90%;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: $grey-extra-light;
        padding: 1rem;
        width: 100%;
    }

    &__copyright {
        // color: $grey-dark;
    }

    &__login-button {
        color: black;
        text-decoration: none;
        // font-weight: $bolold;
        margin-left: 0.5rem;

        &:hover {
            // color: $brand-red;
        }
    }
}
