/* --------------------------------
    Typography
   -------------------------------- */

@font-face {
    font-family: 'font-haas-tx';
    src: url('/theme/fonts/NHaasGroteskTXPro-75Bd.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'font-haas';
    src: url('/theme/fonts/NHaasGroteskDSPro-75Bd.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'font-haas';
    src: url('/theme/fonts/NHaasGroteskDSPro-65Md.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'font-haas';
    src: url('/theme/fonts/NHaasGroteskDSPro-55Rg.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'font-haas';
    src: url('/theme/fonts/NHaasGroteskDSPro-45Lt.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

.wysiwyg {
	word-break: break-word;

	a {
		color: inherit;
	}

	p {
		&:first-of-type {
			margin-top: 0;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	h1,
	h2,
	h3,
	h4 {
		font-weight: $weight-light;
		margin-top: 1.5em;
		margin-bottom: 0.75em;
		text-transform: uppercase;
	}

	&:first-of-type {
		margin-top: 0;
	}
}
