/* ==========================================================================
    Basket
   ========================================================================== */

.basket-row {

	&__button {
		background: 0;
		border: 0;
		cursor: pointer;
		padding: 0;

		img {
			display: block;
		}
	}
}

.basket-form {
	display: flex;
	align-items: center;

	&__remove-button {
		margin-left: .5rem;
	}
}

.basket {
	position: relative;
	justify-content: center;
	font-family: $font-haas;
	color: $black-slate;

	@include mq-min-max(no, xs) {
		width: 100vw;
		padding: 0;
		flex-direction: column;
	}

	&__empty-message {
		margin: 0 0 1rem;
	}

	&__background {
		position: absolute;
		overflow: hidden;
		width: 100vw;
		height: 100%;
		opacity: 1;

		@include mq-min-max(no, xs) {
			display: block;
		}
	}

	&__background-logo {
		position: absolute;
		right: -3px;
		bottom: -627px;
		z-index: 1;
		width: 80%;
		height: auto;
	}

	&__overlay {
		position: absolute;
		z-index: 0;
		width: 100%;
		height: 100%;
		background-color: $red;
	}

	&__main {
		display: flex;
		width: 100vw;
		justify-content: center;
	}

	&__main-inner {
		display: flex;
		width: 100%;
		max-width: 1720px;

		@include mq-min-max(no, xs) {
			flex-direction: column-reverse;
		}
	}

	&__red-margin {
		height: 475px;
		min-width: 100px;
		flex: 1;
		background-color: $red;

		@include mq-min-max(no, sm) {
			display: none;
		}
	}

	&__summary {
		width: 100%;
		padding: 79px 0px 160px 50px;
		background-color: transparent;

		@include mq-min-max(xs, sm) {
			padding: 79px 50px 160px 50px;
		}

		@include mq-min-max(no, xs) {
			padding: 40px 15px;
		}
	}

	&__white-margin {
		min-width: 100px;
		flex: 1;
		background-color: white;

		@include mq-min-max(no, sm) {
			display: none;
		}
	}

	&__summary-items {
		border-bottom: 1px solid $silver;

		@include mq-min-max(no, xs) {
			border-bottom-style: none;
		}
	}

	&__summary-totals {
		display: flex;
		padding-top: 0px;
		flex-direction: column;
		align-items: flex-end;
		background-color: $white-smoke;

		@include mq-min-max(no, xs) {
			padding-left: 0px;
		}
	}

	&__summary-subtotal {
		display: flex;
		padding-top: 18px;
		padding-bottom: 18px;
		justify-content: space-between;
		border-bottom: 1px solid $silver;

		@include mq-min-max(no, xs) {
			padding-top: 16px;
			padding-bottom: 16px;
		}
	}

	&__smmary-subtotal-label {
		margin-top: 0px;
		margin-bottom: 0px;
		line-height: 1;

		@include mq-min-max(no, xs) {
			font-size: 16px;
		}
	}

	&__summary-subtotal-amount {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-right: 16px;
		font-size: 18px;
		line-height: 1;
		font-weight: 500;

		@include mq-min-max(no, xs) {
			padding-right: 0px;
			font-size: 16px;
		}
	}

	&__summary-total {
		display: flex;
		margin-bottom: 0px;
		padding-top: 20px;
		padding-bottom: 24px;
		justify-content: space-between;
		border-top: 1px solid $black-slate;
		border-bottom: 1px solid $black-slate;

		@include mq-min-max(no, xs) {
			margin-bottom: 0px;
			padding-bottom: 20px;
		}
	}

	&__summary-total-label {
		margin-top: 0px;
		margin-bottom: 0px;
		line-height: 1;

		@include mq-min-max(no, xs) {
			font-size: 20px;
		}
	}

	&__summary-total-amount {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-right: 15px;
		line-height: 1;

		@include mq-min-max(no, xs) {
			padding-right: 0px;
			font-size: 20px;
		}
	}

	&__header {
		position: relative;
		top: 0px;
		display: flex;
		width: 100vw;
		justify-content: center;
	}

	&__header-inner {
		position: relative;
		z-index: 1;
		width: 100%;
		max-width: 1920px;
		padding: 33px 100px 37px;
		font-size: 9px;
		line-height: 1;
		font-weight: 600;

		@include mq-min-max(no, xs) {
			padding-right: 15px;
			padding-left: 15px;
		}
	}

	&__breadcrumb-link {
		display: inline-block;
		padding-bottom: 65px;
		color: #fff;
		text-decoration: none;
	}

	&__proceed-button {
		max-width: 220px;
		width: 100%;
		margin-bottom: 0px;
		padding: 18px 40px;

		justify-content: space-between;
		align-self: flex-end;

		&:hover {
			padding-right: 30px;
		}

		@include mq-min-max(no, xs) {
			height: 45px;
			max-width: none;
			padding-top: 0px;
			padding-bottom: 0px;
			justify-content: center;
			align-items: center;
			align-self: flex-start;
		}
	}

	&__proceed-butotn-text {
		margin-top: 0px;
		margin-bottom: 0px;
		color: #fff;
		font-size: 18px;
		line-height: 1;
		font-weight: 700;

		@include mq-min-max(no, xs) {
			margin-right: 40px;
			font-size: 16px;
		}
	}

	&__header-title {
		margin-top: 0px;
		margin-bottom: 0px;
		color: #fff;
		font-size: 74px;
		line-height: 1;

		@include mq-min-max(no, xs) {
			font-family: $font-haas-tx;
			font-size: 30px;
			font-weight: 700;
		}
	}

	&__summary-headers {
		display: flex;
		padding-bottom: 17px;
		justify-content: flex-start;
		border-bottom: 1px solid $black-slate;
		border-left-style: none;

		@include mq-min-max(no, xs) {
			display: none;
		}
	}

	&__summary-item-counters {
		display: flex;
		width: 80%;
		margin-right: 40px;
		align-items: center;

		@include mq-min-max(no, xs) {
			width: 100%;
			margin-right: 0px;
			order: 1;
		}

		&--button {
			cursor: pointer;
		}
	}

	&__summary-item-counter-value {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-right: 18px;
		padding-left: 18px;
		font-size: 18px;
		line-height: 1;
		font-weight: 500;
	}

	&__summary-item-remove {
		display: block;
		color: $black-slate;
		font-size: 18px;
		line-height: 1;
		font-weight: 500;
		text-decoration: none;

		@include mq-min-max(no, xs) {
			order: 1;
			font-size: 16px;
		}
	}

	&__summary-totals-frame {
		width: 23.8%;
		margin-bottom: 0px;
		padding-top: 19px;
		@include mq-min-max(xs, md) {
			width: 50%;
		}

		@include mq-min-max(no, xs) {
			width: 100%;
		}
	}

	&__summary-buttons {
		display: flex;
		padding-top: 68px;
		justify-content: flex-end;
		align-items: center;
		gap: 100px;

		@include mq-min-max(no, xs) {
			padding-top: 50px;
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__book-button {
		color: $black-slate;
		font-size: 18px;
		line-height: 1;
		font-weight: 500;
		text-decoration: none;

		@include mq-min-max(no, xs) {
			margin-right: 0px;
			margin-bottom: 12px;
			font-size: 16px;
		}
	}

	&__summary-item-label {
		display: none;
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 18px;
		line-height: 1;
		font-weight: 700;

		@include mq-min-max(no, xs) {
			display: block;
			font-size: 16px;
		}
	}

	table {
		border-collapse: collapse;
		width: 100%;

		tr {
			width: 100%;

			@include mq-min-max(no, md) {
				height: 50px;
			}

			&.mobile {
				padding-top: 20px;
				@include mq(xs) {
					display: none;
				}

				td {
					border-bottom: 1px solid black;
					
					&:first-child {
						text-align: left;
						width: 50%;
					}

					&:last-child {
						text-align: right;
						width: 50%;
					}
				}
			}
		}
		th {
			@include rem(20);
			font-weight: $weight-bold;
			&.title {
				text-align: left;
				width: 30%;
			}

			&.location {
				width: 10%;
				@include mq-min-max(no, md) {
					display: none;
				}
			}

			&.date {
				width: 15%;
				@include mq-min-max(no, lg) {
					display: none;
				}
			}
			&.duration {
				width: 15%;
				@include mq-min-max(no, lg) {
					display: none;
				}
			}
			&.qty {
				width: 20%;
				padding-right: 60px;
				@include mq-min-max(no, md) {
					padding-right: 85px;
				}
				@include mq-min-max(no, sm) {
					text-align: right;
				}
			}
			&.price {
				padding-right: 18px;
				text-align: right;
				width: 10%;

				@include mq-min-max(no, xs) {
					display: none;
				}
			}
		}

		td {

			&:not(.image) {
				padding: 8px 0;
			}

			@include mq(md) {
				border-bottom: 1px solid grey;
			}
			font-weight: $weight-regular;
			&.title {
				@include rem(18);
				font-weight: $weight-bold;
				width: 20%;
				@include mq-min-max(no, sm) {
					width: 30%;
				}
			}
			&.image {
				width: 10%;
				img {
					width: 100%;
					object-fit: cover;
					max-width: 136px;
				}
				@include mq-min-max(no, sm) {
					display: none;
				}
			}
			&.location {
				width: 10%;
				text-align: center;
				@include mq-min-max(no, md) {
					display: none;
				}
			}
			&.date {
				width: 15%;
				text-align: center;
				@include mq-min-max(no, lg) {
					display: none;
				}
			}
			&.duration {
				width: 15%;
				text-align: center;
				@include mq-min-max(no, lg) {
					display: none;
				}
			}
			&.qty {
				width: 20%;

				form {
					display: flex;
					align-items: center;
					justify-content: center;

					@include mq-min-max(no, xs) {
						justify-content: right;
					}

					a {
						text-decoration: none;
						color: black;
					}
				}

				input {
					background: none;
					border: none;
					width: 40px;
					text-align: center;
					padding: 0%;
				}
			}
			&.price {
				padding-right: 18px;
				width: 10%;
				text-align: right;

				@include mq-min-max(no, xs) {
					display: none;
				}
			}
		}
	}
}

.basket-session-summary {
	min-width: 300px;

	&__row {
		border-top: 1px solid black;
		display: flex;
		padding: 4px 0;
	}

	&__row-date {
		margin-right: 1rem;
		font-weight: $weight-bold;
	}

	&__row-times {
		margin-left: auto;
	}
}