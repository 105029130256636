/* ==========================================================================
Enquiry Modal
   ========================================================================== */

.enquiry-modal {
	display: flex;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	justify-content: flex-end;
	font-family: $font-haas;
	color: white;
	position: fixed;
	pointer-events: none;
	top: 0;
	z-index: 200;

	@include mq-min-max(no, md) {
		width: 100vw;
		min-height: auto;
		padding-right: 0px;
		padding-bottom: 0px;
		padding-left: 0px;
		background-color: transparent;
		font-family: $font-haas;
	}

	&.open {
		pointer-events: auto;
	}

	&__slider {
		display: flex;
		padding: 15px 100px 15px 0px;
		flex-direction: column;
		justify-content: space-between;
		background-color: $red;
		transform: translateX(100%);
		transition: transform 0.3s ease;
		overflow: scroll;

		@include mq-min-max(no, md) {
			padding-right: 15px;
			padding-bottom: 140px;
			padding-left: 15px;
			flex: 1;
			background-color: $red;
		}

		&.open {
			transform: translateX(0%);
		}
	}

	&__header {
		display: flex;
		width: 100%;
		height: 117px;
		justify-content: flex-end;
		align-items: center;
		border-bottom: 1px solid white;

		@include mq-min-max(no, md) {
			min-height: 90px;
		}
	}

	&__header-icons {
		display: flex;
		justify-content: flex-end;

		@include mq-min-max(no, md) {
			width: 100%;
			padding-right: 5px;
			padding-left: 5px;
			justify-content: flex-end;
			align-items: center;
		}
	}

	&__header-icon {
		width: 29px;
		margin-left: 47px;
		cursor: pointer;

		@include mq-min-max(no, md) {
			display: block;
			height: 29px;
			margin-left: 0px;
		}

		&.search {
			display: none;
		}
	}

	&__header-icon-logo {
		@include mq-min-max(no, md) {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__form-header {
		display: flex;
		width: 610px;
		padding-bottom: 12px;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		align-self: flex-end;
		border-bottom: 1px solid white;

		@include mq-min-max(no, md) {
			width: auto;
			padding-bottom: 0px;
		}
	}

	&__form-title {
		padding-bottom: 12px;
		color: white;
		font-size: 30px;
		line-height: 1;
		font-weight: 700;
		text-align: right;
		text-decoration: none;

		@include mq-min-max(no, md) {
			padding-bottom: 15px;
			font-family: $font-haas-tx;
			color: white;
			font-size: 24px;
			letter-spacing: -0.03em;
		}
	}

	&__page-links {
		display: flex;
		padding-top: 27px;
		padding-bottom: 90px;
		flex-direction: column;
		align-items: flex-end;
		align-self: flex-end;
		font-size: 24px;
		line-height: 1;

		@include mq-min-max(no, md) {
			padding-top: 18px;
			padding-bottom: 26px;
		}
	}

	&__main {
		margin-top: 76px;
		padding-left: 67px;
		align-self: flex-end;
		flex: 1;
		font-weight: 700;

		@include mq-min-max(no, md) {
			width: 100%;
			margin-top: 26px;
			padding-right: 0px;
			padding-left: 0px;
		}
	}

	&__overlay {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		background-color: $black-slate;
		opacity: 0;
		transition: opacity 0.3s ease;

		@include mq-min-max(no, md) {
			display: none;
		}

		&.open {
			opacity: 0.7;
		}
	}

	&__form-description {
		margin: 0;
		padding-top: 34px;
		padding-bottom: 52px;
		font-size: 22px;
		line-height: 30px;
		font-weight: 500;

		@include mq-min-max(no, md) {
			padding-top: 20px;
			padding-bottom: 37px;
			font-size: 18px;
			line-height: 30px;
		}
	}

	&__form-inner {
		display: flex;
		flex-direction: column;
	}

	&__form-fieldset {
		position: relative;
		padding-bottom: 24px;
	}

	&__form-input-label {
		position: absolute;
		left: 1.3em;
		top: 0.8em;
		font-size: 16px;
		line-height: 1;
		font-weight: 500;
		pointer-events: none;
	}

	&__form-input-field {
		width: 100%;
		height: 42px;
		margin-bottom: 0px;
		padding-left: 21px;
		border-style: solid;
		border-width: 1px;
		border-color: white;
		border-radius: 6px;
		background-color: transparent;
		color: white;
		font-size: 16px;
		line-height: 1;
		font-weight: 500;
		&::placeholder {
			color: $white;
		}
	}

	&__form-textarea-field {
		color: white;
		min-height: 145px;
		border-radius: 6px;
		border: 1px solid white;
		background-color: transparent;
		width: 100%;
		padding-left: 20px;
		padding-top: 12px;
		&::placeholder {
			color: $white;
		}

		@include mq-min-max(no, md) {
			min-height: 120px;
			margin-bottom: 0px;
		}
	}

	&__form-submit-button-text {
		padding: 0;
		background-color: transparent;
		color: $red;
		font-size: 16px;
		line-height: 1;
		border: none;
		font-weight: 700;
		pointer-events: none;
	}

	&__form-submit-button {
		display: flex;
		min-width: 195px;
		padding: 19px 41px;
		justify-content: space-between;
		align-items: center;
		align-self: flex-end;
		background-color: white;
		cursor: pointer;

		&.hidden {
			display: none;
		}
	}

	&__form-error,
	&__form-mesage {
		margin: 15px 0px 0px 15px;

		&.hidden {
			display: none;
		}
	}
}
