.ez-consent #cookie-notification {
    width: 35vw;
    padding: 60px 30px;
    gap: 30px;
    z-index: 3 !important;


    @media (max-width: 1800px) {
        width: 50vw !important;
    }

    @media (max-width: 1200px) {
        width: 70vw !important;
    }

    @media (max-width: 768px) {
        bottom: 20px;
        width: 95vw !important;
        padding: 30px 30px 20px 30px !important;
    }


    .enzuzo-notification-buttons {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 1rem;

        @media (max-width: 768px) {
            flex-direction: column-reverse !important;

        }

        #notificationManagerLink {
            padding: 0 !important;
            font-size: 16px;

            a {
                color: $red;
            }
        }

        .notification-button {
            font-size: 16px;

            font-weight: 700;
        }

    }

    .cookie-icon {
        width: 55px;
        height: 55px;

        @media (max-width: 768px) {
            width: 35px !important;
            height: 35px !important;
        }
    }

    .enzuzo-cookie-icon {
        top: 60px;
        left: 40px;

        @media (max-width: 768px) {
            top: 35px !important;
            left: 20px !important;
        }

        svg {
            width: 35px !important;
            height: 35px !important;

            @media (min-width: 768px) {
                width: 55px !important;
                height: 55px !important;
            }
        }
    }

    .enzuzo-notification-text {
        font-size: 16px;
        padding-left: 100px;
        padding-right: 30px;

        @media (max-width: 768px) {
            padding-left: 56px !important;
        }

        .enzuzo-notification-desc {

            @media (max-width: 768px) {
                font-size: 15px;
                line-height: 18px;
            }
        }
    }

    .enzuzo-cookieButtonWrap {
        flex-direction: row;
        width: fit-content;
        gap: 1rem;

        @media (max-width: 768px) {
            flex-direction: column-reverse !important;
            width: 100% !important;
            gap: 0.5rem !important;
        }

        button {
            padding: 14px 55px !important;
        }

        #cookie-notification__decline {
            margin-bottom: 0;
            background: transparent;
            border: 1px solid #fff !important;
            color: $red !important;

            &:hover {
                background: transparent !important;
                color: #fff !important;
            }
        }

        #cookie-notification__accept {
            margin-bottom: 0;
            border: 1px solid #FFFFFF !important;
            position: relative; // Necessary for absolute positioning of pseudo-element
            padding: 14px 80px 14px 30px !important;

            @media (max-width: 768px) {
                padding: 14px 55px 14px 55px !important;
            }

            &:hover {
                background: #eee !important;
                color: $red !important;

                &::after {
                    right: 10px;
                }
            }

            &::after {
                content: '';
                position: absolute;
                right: 25px;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                width: 34px;
                height: 14px;
                background-image: url(/theme/images/icons/arrow-red-long.svg);
                background-size: contain;
                background-repeat: no-repeat;
                transition: 0.3s;
            }
        }



        .enzuzo-close-banner-button {
            right: 17px;
            top: 17px;
            
            &:hover{

                &:before,&:after{
                    transition: all 0.3s;
                    background-color: $red-link !important;
                }
            }

            

        }
    }

    #notificationManagerLink {
        width: unset;

        @media (max-width: 768px) {
            margin: 20px auto 0px auto
        }
    }

    #notificationPolicyLink {
        a {
            color: #DD337c;
        }
    }
}

.notification-text a {
    font-weight: 700;
}


//Manage cookies modal
.ez-consent {
    .enzuzo-modal-wrapper {

        span {
            color: #fff !important;
        }

        .enzuzo-preferences-content {
            color: #fff !important;
            background-color: $red;
        }

        .enzuzo-cookie-modal {
            border-radius: 0px;
            box-shadow: unset;
            width: 800px;

            @media (max-width: 768px) {
                width: 100%;
                border-radius: 0px;
            }

            .enzuzo-modal-header {
                min-height: unset;
                padding: 50px 20px 20px 20px;

                @media (max-width: 768px) {
                    
                    border-radius: 0px !important;
                }

                .enzuzo-modal-title {
                    font-family: $font-haas;
                    text-transform: capitalize;
                    font-size: 24px;
                    font-weight: 700;
                    text-align: center;
                }

                .enzuzo-modal-desc {
                    margin: 1em 0 0 0;
                    text-align: center;

                    a {
                        color: $red;
                    }
                }
            }

            .enzuzo-cookie-preferences {
                background: $red;
                overflow: unset;
                overflow-y: scroll;
                padding-top: 30px;


                // @media (max-width: 768px) {
                //     padding: 30px 10px;
                // }

                .enzuzo-cookie-info-grey {
                    background-color: $red;
                }

                .enzuzo-card-header {
                    background: $red;
                    border-bottom: none;
                }

                .enzuzo-card-info {
                    align-items: center;
                    color: #fff;
                    background: $red;

                    svg {
                        path {
                            fill: #fff;
                        }
                    }

                    .enzuzo-cookie-toggle-slider {
                        align-self: center;

                        .enzuzo-hv-toggle-label {
                            background: #fff;

                            &::before {
                                background: #111;
                            }
                        }
                    }

                    .enzuzo-card-icon {
                        fill: #fff;
                    }

                    .enzuzo-hv-toggle:checked+label {
                        background: #fff !important;
                    }

                    .enzuzo-hv-toggle:checked+label::before {
                        background: $red !important;
                    }

                    .enzuzo-hv-toggle:disabled+label {
                        background: #fff !important;
                    }
                }

                .enzuzo-hv-preference__purpose {
                    color: #fff;
                    font-weight: 400 !important;
                }

                .enzuzo-hv-preference__description {
                    color: #fff;
                    font-weight: 400 !important;

                }
            }

            .enzuzo-modal-footer {
                background: $red;
                border-radius: 0;

                #cookie-preferences-buttons-wrapper {
                    .enzuzo-button-container {

                        @media (max-width: 600px) {
                            flex-direction: column-reverse !important;

                            gap: 0px;

                            .enzuzo-left-button-container {
                                margin-top: 8px;
                                width: 100%;
                                gap: 8px;
                                display: flex;
                                flex-direction: column;
                            }
                        }

                        .enzuzo-modal-button {
                            margin-bottom: 0;
                            border-radius: 0%;
                            font-weight: 700;
                            transition: all 0.3s;
                            font-size: 16px;
                        }
                    }
                }

                .enzuzo-modal-button-confirm {
                    background-color: $white;
                    border: 1px solid #FFFFFF !important;
                    position: relative;
                    padding: 14px 80px 14px 30px !important;
                    color: $red;

                    @media (max-width: 768px) {
                        padding: 14px 55px 14px 55px !important;
                    }

                    &:hover {
                        background: #eee !important;
                        color: $red !important;

                        &::after {
                            right: 10px;
                        }
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        right: 25px;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        transform: translateY(-50%);
                        width: 34px;
                        height: 14px;
                        background-image: url(/theme/images/icons/arrow-red-long.svg);
                        background-size: contain;
                        background-repeat: no-repeat;
                        transition: 0.3s;
                    }
                }

                .enzuzo-modal-button-accept {
                    background-color: $white;
                    border: 1px solid #FFFFFF !important;
                    position: relative;
                    padding: 14px 80px 14px 30px !important;
                    color: $red;

                    @media (max-width: 768px) {
                        padding: 14px 55px 14px 55px !important;
                    }

                    &:hover {
                        background: #eee !important;
                        color: $red !important;

                        &::after {
                            right: 10px;
                        }
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        right: 25px;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        transform: translateY(-50%);
                        width: 34px;
                        height: 14px;
                        background-image: url(/theme/images/icons/arrow-red-long.svg);
                        background-size: contain;
                        background-repeat: no-repeat;
                        transition: 0.3s;
                    }
                }

                .enzuzo-modal-button-reject {
                    background: transparent;
                    border: 1px solid #fff;
                    color: #fff;
                    text-transform: capitalize;
                    padding: 14px 55px 14px 55px !important;

                    &:hover {
                        background: #fff !important;
                        color: $red !important;
                    }
                }

                .enzuzo-poweredby-wrap {
                    color: $white;
                    background-color: $red;
                }
            }
        }

    }


    .enzuzo-modal-button-close {
        cursor: pointer;

        &:hover {
            svg {
                path {
                    fill: #000;
                }
            }
        }

        svg {
            path {
                transition: fill 0.3s;
            }
        }

        @media (max-width: 768px) {
            top: 20px !important;

            svg {
                path {
                    fill: #fff;
                    transition: fill 0.3s;

                    &:hover {
                        fill: #111;
                    }
                }
            }

        }
    }
}