/* ==========================================================================
Topnav
   ========================================================================== */

.topnav {
	--nav-color: black;
	--search-color: black;
	--search-background: white;
	--image-color: brightness(0) invert(0);
	transition: 0.5s ease-in-out;

	position: fixed;
	display: flex;
	width: 100vw;
	height: 117px;
	justify-content: center;
	font-family: $font-haas;
	background-color: var(--search-background);

	@include mq-min-max(no, lg2) {
		height: 90px;
	}

	&__inner {
		display: flex;
		width: 100%;
		max-width: 1920px;
		padding-right: 100px;
		padding-left: 100px;

		@include mq-min-max(no, lg2) {
			padding: 0 17px;
		}
	}

	&__center {
		display: flex;
		padding-left: 15px;
		align-items: center;
		@include mq-down(lg){
			width: 50%;
		}
	}
	&__right{
		margin-right: 100px;
		img{
			height: 33px;
		}
		@include mq-down(lg) {
			margin-right: 55px;
			
		}
	}
	&__left, &__right{
		width: fit-content;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}

	&__basket {
		filter: var(--image-color);
		cursor: pointer;
	}

	&__border {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $competence-grey;
	}

	&__logo {
		cursor: pointer;

		@include mq-min-max(no, lg2) {
			width: 49px;
		}
	}

	&__search {
		position: fixed;
		z-index: 1001;
		transform: translateY(-50%);
		height: 30px;
		top: calc( 90px / 2);
    	right: 115px;
		filter: var(--image-color);
		cursor: pointer;

		@media (min-width: 1024px) {
			display: none;
		}
	}

	&__hamburger{
		top: calc( 117px / 2);
		right: 100px;
		@include mq-min-max(no, lg2) {
			top: calc( 90px / 2);
			right: 10px;
		}
		position: fixed;
		z-index: 300;
		top: calc( 117px / 2);
		transform: translateY(-50%);
		width: 50px;
		height: 50px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		&-line{
			width: 100%;
			height: 2px;
			background-color: black;
			position: relative;
			pointer-events: none;
			transition: background-color 0.5s 0.2s ease;
			&::before, &::after{
				content: "";
				position: absolute;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: black;
				transition: top 0.25s 0.25s ease, rotate  0.25s ease, background-color 0.25s 0.25s ease;
			}

			&.open{
				background-color: transparent;
				transition: background-color 0s 0.2s ease;
				&::before, &::after{
					top: 0;
					bottom: 0;
					background-color: white;
					transition: top 0.25s  ease, rotate 0.25s 0.25s ease, background-color 0.25s 0.25s ease;
				}

				&::before{
					rotate: 45deg;
				}

				&::after{
					rotate: -45deg;
				}
			}


			&::before{
				top: -14px;
			}

			&::after{
				top: 14px;
			}
		}
		cursor: pointer;
	}

	
}
