/* --------------------------------
   Loader
   -------------------------------- */

:root {
	--loader: #{rgba($color-secondary, 0.2)};
	--loader-active: #{rgba($color-secondary, 1)};
	--loader-box: 30px;
	--loader-size: 0.5em;
}

.loader-wrapper {
	overflow: hidden;
	position: absolute;
	z-index: -10;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.loader,
.loader:after {
	width: 10em;
	height: 10em;
	border-radius: 50%;
}

.loader {
	font-size: 10px;
	position: absolute;
	z-index: -100;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: var(--loader-box);
	height: var(--loader-box);
	margin: auto;
	transition: opacity 0.3s ease-in-out, z-index 0.3s step-end;
	transform: translateZ(0);
	animation: load-spinner 1.1s infinite linear;
	text-indent: -9999em;
	opacity: 0;
	border-top: var(--loader-size) solid var(--loader);
	border-right: var(--loader-size) solid var(--loader);
	border-bottom: var(--loader-size) solid var(--loader);
	border-left: var(--loader-size) solid var(--loader-active);

	&.active {
		z-index: 100;
		transition: opacity 0.3s ease-in-out, z-index 0.3s step-start;
		opacity: 1;
	}
}

@keyframes load-spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
