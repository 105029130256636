.apprenticeship {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	&__inner {
		width: 100%;
		max-width: 1920px;

		@include mq(lg) {
			padding: 0 100px;
		}
		padding: 0 15px;
	}

	&__content {
		margin: 50px 0;
		width: 100%;
		display: flex;
		gap: 30px;
		@include mq(md) {
			&:nth-child(even) {
				flex-direction: row;
			}
			&:nth-child(odd) {
				flex-direction: row-reverse;
			}
		}
		flex-direction: column;
	}

	&__item {
		@include mq(md) {
			width: 50%;
		}
		width: 100%;

		&-text {
			border-top: 1px solid #393939;
		}

		&-image {
			img {
				object-fit: cover;
				width: 100%;
			}
		}

		h2 {
			font-size: clamp(30px, 3vw, 40px);
			font-weight: bold;
			line-height: clamp(36px, 3vw, 48px);
		}
		p,
		a,
		li {
			@include rem(18);
			line-height: 24px;
		}

		a {
			@include mq-down(xs) {
				@include rem(16);
			}
			color: #393939;
			&:active {
				color: #393939;
			}
		}
	}
}
