.booking-modal {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    justify-content: flex-end;
    font-family: $font-haas;
    color: #393939;
    position: fixed;
    pointer-events: none;
    top: 0;
    z-index: 305;

    @include mq-min-max(no, md) {
        width: 100vw;
        min-height: auto;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        background-color: transparent;
        font-family: $font-haas;
    }

    &.open {
        pointer-events: auto;
    }

    &__overlay {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-color: $black-slate;
        opacity: 0;
        transition: opacity 0.3s ease;

        @include mq-min-max(no, md) {
            display: none;
        }

        &.open {
            opacity: 0.7;
        }
    }

    &__slider {
        display: flex;
        padding-right: 100px;
        padding-left: 0px;
        flex-direction: column;
        background-color: $white;
        transform: translateX(100%);
        transition: transform 0.3s ease;
        width: 800px;

        @include mq-min-max(no, md) {
            padding-right: 15px;
            padding-bottom: 140px;
            padding-left: 15px;
            flex: 1;
            background-color: $white;
        }

        &.open {
            transform: translateX(0%);
            overflow-y: scroll;
        }
    }

    &__header {
        display: flex;
        width: 100%;
        height: 117px;
        justify-content: flex-end;
        align-items: center;
        border-bottom: 1px solid #393939;

        @include mq-min-max(no, md) {
            min-height: 90px;
        }
    }

    &__header-icons {
        display: flex;
        justify-content: flex-end;

        @include mq-min-max(no, md) {
            width: 100%;
            padding-right: 5px;
            padding-left: 5px;
            justify-content: flex-end;
            align-items: center;
        }
    }

    &__header-icon {
        width: 29px;
        margin-left: 47px;
        cursor: pointer;

        @include mq-min-max(no, md) {
            display: block;
            height: 29px;
            margin-left: 0px;
        }

        &.search {
            display: none;
        }
    }

    &__booking-content{
        width: 100%;
        padding-left: 100px;
        margin-top: 50px;
        margin-bottom: 50px;
        @include mq-min-max(no, md) {
            padding-left: 0px;
        }
    }

    &__booking-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #393939;
        align-items: center;

        h1{
            @include rem(30);
            font-weight: $weight-bold;
        }

        a{
            text-decoration: none;
            color: #393939;
        }
    }

    &__booking-item{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &:not(:last-child){
            border-bottom: 1px solid #C4C4C4;
        }
    }

    &__item-text{
        p{
            @include rem(18);
            font-weight: $weight-regular;

            &:first-child{
                font-weight: $weight-bold;
            }   
        }
    }

    a{
        color: #393939;
        text-decoration: none;
    }

    &__item-quantity{
        margin: 35px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        p{
            margin: 0px 10px;
        }

        span{
            cursor: pointer;
        }
        input{
            border: 0;
            outline: none;
            background: none;
            width: 20px;
            margin: 0px 10px;
        }
    }

    &__item-selector-plus{
        margin-right: 15px;
        margin-left: -8px;
    }

    &__pricing{
        width: 100%;
        background-color: #F8F8F9;
    }

    &__subtotal, &__vat, &__total{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @include rem(18);
    }

    &__subtotal{
        border-bottom: 1px solid #C4C4C4;
        border-top: 1px solid #C4C4C4;
    }

    &__total{
        @include rem(23);
        font-weight: $weight-bold;

        border-bottom: 1px solid #393939;
        border-top: 1px solid #393939;
    }

    &__booking-footer{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        @include mq-min-max(no, md) {
            justify-content: space-between;
        }
    }

    &__button {
        display: flex;
        padding: 23px 32px;
        justify-content: space-between;
        align-items: center;
        background-color: $red;
        color: white;
        text-decoration: none;
        cursor: pointer;
        margin-left: 30px;

        @include mq-min-max(no, xs) {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    &__button-text {
        color: white;
        font-size: 24px;
        line-height: 1;
        font-weight: 700;
        margin: 0;

        @include mq-min-max(no, md) {
            font-size: 20px;
        }
    }

    &__button-arrow {
        width: 43px;
        margin-left: 24px;

        @include mq-min-max(no, xs) {
            margin-left: 26px;
        }
    }
}