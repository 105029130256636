.course-footer {
	width: 100%;
	background-color: $competence-grey;

	&__inner{
		max-width: 1920px;
		padding: 50px 100px;
		margin: auto;
		@include mq-min-max(no, sm) {
			padding: 50px 15px;
		}
	}

	&__related {
		@include rem(30);
		font-weight: $weight-bold;
		width: 100%;
		border-bottom: 1px solid #707070;
	}

	&__content-inner {
		width: 100%;
		padding-top: 20px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;

		@include mq-min-max(no, lg) {
			justify-content: center;
		}
	}

	&__content {
		width: 546px;
		min-height: 335px;
		background-color: #fff;
		padding: 15px;
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;

		&:hover {
			background-color: $red;
			color: white;
		}

		&--title {
			@include rem(40);
			font-weight: $weight-bold;
		}

		&--duration {
			@include rem(20);
			display: flex;
		}

		&--duration-bold {
			@include rem(20);
			display: inline;
			font-weight: $weight-bold;
		}
	}

	&__content-footer {
		margin-top: auto;
		width: 100%;
		border-top: 1px solid #707070;
		padding-top: 15px;

		&--button {
			background-color: #393939;
			color: white;
			text-decoration: none;
			border-radius: 50%;
			width: 50px;
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: auto;

			@include mq-min-max(no, xs) {
				width: 30px;
				height: 30px;
			}

			&:hover {
				background-color: white;
				.course-footer__content-footer--span,
				.course-footer__content-footer--span::after {
					background-color: $red;
				}
			}
		}

		&--span {
			position: relative;
			height: 2px;
			width: 15px;
			border-radius: 10px;
			background-color: white;
			transform-origin: 100%;
			transform: rotate(45deg);

			@include mq-min-max(no, xs) {
				width: 7px;
			}

			&::after {
				content: '';
				top: 0;
				right: 0;
				height: 2px;
				width: 15px;
				border-radius: 10px;
				background-color: white;
				transform-origin: 100%;
				transform: rotate(-45deg);
				transform: rotate(-90deg);
				position: absolute;

				@include mq-min-max(no, xs) {
					width: 7px;
				}
			}
		}
	}
}
