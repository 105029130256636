/* ==========================================================================
Contact Page
   ========================================================================== */

.contact-page {
    position: relative;
    width: 100vw;
    overflow: hidden;

    &__background {
        position: absolute;
        top: 0px;
        z-index: -1;
        width: 100vw;
        height: 100%;
        background-color: $red;
        
        @include mq-min-max(no, xs) {
            display: block;
            overflow: hidden;
        }
    }

    &__background-image {
        position: absolute;
        left: -2vw;
        bottom: 21.5vh;
        width: 115%;
        max-width: none;
        @include mq-min-max(no, xs) {
            left: -57.5vw;
            top: -19.2vh;
            bottom: auto;
            width: 220%;
        }
    }
}
