/* --------------------------------
   Buttons
   -------------------------------- */

:root {
	--button-background: transparent;
	--button-background-hover: #{$color-secondary};
	--button-border: #{$color-secondary};
	--button-border-hover: #{$color-secondary};
	--button-color: #{$color-secondary};
	--button-color-hover: #{$color-primary};
	--button-width: auto;
	--input-size: 40px;
}

.button {
	@include rem(13);
	@include letter-spacing(20);

	display: inline-flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
	width: var(--button-width);
	min-width: 100px;
	min-height: var(--input-size);
	padding-top: 1px;
	padding-right: 15px;
	padding-bottom: 3px;
	padding-left: 15px;
	cursor: pointer;
	transition: z-index 0.2s step-start, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out,
	border-color 0.2s ease-in-out, color 0.2s ease-in-out;
	text-align: center;
	white-space: nowrap;
	text-decoration: none;
	text-transform: uppercase;
	word-break: break-word;
	color: var(--button-color);
	border: 1px solid var(--button-border);
	border-radius: 100px;
	background-color: var(--button-background);

	@include mq(md) {
		@include rem(20);

		min-width: 160px;
		padding-right: 30px;
		padding-left: 30px;

		--input-size: 50px;
	}

	&:hover {
		--button-background: var(--button-background-hover);
		--button-border: var(--button-border-hover);
		--button-color: var(--button-color-hover);
	}

	&--primary {
	}

	&--alt {
		--button-background-hover: #{$color-primary};
		// --button-background: #{};
		// --button-background-hover: #{};
		--button-border: #{$color-primary};
		--button-border-hover: #{$color-primary};
		--button-border-hover: #{$color-primary};
		--button-color: #{$color-primary};
		--button-color-hover: #{$color-primary};
		--button-color-hover: #{$color-secondary};

		&.is-active {
			--button-background: #{$color-primary};
			--button-border-hover: #{$color-primary};
			--button-color: #{$color-secondary};
		}
	}

	&--italic {
		font-style: italic;
	}

	&--expand {
		flex-grow: 1;
	}

	&--no-border {
		padding: 0;
		border: 0;
	}

	&[disabled],
	&--disabled,
	&.is-disabled {
		cursor: not-allowed;
	}

	&.no-hover {
		pointer-events: none;
	}
}
