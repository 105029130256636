/* --------------------------------
   Form Textarea
   -------------------------------- */

.form-textarea {
	@include rem(12);

	font-weight: $weight-regular;
	width: 100%;
	height: 185px;
	min-height: 60px;
	padding: 16px 0 16px 0;
	resize: vertical;
	transition: border-color 0.3s ease-in-out;
	color: var(--input-color, #000000);
	border-color: var(--input-border-color, #909493);
	outline: none;
	background-color: var(--input-background);
	appearance: none;

	@include mq(md) {
		@include rem(16);

		padding: 16px 0 16px 0;
	}

	&--border {
		border-width: var(--input-border-width, 1px);
	}

	&--border-bottom {
		border-width: 0 0 var(--input-border-width, 1px) 0;
	}

	&.is-active,
	&[readonly],
	&:focus {
		--input-border-color: var(--input-border-color-active, var(--input-border-color));
	}
}
