/* ==========================================================================
Signin
   ========================================================================== */

.signin {
	position: relative;
	display: flex;
	width: 100vw;
	justify-content: center;
	font-family: $font-haas;

	&__inner {
		position: relative;
		z-index: 1;
		width: 100%;
		max-width: 1920px;

		@include mq(xl) {
			padding: 50px 245px 158px;
		}
		@include mq(md) {
			padding: 50px 40px 158px;
		}
		color: $black-slate;
		font-weight: 500;
		@include mq-min-max(no, md) {
			padding: 43px 15px 45px;
		}
	}

	&__frame {
		display: flex;
		padding: 52px 56px 60px 50px;
		background-color: hsla(0, 0%, 100%, 0.8);
		@include mq-min-max(no, md) {
			padding: 45px 20px 20px;
			flex-direction: column;
		}
	}

	&__left {
		width: 50%;
		padding-right: 59px;
		@include mq-min-max(no, md) {
			width: 100%;
			padding-right: 0px;
		}
	}

	&__login-inner {
		display: flex;
		flex-direction: column;
		@include mq-min-max(no, md) {
			padding-bottom: 34px;
		}
	}

	&__fieldset {
		position: relative;

		&.large {
			padding-bottom: 10px;
		}

		input {
			height: 42px;
			margin-bottom: 12px;
			border-style: solid;
			border-width: 1px;
			border-color: $black-slate;
			border-radius: 6px;
			background-color: transparent;
			width: 100%;
			padding-left: 20px;

			&.postcode {
				padding-left: 20px;
				height: 42px;
				margin: 0;
				width: 100%;
				border-style: solid;
				border-width: 1px;
				border-color: $black-slate;
				border-radius: 6px;
				background-color: transparent;
			}

			&.error {
				border-width: 2px;
				border-color: $red;
			}

			&::placeholder {
				color: transparent;
			}

			&:focus + label,
			&:not(:placeholder-shown) + label {
				top: -7px;
				z-index: 1;
				font-size: 13px;
				background-color: white;
				padding: 2px;
				border-radius: 4px;
				margin: 0;
				width: fit-content;
			}
		}

		label {
			position: absolute;
			left: 20px;
			top: 11px;
			font-size: 16px;
			font-weight: 500;
			pointer-events: none;
			transition: 0.2s ease-in-out;
			@include mq-min-max(no, md) {
				margin-left: 10px;
			}
			@include mq-min-max(no, xs) {
				font-size: 12px;
			}
		}
	}

	&__form-title {
		margin-top: 0px;
		margin-bottom: 27px;
		padding-bottom: 25px;
		border-bottom: 1px solid $black-slate;
		font-size: 30px;
		line-height: 1;
		@include mq-min-max(no, md) {
			margin-bottom: 15px;
			padding-bottom: 22px;
			font-family: $font-haas-tx;
		}
	}

	&__form-label {
		margin-bottom: 0px;
		padding-bottom: 30px;
		font-size: 18px;
		line-height: 24px;
		@include mq-min-max(no, md) {
			padding-bottom: 24px;
		}
	}

	&__checkbox-fieldset {
		margin-top: 18px;
		margin-bottom: 0px;
		padding-right: 42px;
		padding-bottom: 19px;
		align-self: flex-end;
		@include mq-min-max(no, md) {
			align-self: flex-start;
		}
	}

	&__checkbox-label {
		font-size: 16px;
	}

	&__checkbox-field {
		width: 18px;
		height: 18px;
		margin-top: 0px;
		margin-left: -29px;
		border-color: $black-slate;
		border-radius: 5px;
		@include mq-min-max(no, md) {
			margin-right: 11px;
			margin-left: -20px;
		}
	}

	&__submit-login-text {
		padding: 0;
		background-color: transparent;
		font-size: 16px;
		font-weight: 700;
		border: none;
		color: white;
	}

	&__submit-login {
		cursor: pointer;
		display: flex;
		min-width: 195px;
		padding: 0px 35px;
		justify-content: space-between;
		align-items: center;
		align-self: flex-end;
		background-color: $red;
		color: white;
		font-weight: 700;
		position: relative;
		border: none;
		position: relative;

		&:hover {
			padding-right: 25px;
		}

		&:after {
			position: absolute;
			content: '';
			background-image: url('https://uploads-ssl.webflow.com/6254185a5fed6af135d0cf8b/627a3f33a798e67b4e476115_button-arrow.svg');
			right: 25px;
			z-index: 1;
			width: 28px;
			height: 13px;
		}

		@include mq-min-max(no, md) {
			margin-bottom: 24px;
			align-self: flex-start;
		}
	}

	&__submit-login-arrow {
		margin-left: 25px;
	}

	&__submit-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include mq-min-max(no, md) {
			flex-direction: column-reverse;
			align-items: flex-start;
		}
	}

	&__link {
		color: $black-slate;
		font-size: 16px;
		line-height: 1;
		text-decoration: none;
	}

	&__right {
		width: 50%;
		padding-right: 0px;
		padding-left: 57px;
		border-left: 1px solid hsla(0, 0%, 43.9%, 0.3);
		@include mq-min-max(no, md) {
			width: 100%;
			padding-left: 0px;
			border-left-style: none;
		}
	}

	&__select-field {
		position: relative;
		height: 42px;
		margin-bottom: 0px;
		padding-left: 17px;
		border-style: solid;
		border-width: 1px;
		border-color: $black-slate;
		border-radius: 6px;
		background-color: transparent;
		font-size: 16px;
		line-height: 1;
		width: 100%;

		&.address {
			margin-bottom: 12px;
		}

		&.hidden {
			display: none;
		}

		&.error {
			border-width: 2px;
			border-color: $red;
			margin-bottom: 10px;
		}

		@include mq-min-max(no, md) {
			padding-left: 10px;
		}
		@include mq-min-max(no, xs) {
			font-size: 12px;
		}
	}

	&__select-label {
		pointer-events: none;
		position: absolute;
		left: 1.1em;
		top: -0.6em;
		z-index: 1;
		margin-bottom: 0px;
		padding-right: 8px;
		padding-left: 8px;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.8);
		border-radius: 4px;
		background-color: transparent;
		font-size: 12px;
		line-height: 0.6;
		font-weight: 500;
		background-clip: padding-box;
		@include mq-min-max(no, md) {
			left: 0.5em;
		}
	}

	&__select-fieldset {
		position: relative;
		width: 133px;
		padding-bottom: 22px;
		align-self: flex-start;

		&.large {
			width: auto;
			align-self: stretch;
		}
	}

	&__postcode-row {
		display: flex;
		padding-bottom: 12px;
		justify-content: flex-start;
		align-items: center;
		@include mq-min-max(no, md) {
			justify-content: space-between;
		}
	}

	&__find-address {
		cursor: pointer;
		display: flex;
		min-width: 195px;
		padding: 11px 35px;
		justify-content: space-between;
		align-items: center;
		align-self: flex-end;
		background-color: $red;
		color: white;
		margin-left: 15px;
		@include mq-min-max(no, md) {
			min-width: auto;
			padding: 12px 15px;
		}
	}

	&__find-address-text {
		padding: 0;
		background-color: transparent;
		font-size: 16px;
		font-weight: 700;
		margin: 0;
	}

	&__find-address-arrow {
		margin-left: 25px;
		@include mq-min-max(no, md) {
			margin-left: 15px;
		}
	}

	&__postcode-input-field {
		padding-left: 20px;
		height: 42px;
		margin: 0;
		width: 100%;
		border-style: solid;
		border-width: 1px;
		border-color: $black-slate;
		border-radius: 6px;
		background-color: transparent;

		&::placeholder {
			color: transparent;
		}

		&:focus + label,
		&:not(:placeholder-shown) + label {
			top: -7px;
			z-index: 1;
			font-size: 13px;
			background-color: rgba(255, 255, 255, 0.8);
			border-radius: 4px;
			margin: 0;
			width: fit-content;
		}

		label {
			position: absolute;
			left: 20px;
			top: 11px;
			font-size: 16px;
			font-weight: 500;
			pointer-events: none;
			transition: 0.2s ease-in-out;

			@include mq-min-max(no, md) {
				margin-left: 10px;
			}
			@include mq-min-max(no, xs) {
				font-size: 12px;
			}
		}
	}

	&__postcode-fieldset {
		position: relative;
		width: 255px;
		margin-right: 16px;
		@include mq-min-max(no, md) {
			margin-right: 12px;
			width: 120px;
		}
	}

	&__submit-register {
		cursor: pointer;
		display: flex;
		min-width: 195px;
		margin-top: 12px;
		padding: 17px 35px;
		justify-content: space-between;
		align-items: center;
		align-self: flex-end;
		background-color: $red;
		@include mq-min-max(no, xs) {
			margin-top: 9px;
			align-self: flex-start;
		}
	}

	&__background-image {
		position: absolute;
		z-index: -1;
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__button {
		width: 230px;
		padding: 5px 30px;

		p {
			font-weight: $weight-bold;
		}

		&:hover {
			padding-right: 20px;
		}
	}

	&__register-link {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	&__register-inner {
		position: relative;
		z-index: 1;
		width: 100%;
		max-width: 1920px;
		color: $black-slate;
		font-weight: 500;

		@include mq(xl) {
			padding: 50px 450px 158px;
		}
		@include mq(md) {
			padding: 50px 200px 158px;
		}
		@include mq(xs) {
			padding: 50px 50px 158px;
		}

		@include mq-min-max(no, xs) {
			padding: 43px 15px 45px;
		}
	}

	&__register-frame {
		padding: 60px;
		background-color: hsla(0, 0%, 100%, 0.8);
	}

	&__register-form {
		width: 100%;
		@include mq-min-max(no, xs) {
			width: 100%;
			padding-left: 0px;
			border-left-style: none;
		}
	}

	&__error-message {
		color: $red;
		margin: 0 0 10px 0;
		font-weight: 900;
	}

	&__manual-address {
		overflow: hidden;
		transition: 0.4s ease-in-out;
		height: 0;
	}

	&__manual-address-inner {
		padding: 5px 0 0 0;
	}
}
