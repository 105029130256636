/* ==========================================================================
    Testimonials Content
   ========================================================================== */

.testimonials-content {
	display: flex;
	width: 100vw;
	justify-content: center;
	font-family: $font-haas;
	color: $black-slate;
	line-height: 1;

	&__red-margin {
		height: 255px;
		min-width: 100px;
		flex: 1;
		background-color: $red;

		@include mq-min-max(no, md) {
			display: none;
		}
	}

	&__white-margin {
		min-width: 100px;
		flex: 1;

		@include mq-min-max(no, md) {
			display: none;
		}
	}

	&__main {
		width: 100%;
		padding-top: 82px;
		padding-bottom: 238px;
		padding-left: 145px;
		padding-right: 145px;

		@include mq-min-max(md, lg) {
			padding-left: 15px;
		}

		@include mq-min-max(no, md) {
			padding: 30px 15px 70px;
		}
	}

	&__heading {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 22px;
		line-height: 1;

		@include mq-min-max(no, md) {
			font-size: 18px;
		}
	}

	&__left {
		padding-top: 9px;
		flex: 1;
	}

	&__right {
		position: relative;
		width: 44%;

		@include mq-min-max(no, md) {
			width: 100%;
		}
	}

	&__main-inner {
		display: flex;
		justify-content: space-between;

		@include mq-min-max(no, md) {
			flex-direction: column;
		}
	}

	&__testimonial-name {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 52px;
		transition: color 300ms ease;
		font-family: $font-haas;
		color: $silver;
		font-size: 68px;
		line-height: 1;
		cursor: pointer;

		@include mq-min-max(no, md) {
			display: none;
		}

		&:hover {
			color: $black-slate;
		}

		&.active {
			color: $black-slate;

			@include mq-min-max(no, md) {
				display: block;
				padding-bottom: 16px;
				font-family: $font-haas;
				font-size: 30px;
			}
		}
	}

	&__testimonial-image {
		width: 100%;
		img {
			width: 100%;
		}
	}

	&__testimonial-description {
		p {
			padding-right: 6px;
			padding-bottom: 38px;
			font-family: $font-haas-tx;
			font-size: 22px;
			line-height: 30px;
			font-weight: 600;

			@include mq-min-max(no, md) {
				padding-right: 10px;
				padding-bottom: 26px;
				font-size: 18px;
				line-height: 24px;
				font-weight: 700;
			}
		}
	}

	.paragraph {
		margin-bottom: 0px;
	}

	&__testimonial-title {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 21px;
		font-weight: 500;

		@include mq-min-max(no, md) {
			font-size: 20px;
		}
	}

	&__header {
		margin-bottom: 75px;
		padding-bottom: 27px;
		border-bottom: 1px solid $black-slate;

		@include mq-min-max(no, md) {
			display: flex;
			margin-bottom: 18px;
			padding-bottom: 9px;
			justify-content: space-between;
		}
	}

	&__slide-counter {
		display: flex;
		input {
			border: 0;
			outline: none;
			background: none;
			width: 20px;
			font-size: 16px;
			font-weight: 600;
		}
	}

	&__slide-prev {
		display: none;

		@include mq-min-max(no, md) {
			display: block;
			color: $black-slate;
			text-decoration: none;
			cursor: pointer;
		}
	}

	&__slide-next {
		display: none;

		@include mq-min-max(no, md) {
			display: block;
			color: $black-slate;
			text-decoration: none;
			cursor: pointer;
		}
	}

	&__slide-counter-dash {
		display: none;

		@include mq-min-max(no, md) {
			display: block;
			margin-top: 0px;
			margin-bottom: 0px;
			padding-right: 7px;
			padding-left: 7px;
		}
	}

	&__image-wrapper {
		margin-bottom: 27px;

		@include mq-min-max(no, md) {
			position: relative;
			margin-bottom: 25px;
		}
	}

	&__nav-controls {
		display: none;

		@include mq-min-max(no, md) {
			position: absolute;
			z-index: 2;
			top: 50%;
			display: flex;
			width: 100%;
			justify-content: space-between;
			transform: translate(0px, -50%);
		}
	}

	&__nav-next,
	&__nav-prev {
		position: relative;
		background-color: #e3e2e2;
		width: 38px;
		height: 38px;
		border-radius: 100%;
		cursor: pointer;
		transition: 0.4s ease-in-out;

		&::before,
		&::after {
			position: absolute;
			content: '';
			transform-origin: 100%;
			background-color: #393939;
			height: 1px;
			width: 7px;
			border-radius: 10px;
		}

		&:hover {
			background-color: #393939;
			&::before,
			&::after {
				background-color: #e3e2e2;
			}
		}
	}

	&__nav-next {
		margin-right: -8px;

		&::before {
			top: 14px;
			right: 20px;
			transform: rotate(225deg);
		}

		&::after {
			top: 24px;
			right: 20px;
			transform: rotate(135deg);
		}
	}

	&__nav-prev {
		margin-left: -8px;

		&::before {
			top: 14px;
			left: 13px;
			transform: rotate(315deg);
		}

		&::after {
			top: 24px;
			left: 13px;
			transform: rotate(45deg);
		}
	}
}
