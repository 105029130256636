/* ==========================================================================
Course Content
   ========================================================================== */

.course-content {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	font-family: $font-haas;
	color: $black-slate;
	background-color: #F8F8F9;

	@include mq-min-max(no, md2) {
		width: 100vw;
		min-height: auto;
		padding-right: 0px;
		padding-bottom: 0px;
		padding-left: 0px;
	}

	&__select-title{
		font-family: $font-haas;
		font-weight: $weight-bold;
		@include rem(20);
		margin-bottom: 20px;
	}

	&__inner {
		width: 100%;
		max-width: 1920px;
		padding: 34px 100px 35px;

		@include mq-down(lg) {
			padding: 24px 15px 9px;
		}
	}

	&__breadcrumbs {
		padding-bottom: 64px;
		font-size: 12px;
		line-height: 1;

		@include mq-min-max(no, md2) {
			padding-bottom: 33px;
		}
	}

	&__main {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 30px;

		@include mq-down(md) {
			grid-template-columns: 1fr;
		}
	}

	&__left {
		width: 100%;
		@include mq(md) {
			height: fit-content;
			// position: sticky;
			// top: 100px;
		}
	}

	&__right {
		max-height: var(--left-content-height);
		overflow-y: scroll;
		
		padding-top: 39px;

		@include mq(md2) {
			padding-left: 20px;
		}

		padding-top: 0px;
		
		@include mq-down(md) {
			padding-left: 0px;
			img {
				display: none;
			}
		}

		h1 {
			margin-top: 0px;
			margin-bottom: 0px;
			padding-bottom: 15px;
			font-size: 30px;
			line-height: 1;

			@include mq-min-max(no, md2) {
				padding-bottom: 9px;
				font-family: $font-haas-tx;
				font-size: 30px;
			}
		}

		h2 {
			margin-top: 40px;
			font-size: 22px;
			line-height: 30px;
			font-weight: 700;

			@include mq-min-max(no, md2) {
				padding-bottom: 40px;
				font-size: 18px;
			}
		}

		p {
			font-size: 22px;
			line-height: 30px;
			font-weight: 500;

			@include mq-min-max(no, md2) {
				padding-bottom: 24px;
				font-size: 18px;
			}
		}

		ul {
			padding: 0;
		}

		li {
			list-style-type: none;
			font-weight: $weight-regular;
			@include rem(22);
			line-height: 30px;

			&:before {
				content: '—';
			}
		}

		img {
			width: 100%;
			margin-bottom: 39px;

			@include mq-min-max(no, md2) {
				margin-bottom: 17px;
			}
		}
	}

	&__breadcrumb-link {
		display: inline-block;
		color: #424957;
		font-weight: 600;
		text-decoration: none;
	}

	&__price-number {
		display: inline-block;
		margin-top: 0px;
		margin-right: 18px;
		margin-bottom: 0px;
		color: $red;
		font-size: 40px;
		line-height: 1;
		font-weight: 500;

		@include mq-min-max(no, md2) {
			margin-right: 12px;
			font-size: 22px;
		}
	}

	&__price {
		margin-bottom: 33px;
		padding-bottom: 34px;
		border-bottom: 1px solid $dim-grey;

		@include mq-min-max(no, md2) {
			margin-bottom: 18px;
			padding-bottom: 15px;
		}

		&.noPrice{
			.course-content__price-label-none{
				display: block;
			}
			.course-content__price-label{
				display: none;
			}
			.course-content__price-number{
				display: none;
			}
		}
	}

	&__price-label {
		display: inline-block;
		margin-top: 0px;
		margin-bottom: 0px;
		color: $red;
		font-size: 24px;
		line-height: 1;
		font-weight: 500;

		@include mq-min-max(no, md2) {
			font-size: 18px;
		}

		&-none{
			display: none;
			margin-top: 0px;
			margin-bottom: 0px;
			color: $red;
			font-size: 24px;
			line-height: 1;
			font-weight: 500;
	
			@include mq-min-max(no, md2) {
				font-size: 18px;
			}
		}
	}

	&__search {
		display: flex;
		margin-bottom: 38px;
		padding-bottom: 29px;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $silver;
		gap: 15px;

		@include mq-min-max(no, md2) {
			margin-bottom: 31px;
			padding-bottom: 17px;
			flex-direction: column;
		}

		.course-button {
			@include mq-down(md) {
				width: 100%;
			}
		}
	}

	&__search-location {
		background-color: #F8F8F9;
		position: relative;
		overflow: hidden;
		width: 56%;
		padding-left: 15px;
		border-right: 1px solid $black-slate;
		border-radius: 25px 0px 0px 25px;

		@include mq-min-max(no, md2) {
			width: 53%;
			padding-left: 14px;
		}
	}

	&__search-places {
		background-color: #F8F8F9;
		position: relative;
		padding-left: 15px;
		justify-content: space-between;
		flex: 1;
		border-radius: 0px 25px 25px 0px;
	}

	&__dropdown {
		display: flex;
		width: 61.5%;
		height: 59px;
		border-color: $black-slate;
		border-radius: 30px;

		@include mq-min-max(no, md2) {
			width: 100%;
			height: 40px;
			margin-bottom: 15px;
			border-radius: 20px;
		}
	}

	&__search-button {
		display: flex;
		padding: clamp(15px, 1.3vw, 23px) clamp(15px, 2vw, 32px);
		justify-content: space-between;
		align-items: center;
		background-color: $red;
		color: white;
		text-decoration: none;
		cursor: pointer;

		@include mq-min-max(no, md2) {
			width: 100%;
			justify-content: center;
		}
	}

	&__search-button-text {
		font-size: clamp(20px, 3vw, 24px);
		line-height: 1;
		font-weight: 700;
		margin: 0;
		text-wrap: nowrap;
	}

	&__search-button-arrow {
		width: 43px;
		margin-left: 24px;

		@include mq-min-max(no, md2) {
			margin-left: 26px;
		}
	}

	&__search-item {
		margin-bottom: 24px;
		margin-top: 0;
		font-size: 16px;
		line-height: 1;
		font-weight: 600;

		@include mq-min-max(no, md2) {
			font-size: 14px;
		}
	}

	&__search-arrow {
		position: absolute;
		top: 50%;
		right: 21px;
		transform: translate(0px, -50%);

		@include mq-min-max(no, md2) {
			right: 19px;
		}
	}

	&__search-arrow-places {
		position: absolute;
		top: 50%;
		right: 37px;
		transform: translate(0px, -50%);

		@include mq-min-max(no, md2) {
			right: 22px;
		}
	}

	&__details,
	&__course-info {
		padding-bottom: 28px;
		gap: 20px;
		width: 100%;
		@include mq-min-max(no, md2) {
			margin-bottom: 28px;
		}
	}

	&__pdf{
		width: 100%;
		padding: 20px 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-decoration: none;

		p{
			color: #393939;
		}

		font-family: $font-haas;
		@include rem(20);


	}

	&__other-courses{
		width: 100%;
		background: #E3E2E2;
		padding: 20px 50px;
		display: flex;
		align-items: center;
		gap: 20px;

		&-title {
			display: inline-block;
			margin-right: 25px;
			margin-bottom: 0px;
			font-size: 20px;
			line-height: 1;
			font-weight: 700;
	
			@include mq-down(md2) {
				margin-right: 0px;
				padding-bottom: 12px;
				font-size: 18px;
			}
		}

		&-link {
			color: $black-slate;
			font-size: 20px;
			line-height: 1;
			font-weight: 500;
	
			@include mq-down(md2) {
				display: block;
				font-size: 18px;
			}
		}
	}


	&__course-info {
		display: flex;
		flex-direction: row;

		

		@include mq-min-max(no, md) {
			flex-direction: column;
		}

		.course-details{
			flex-direction: row;
			display: flex;
			min-width: 50%;

			@include mq-min-max(no, md) {
				flex-direction: column;
			}
		}

		
	}

	&__info-content {
		display: flex;
		flex-direction: column;
		width: fit-content;
		padding-right: 20px;
		@include mq-min-max(no, md) {
			width: 100%;
			padding-right: unset;
		}
	}

	&__info-section {
		margin-bottom: 40px;

		&:last-child {
			margin-bottom: 5px;
		}

		&--training-provider {
			display: flex;
			align-items: center;
			border-bottom: 1px solid $silver;
			margin-top: 50px;
			padding-bottom: 50px;
		}

		p {
			@include rem(20);
			font-weight: $weight-light;
			margin: 5px 0px;
			&:nth-child(1) {
				font-weight: $weight-bold;
			}
		}

		&--sessions{
			display: flex;
			flex-direction: column;
			padding: 0;

			li{
				border-top: 1px solid black;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				p{
					font-size: 16px;
				}
			}
		}
	}

	&__summary-title {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 32px;
		font-size: 30px;
		line-height: 1;

		@include mq-min-max(no, md2) {
			padding-bottom: 20px;
			font-size: 22px;
		}
	}
}


