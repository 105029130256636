@function convert($target, $context: 16, $unit: em) {
	@if $unit=='em' {
		@return ($target / $context) * 1em;
	}
	@else if $unit=='rem' {
		@return ($target / $context) * 1rem;
	}
	@else if $unit=='%' {
		@return percentage($target / $context);
	}
}

@function opposite-direction($directions) {
	$opposite-directions: ();
	$direction-map: ( 'top': 'bottom', 'right': 'left', 'bottom': 'top', 'left': 'right', 'center': 'center', 'ltr': 'rtl', 'rtl': 'ltr');
	@each $direction in $directions {
		$direction: to-lower-case($direction);
		@if map-has-key($direction-map, $direction) {
			$opposite-directions: append($opposite-directions, unquote(map-get($direction-map, $direction)));
		}
		@else {
			@warn 'No opposite direction can be found for `#{$direction}`. Direction omitted.';
		}
	}
	@return $opposite-directions;
}

@function strip-unit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}
	@return $number;
}
