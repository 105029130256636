/* --------------------------------
    Form Checkbox
   -------------------------------- */

.form-checkbox {
	$root: &;

	display: flex;

	&__input {
		display: none;
		width: 50px;
	}

	&__icon {
		display: flex;
		display: block;
		justify-content: center;
		align-items: center;
		width: 13px;
		height: 13px;
		margin-top: 3px;
		margin-right: 10px;
		cursor: pointer;
		transition: background-color 0.2s ease-in-out;
		border: 1px solid;
		border-color: var(--checkbox-border, #{$color-secondary});
		border-radius: 100px;
		background-color: var(--checkbox-background, #{rgba($color-secondary, 0)});

		#{$root}__input:checked ~ & {
			--checkbox-background: #{$color-secondary};
		}

		#{$root}__input:disabled ~ & {
			cursor: disabled;
			pointer-events: none;
		}
	}

	&__label {
		@include rem(14);
		@include letter-spacing(10);

		font-family: $font-acumin;
		max-width: calc(100% - 30px);
		color: var(--checkbox-label);

		#{$root}__input:disabled ~ & {
			cursor: disabled;
			pointer-events: none;
		}

		a {
			text-decoration: underline;
			color: inherit;
		}
	}
}
