.course-main {
	width: 100%;
	line-height: 30px;
	background-color: $competence-grey;

	@include mq-min-max(no, md2) {
		padding: 0px 15px;
	}

	&__inner {
		border-top: 1px solid #c4c4c4;
		width: 100%;
		max-width: 1920px;
		padding: 25px 100px 0;
		margin: 0px auto;
	}

	&__breadcrumbs {
		@include rem(12);
		font-weight: $weight-semibold;
		display: flex;
		flex-direction: row;

		a {
			text-decoration: none;
			color: black;
		}

		p {
			margin: 0px 2px;
		}
	}

	&__content-inner {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@include mq-min-max(no, md) {
			flex-direction: column;
			align-items: center;
		}
	}

	&__content {
		width: 50%;
		display: flex;
		flex-direction: column;
		font-weight: $weight-bold;

		@include mq-min-max(sm, md2) {
			width: 75%;
		}

		@include mq-min-max(no, md) {
			width: 100%;
		}

		&:last-child {
			max-width: 693px;
		}

		&--title {
			font-size: clamp(40px, 4vw, 70px);
			font-weight: $weight-semibold;
			border-bottom: 1px solid #c4c4c4;
			line-height: clamp(50px, 4vw, 80px);
		}

		&--right-title {
			@include rem(30);
		}

		&--right-paragraph {
			@include rem(22);
		}

		ul {
			padding: 0;
		}

		li {
			list-style-type: none;
			font-weight: $weight-regular;
			@include rem(22);

			&:before {
				content: '—';
			}
		}

		&--right-paragraph-regular {
			@include rem(22);
			font-weight: $weight-regular;
		}
	}

	&__content-description {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 20px;
		border-bottom: 1px solid #c4c4c4;

		@include mq-min-max(no, xl) {
			flex-direction: column;
			align-items: baseline;
		}

		&--description {
			@include rem(20);
			font-weight: $weight-regular;
			max-width: 420px;
		}

		&--button {
			background-color: #bb3530;
			color: white;
			padding: 0px 30px;
			text-decoration: none;
			display: flex;
			width: 280px;
			height: 70px;
			justify-content: space-between;
			align-items: center;
			@include rem(24);
			@include mq-min-max(no, sm) {
				width: 100%;
			}
		}

		&--arrow {
			max-height: 20px;
			max-width: 45px;
		}
	}

	&__downloads {
		@include rem(20);
		font-weight: $weight-bold;
		width: 100%;
		border-bottom: 1px solid #c4c4c4;
		padding-bottom: 20px;
	}

	&__file {
		display: flex;
		flex-direction: row;
		align-items: center;

		&--image {
			max-width: 36px;
			max-height: 46px;
		}

		&--description {
			@include rem(20);
			font-weight: $weight-bold;
			margin-left: 20px;
			padding-right: 20px;
		}

		&--download {
			max-height: 20px;
			max-width: 18px;
			margin-left: auto;
		}
	}
}
