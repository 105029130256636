/* ==========================================================================
    Faqs Content
   ========================================================================== */

.faqs-content {
	position: relative;
	display: flex;
	font-family: $font-haas;
	color: $black-slate;

	&__background {
		position: absolute;
		width: 50%;
		height: 335px;
		background-color: $red;

		@include mq-min-max(no, xs) {
			display: none;
		}
	}

	&__content {
		position: relative;
		z-index: 1;
		display: flex;
		width: 100vw;
		justify-content: center;
	}

	&__content-outer {
		width: 100%;
		max-width: 1820px;
		margin-left: 100px;
		padding-top: 105px;
		padding-right: 100px;
		padding-left: 0px;
		background-color: white;

		@include mq-min-max(xs, md) {
			padding-right: 15px;
		}

		@include mq-min-max(no, xs) {
			margin-left: 0px;
			padding-top: 25px;
			padding-right: 15px;
			padding-left: 15px;
		}
	}

	&__content-inner {
		display: flex;
		padding-right: 0px;
		padding-bottom: 250px;
		padding-left: 70px;

		@include mq-min-max(xs, md) {
			flex-direction: column;
		}

		@include mq-min-max(no, xs) {
			padding-right: 0px;
			padding-bottom: 80px;
			padding-left: 0px;
			flex-direction: column;
		}
	}

	&__left-column {
		max-width: 49.2%;
		min-width: 49.2%;
		padding-top: 5px;
		padding-right: 188px;

		@include mq-min-max(xs, md) {
			max-width: none;
			min-width: none;
			margin-bottom: 30px;
		}

		@include mq-min-max(xs, lg) {
			padding-right: 50px;
		}

		@include mq-min-max(no, xs) {
			max-width: none;
			min-width: auto;
			padding-top: 0px;
			padding-right: 0px;
			padding-bottom: 20px;
		}
	}

	&__right-column {
		padding-top: 0px;
		flex: 1;

		@include mq-min-max(no, xs) {
			padding-top: 30px;
		}
	}

	&__support {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 22px;
		line-height: 30px;
		font-weight: 500;

		@include mq-min-max(no, xs) {
			font-size: 18px;
			line-height: 24px;
		}
	}

	&__faq {
		padding-top: 20px;
		padding-bottom: 23px;
		border-bottom: 1px solid $silver;

		&.first {
			padding-top: 0;
		}

		@include mq-min-max(no, xs) {
			padding-top: 18px;
			padding-bottom: 18px;
			font-size: 18px;
		}
	}

	&__faq-question-text {
		max-width: 80%;
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 0px;
		font-size: 30px;
		line-height: 36px;

		@include mq-min-max(no, xs) {
			padding-right: 8px;
			font-size: 24px;
			line-height: 28px;
		}
	}

	&__faq-answer {
		padding-right: 115px;
		font-size: 18px;
		line-height: 24px;
		font-weight: 700;
		max-height: 0;
		opacity: 0;
		transition: 0.3s opacity ease, max-height 0.3s ease, padding 0.3s ease;

		@include mq-min-max(no, xs) {
			padding-right: 0px;
		}

		&.expanded {
			padding-top: 22px;
			padding-bottom: 16px;
			max-height: 500px;
			opacity: 1;

			@include mq-min-max(no, xs) {
				padding-top: 19px;
			}
		}
	}

	&__faq-content {
		padding-right: 0px;
	}

	&__faq-icon-plus {
		display: block;
		padding-top: 4px;

		&.expanded {
			display: none;
		}

		@include mq-min-max(no, xs) {
			padding-right: 4px;
		}
	}

	&__faq-icon-minus {
		display: none;
		padding-top: 4px;

		&.expanded {
			display: block;
		}

		@include mq-min-max(no, xs) {
			padding-right: 4px;
		}
	}

	&__faq-question {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		cursor: pointer;
	}
}
