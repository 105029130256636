/* --------------------------------
   Grid Mixins
   -------------------------------- */

@mixin make-row($gutter: $grid-gutter-width) {
	@include make-row-margins($gutter);

	display: flex;
	flex-wrap: wrap;
}

@mixin make-row-margins($gutter: $grid-gutter-width) {
	$value: strip-unit($gutter);
	$value: ($value / 2) * -1px;

	margin-right: $value;
	margin-left: $value;
}

@mixin make-col($size: false, $gutter: $grid-gutter-width) {
	@include make-col-padding($gutter);
	@include make-col-size($size);

	flex-shrink: 0;
	max-width: 100%;
}

@mixin make-col-size($size: false) {
	@if $size {
		flex-basis: #{$size};
		width: #{$size};
		max-width: #{$size};
	} @else {
		flex-basis: auto;
	}
}

@mixin make-col-auto() {
	flex-basis: auto;
	width: auto;
	max-width: none;
}

@mixin make-col-padding($gutter: $grid-gutter-width) {
	@if $gutter and $gutter != 0 {
		$value: strip-unit($gutter);
		$value: ($value / 2) * 1px;

		padding-right: $value;
		padding-left: $value;
	}
}
