/* ==========================================================================
Course List
   ========================================================================== */

.tst {
	$root: &;

	width: 100vw;
	font-family: $font-haas;
	display: flex;
	justify-content: center;

	&__header{
		display: flex;
		flex-direction: row;
		width: 100%;
		border-bottom: 1px solid #707070;
		margin-bottom: 33px;
		padding-bottom: 34px;
		@include mq-min-max(no, md) {
			flex-direction: column;
		}
		&-name{
			font-family: "font-haas",sans-serif;
			color: #393939;
			font-size: 2em;
			margin: 0.67em 0;
		}

		&-strapline{
			color: #393939;
			font-size: 1.2em;
			font-weight: 400;
		}
		
		&-left, &-right{
			width: 50%;
			@include mq-min-max(no, md) {
				width: 100%;
			}
		}
	}


	&__outer {
		display: flex;
		width: 100%;
		max-width: 1920px;
		padding: 43px 100px 110px;
		flex-direction: column;
		align-items: center;
		@include mq-min-max(no, xs) {
			padding: 17px 15px 50px;
		}
	}
	&__selector{
		display: flex;
		align-items: flex-start;
		width: 100%;
	}

	&__search{
		display: flex;
		margin-bottom: 38px;
		padding-bottom: 29px;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $silver;
		gap: 15px;
		width: 100%;

		@include mq-min-max(no, md2) {
			margin-bottom: 31px;
			padding-bottom: 17px;
			flex-direction: column;
		}

		&-option{
			background-color: #F8F8F9;
			position: relative;
			overflow: hidden;
			width: fit-content;
			padding: 0 15px;
			border-right: 1px solid $black-slate;

			&.disabled{
				cursor: not-allowed !important;
				pointer-events: none;
				opacity: 0.5;
				&:hover{
					cursor: not-allowed !important;
				}
			}
			&:first-of-type{
				border-radius: 25px 0px 0px 25px;
			}

			&:last-of-type{
				border-radius: 0px 25px 25px 0px;
			}

			@include mq-min-max(no, md2) {
				width: 100%;
				border-radius: 25px !important;
				height: 40px;
			}
		}
	}

	&__dropdown {
		display: flex;
		width: 100%;
		height: 59px;
		border-color: $black-slate;
		border-radius: 30px;

		@include mq-min-max(no, md2) {
			width: 100%;
			height: fit-content;
			flex-direction: column;
			margin-bottom: 15px;
			border-radius: 20px;
		}
	}

	.course-button{
		&.disabled{
			cursor: not-allowed !important;
			pointer-events: none;
			opacity: 0.5;
			display: none;
			&:hover{
				cursor: not-allowed !important;
			}
		}
	}


	&__content{
		display: flex;
		flex-direction: row;
		width: 100%;
		gap: 20px;
		@include mq-min-max(no, md) {
			flex-direction: column;
			
		}
		
		&__course-info {
			display: flex;
			flex-direction: row;

			@include mq-min-max(no, md) {
				flex-direction: column;
				&:has(.show){
					border-bottom: 1px solid #c4c4c4;
				}
			}
		}
		&__info-content {
			display: flex;
			flex-direction: column;
			width: 50%;
			gap: 20px;
			margin-bottom: 20px;
			@include mq-min-max(no, md) {
				width: 100%;
			}
		}
	
		&__info-section {
			display: none;

			&.show{
				display: block;
			}
			
			&:last-child {
				margin-bottom: 5px;
			}
	
			&--training-provider {
				display: flex;
				align-items: center;
			}
	
			p {
				@include rem(20);
				font-weight: $weight-light;
				margin: 5px 0px;
				&:nth-child(1) {
					font-weight: $weight-bold;
				}
			}
		}
		
		&-price{
			display: inline-block;
			margin-top: 0px;
			margin-right: 18px;
			margin-bottom: 0px;
			color: #bb3530;
			font-size: 40px;
			line-height: 1;
			font-weight: 500;
			&-wrapper{
				margin-bottom: 33px;
				padding-bottom: 34px;
				display: none;

				&.hasPrice{
					display: block;
				}
			}
		}
		&-pricetext{
			display: inline-block;
			margin-top: 0px;
			margin-bottom: 0px;
			color: #bb3530;
			font-size: 24px;
			line-height: 1;
			font-weight: 500;
		}
		&-left, &-right{
			transition: 0.4s ease-in-out;
			@include mq-min-max(no, md) {
			}
		}

		&-left{
			width: 0%;
			&:has(.show){
				width: 100%;
			}
		}

		&-right{
			flex-grow: 1;
		}
	}
	
}
