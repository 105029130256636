.products-list {
	display: flex;
	width: 100%;

	@include mq(md) {
		width: 50%;
	}

	&__inner {
		position: sticky;
		top: 100px;

		@include mq(md) {
			top: 200px;
		}
	}

	&.confirmation {
		@include mq(md) {
			width: 100%;
		}

		h2 {
			margin-top: 0px;
			margin-bottom: 0px;
			padding-bottom: 19px;
			font-size: 30px;
			line-height: 36px;
			text-align: left;

			@include mq-min-max(no, xs) {
				padding-bottom: 20px;
			}
		}

		p {
			margin-top: 0px;
			margin-bottom: 0px;
			padding-bottom: 14px;
			font-size: 16px;
			line-height: 1;

			@include mq-min-max(no, xs) {
				padding-bottom: 10px;
			}
		}

		a {
			color: $black-slate;
			font-size: 16px;
			line-height: 1;
			font-weight: 500;
			text-decoration: none;

			@include mq-min-max(no, xs) {
				padding-bottom: 3px;
				font-size: 14px;
			}
		}
	}

	&__summary {
		width: 100%;
		background-color: $white-smoke;
		padding: 70px 100px 20px;
		@include mq-min-max(no, xs) {
			padding: 20px 20px;
		}
	}

	&__summary-items {
		border-bottom: 1px solid $silver;
	}

	&__summary-item {
		display: flex;
		padding-top: 7px;
		padding-bottom: 7px;
		align-items: center;
		border-top: 1px solid $silver;
	}

	&__summary-totals {
		display: flex;
		padding-top: 4px;
		flex-direction: column;

		// @include mq(md) {
		// 	padding-left: 125px;
		// }

		@include mq-min-max(no, xs) {
			padding-left: 0px;
		}
	}

	&__summary-subtotal {
		display: flex;
		padding-top: 19px;
		padding-bottom: 16px;
		justify-content: space-between;
		border-bottom: 1px solid $silver;

		@include mq-min-max(no, xs) {
			padding-top: 16px;
			padding-bottom: 16px;
		}
	}

	&__smmary-subtotal-label {
		margin-top: 0px;
		margin-bottom: 0px;
		line-height: 1;
		font-size: 18px;

		@include mq-min-max(no, xs) {
			font-size: 16px;
		}
	}

	&__summary-subtotal-amount {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 18px;
		line-height: 1;
		font-weight: 500;

		@include mq-min-max(no, xs) {
			font-size: 16px;
		}
	}

	&__summary-total {
		display: flex;
		margin-bottom: 34px;
		padding-top: 20px;
		padding-bottom: 20px;
		justify-content: space-between;
		border-top: 1px solid $black-slate;
		border-bottom: 1px solid $black-slate;

		@include mq-min-max(no, xs) {
			margin-bottom: 30px;
		}
	}

	&__summary-total-label {
		margin-top: 0px;
		margin-bottom: 0px;
		line-height: 1;
		font-size: 24px;

		@include mq-min-max(no, xs) {
			font-size: 22px;
		}
	}

	&__summary-total-amount {
		margin-top: 0px;
		margin-bottom: 0px;
		line-height: 1;
		font-size: 24px;

		@include mq-min-max(no, xs) {
			font-size: 22px;
		}
	}

	&__checkbox-field {
		display: flex;
		padding-bottom: 30px;
		flex-direction: row-reverse;
		align-items: center;

		@include mq-min-max(no, xs) {
			margin-bottom: 0px;
			padding-left: 0px;
			flex-direction: row;
			justify-content: flex-start;
		}

		.confirmation & {
			display: none;
		}
	}

	&__checkbox-input {
		appearance: none;
		margin: 0;
		color: $black-slate;
		width: 22px;
		height: 22px;
		border: 1px solid currentColor;
		border-radius: 4px;
		display: grid;
		place-content: center;
		cursor: pointer;

		@include mq-min-max(no, xs) {
			width: 18px;
			height: 18px;
		}

		&:checked::before {
			transform: scale(1);
		}

		&:before {
			content: '';
			width: 18px;
			height: 18px;
			clip-path: polygon(17% 51%, 40% 76%, 81% 28%, 87% 33%, 40% 88%, 11% 56%);
			transform: scale(0);
			transform-origin: bottom left;
			transition: 120ms transform ease-in-out;
			box-shadow: inset 1em 1em black;
			background-color: $black-slate;
		}
	}

	&__checkbox-label {
		color: $black-slate;
		font-size: 16px;
		font-weight: 500;
		line-height: 1;
		display: grid;
		cursor: pointer;
		align-items: center;

		&.right {
			margin-left: 12px;
		}

		&.left {
			margin-right: 12px;

			@include mq-min-max(no, xs) {
				margin-left: 12px;
			}
		}

		&.error {
			color: $red;
			font-weight: bold;
		}
	}

	&__submit-button {
		border: 0;
		display: flex;
		width: 100%;
		max-width: 254px;
		margin-bottom: 33px;
		padding: 18px 40px;
		justify-content: space-between;
		align-self: flex-end;
		background-color: $red;
		color: white;
		text-decoration: none;
		transition: 0.4s ease-in-out;

		.confirmation & {
			display: none;
		}

		&:hover {
			padding: 18px 30px;
		}

		@include mq-min-max(no, xs) {
			max-width: 193px;
			align-self: flex-start;
		}
	}

	&__submit-butotn-text {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 18px;
		line-height: 1;
		font-weight: 700;

		@include mq-min-max(no, xs) {
			font-size: 16px;
		}
	}

	&__summary-footer {
		display: flex;
		padding-top: 25px;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		border-top: 1px solid $silver;

		@include mq-min-max(no, xs) {
			align-items: center;
		}
	}

	&__summary-footer-text {
	}

	&__summary-footer-links {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include mq(xxl) {
			flex-direction: row;
		}

		a {
			color: $black-slate;
			font-size: 16px;
			line-height: 1;
			font-weight: 500;
			text-decoration: none;

			@include mq-min-max(no, xs) {
				padding-bottom: 3px;
				font-size: 14px;
			}
		}
	}

	&__summary-footer-pipe {
		margin: 0px 10px;
		font-size: 16px;
		line-height: 1;
		font-weight: 500;

		@include mq-min-max(no, xxl) {
			display: none;
		}
	}

	&__address {
		text-align: left;
		margin-top: 50px;
		padding-bottom: 50px;

		h4 {
			@include rem(16);
			font-weight: $weight-bold;
			margin-top: 0;
			margin-bottom: 10px;
		}

		p {
			@include rem(16);
			font-weight: $weight-medium;
			padding: 0;
			margin-bottom: 5px;
		}
	}
}
