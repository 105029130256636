.link-button {
	$root: &;
	display: flex;
	flex-direction: column;
	width: fit-content;
	overflow: hidden;

	a {
		text-decoration: none;

		&:hover + #{$root}__underline {
			margin-left: 0;
		}
	}

	&__underline {
		border: 1px solid black;
		width: 100%;
		margin-left: -100%;
		transition: 0.2s ease-in-out;

		&.red {
			border: 1px solid $red;
		}
		&.white {
			border: 1px solid $white;
		}
	}
}

.main-button {
	display: flex;
	justify-content: space-between;
	background-color: $red;
	text-decoration: none;
	transition: 0.2s ease-in-out;
	align-items: center;
	color: white;

	&:hover {
		background-color: #ac1f19;
	}
}

.red-book-button{
	background-color: $red;
	text-decoration: none;
	transition: 0.2s ease-in-out;
	align-items: center;
	color: white;
	margin: 10px 0px 10px 0px;
	padding: 15px 20px;
	font-family: "font-haas",sans-serif;
	font-size: 16px;
	font-weight: 700;
	
	&:hover {
		background-color: #ac1f19;
	}
}
