/* --------------------------------
   Quantity
   -------------------------------- */

.quantity {
	$root: &;

	display: flex;
	align-items: center;

	&__value {
		display: none;
		margin-right: 20px;
		transition: opacity 0.3s ease-in-out;
		text-align: right;

		@include mq(md) {
			display: flex;
		}

		&.is-faded {
			opacity: 0.2;
		}

		// &-currency {
		// }

		// &-cost {
		// }
	}

	&__field {
		display: flex;

		+ #{$root}__value {
			margin-right: 0;
			margin-left: 20px;
		}
	}

	&__input {
		width: 85px;
		height: 50px;
		padding: 3px 0 0 9px;
		text-align: center;
		border: 1px solid '';

		+ #{$root}__button {
			border-right: 1px solid '';
			border-left: 0;
		}
	}

	&__button {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		width: 50px;
		height: 50px;
		border: 1px solid '';
		border-right: 0;

		&:before,
		&:after {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			content: '';
			background-color: '';
		}

		&--up {
			&:before {
				width: 1px;
				height: 20px;
			}

			&:after {
				width: 20px;
				height: 1px;
			}
		}

		&--down {
			&:after {
				width: 22px;
				height: 1px;
			}

			&:before {
				content: initial;
			}
		}
	}
}
