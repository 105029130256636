/* ==========================================================================
Home Hero
   ========================================================================== */

.home-hero {
	position: relative;
	display: flex;
	width: 100vw;
	min-height: 100vh;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background-color: transparent;
	font-family: $font-haas;
	color: white;
	font-weight: 700;

	@include mq-min-max(no, md) {
		height: auto;
		min-height: auto;
		padding-bottom: 0px;
		flex-direction: column;
		justify-content: flex-start;
	}

	&__course-button {
		display: flex;
		min-width: 33px;
		min-height: 33px;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: white;
		transition: background-color 250ms ease;

		@include mq-min-max(no, md) {
			width: 33px;
			height: 33px;
			margin-right: 0px;
		}
	}

	&__course-button:hover {
		background-color: $black-slate;
	}

	&__course-button-image {
		width: 6px;
		object-fit: cover;
		filter: var(--black-color);

		@include mq-min-max(no, md) {
			width: 7px;
		}
	}

	&__button {
		width: 230px;
		padding: 20px 30px;

		&:hover {
			padding-right: 20px;
		}

		@include mq-min-max(no, md) {
			width: auto;
			min-width: 190px;
			padding: 11px 18px;
		}
	}

	&__button-text {
		display: inline-block;
		margin-bottom: 0px;
		color: white;
		font-size: 16px;
		line-height: 1;
		margin: 0;
	}

	&__inner {
		width: 100%;
		max-width: 1920px;
		padding-top: 220px;
		padding-right: 100px;
		padding-left: 100px;
		flex-direction: column;
		justify-content: space-between;
		font-family: $font-haas-tx;
	}

	h1 {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 41px;
		font-family: $font-haas-tx;
		font-size: 74px;
		line-height: 93px;
		max-width: 60%;
		text-wrap: pretty;

		@include mq-min-max(xs, md) {
			font-size: 47px;
			line-height: 45px;
			max-width: 100%;
		}
		@include mq-min-max(no, xs) {
			font-size: 30px;
			line-height: 38px;
			padding-bottom: 31px;
			max-width: 100%;
		}
	}

	&__hero {
		width: 100%;
		max-width: 1920px;
		padding-top: 220px;
		padding-right: 100px;
		padding-left: 100px;

		@include mq-min-max(no, md) {
			display: flex;
			padding: 134px 15px 115px;
			flex-direction: column;
			align-items: flex-start;
			// background-color: black;
		}
	}

	&__background {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;

		// @include mq-min-max(no, md) {
		// 	display: none;
		// }
	}

	.image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__background-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__background-overlay {
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-color: black;
		opacity: 0.3;
	}

	&__footer{
		width: 100%;
		display: grid;
		grid-template-columns: repeat(4, 1fr);

		@include mq-down(md){
			grid-template-columns: 1fr;
		}

		&-item{
			width: 100%;
			background-color: rgba($color: #272E3C, $alpha: 0.8);
			border: 1px solid white;
			border-right: 0;
			padding: 70px 30px;
			transition: 0.4s ease;
			align-items: center;
			gap: 20px;

			@include mq-down(md){
				padding: 28px 17px;
				border-right: 1px solid white;

			}

			display: flex;
			justify-content: space-between;
			color: white;
			text-decoration: none;

			h2{
				font-family: $font-haas-tx;
				@include rem(30);
				margin: 0;
				@include mq-min-max(md,lg){
					@include rem(23);
				}
				@include mq-down(md){
					@include rem(17);
				}
			}

			img{
				opacity: 0;
				width: 48px;
				transition: 0.4s ease;

				@include mq-down(md){
					width: 26px;
				}
			}

			&:hover{
				background-color: $red-hover;
				img{
					opacity: 1;
				}
			}
		}
	}
}
