/* ==========================================================================
   Home News
   ========================================================================== */

.home-news {
	position: relative;
	display: flex;
	width: 100vw;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	font-family: $font-haas;
	color: $black-slate;
	font-weight: 700;

	@include mq-min-max(no, xs) {
		height: auto;
		padding-bottom: 0px;
		flex-direction: column;
		justify-content: flex-start;
	}

	&__content {
		width: 100%;
		max-width: 1920px;
		padding-right: 100px;
		padding-bottom: 110px;
		padding-left: 100px;

		@include mq-min-max(no, xs) {
			padding-right: 17px;
			padding-bottom: 60px;
			padding-left: 17px;
		}
	}

	&__header {
		display: flex;
		margin-bottom: 58px;
		padding-top: 116px;
		padding-bottom: 25px;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $black-slate;
		font-weight: 700;

		@include mq-min-max(no, xs) {
			margin-bottom: 40px;
			padding-top: 35px;
			padding-bottom: 12px;
		}
	}

	&__header-title {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 22px;
		line-height: 1;

		@include mq-min-max(no, xs) {
			padding-bottom: 0px;
			font-size: 18px;
		}
	}

	&__header-link {
		color: $black-slate;
		font-size: 22px;
		line-height: 1;
		font-weight: 600;
		text-decoration: none;
		cursor: pointer;

		@include mq-min-max(no, xs) {
			font-size: 18px;
		}
	}

	&__pods {
		display: grid;
		grid-auto-columns: 1fr;
		grid-column-gap: 42px;
		grid-row-gap: 42px;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: auto;

		@include mq-min-max(md, lg) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include mq-min-max(no, md) {
			display: flex;
			flex-wrap: wrap;
			grid-column-gap: 20px;
			grid-row-gap: 20px;
		}
	}

	&__pod {
		&:hover {
			.home-news__pod-content {
				background-color: $red;
				color: white;
			}
		}

		text-decoration: none;
		color: #393939;

		cursor: pointer;

		@include mq-min-max(no, xs) {
			width: 100%;
		}
	}

	&__pod-image {
		width: 100%;
		img {
			width: 100%;
			object-fit: cover;
			aspect-ratio: 16 / 9;
			margin-bottom: -5px;
		}
	}

	&__pod-content {
		display: flex;
		padding: 45px 45px 29px 38px;
		flex-direction: column;
		justify-content: space-between;
		background-color: $white-smoke;
		transition: 0.4s ease;

		@include mq-min-max(no, xs) {
			padding: 30px 20px;
		}

		&.only {
			aspect-ratio: 1 / 1;

			@include mq-min-max(no, xs) {
				height: auto;
			}
		}
	}

	&__pod-title {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 46px;
		font-size: 30px;
		line-height: 38px;
		font-weight: 600;

		@include mq-min-max(no, xs) {
			font-size: 24px;
			padding-bottom: 30px;
			line-height: 1.2;
		}
	}

	&__pod_excerpt {
		margin-bottom: 64px;
		font-size: 18px;
		line-height: 24px;
		font-weight: 500;

		p {
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		@include mq-min-max(no, xs) {
			margin-bottom: 37px;
			font-size: 16px;
			line-height: 22px;
		}
	}

	&__pod-footer {
		display: flex;
		padding-top: 19px;
		justify-content: space-between;
		border-top: 1px solid $black-slate;
	}

	&__pod-category {
		display: inline-block;
		margin: 0px;
		font-size: 18px;
		line-height: 1;

		@include mq-min-max(no, xs) {
			font-size: 14px;
		}
	}

	&__pod-date {
		display: inline-block;
		margin: 0px;
		font-size: 18px;
		line-height: 1;
		font-weight: 500;

		@include mq-min-max(no, xs) {
			font-size: 14px;
		}
	}
}
