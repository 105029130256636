body.dev {
	&:after {
		font-size: 12px;
		position: fixed;
		z-index: 100;
		right: 0;
		bottom: 0;
		padding: 2px 4px;
		content: 'xxs';
		color: white;
		background: rgba(0, 0, 0, 0.4);

		@include mq(xs) {
			content: 'xs';
		}
		@include mq(sm) {
			content: 'sm';
		}
		@include mq(md) {
			content: 'md';
		}
		@include mq(lg) {
			content: 'lg';
		}
		@include mq(xl) {
			content: 'xl';
		}
	}
}
